// Image aspect ratio
.img {
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &.aspectRatio--1x1 {
    padding-top: calc((1 / 1) * 100%);
  }
  &.aspectRatio--4x3 {
    padding-top: calc((3 / 4) * 100%);
  }
  &.aspectRatio--16x9 {
    padding-top: calc((9 / 16) * 100%);
  }
  &.aspectRatio--360x500 {
    padding-top: calc((500 / 360) * 100%);
  }
  &.aspectRatio--400x272 {
    padding-top: calc((272 / 400) * 100%);
  }
  &.aspectRatio--460x313 {
    padding-top: calc((313 / 460) * 100%);
  }
  &.aspectRatio--460x640 {
    padding-top: calc((640 / 460) * 100%);
  }
  &.aspectRatio--560x397 {
    padding-top: calc((397 / 560) * 100%);
  }
  &.aspectRatio--560x687 {
    padding-top: calc((687 / 560) * 100%);
  }
  &.aspectRatio--660x448 {
    padding-top: calc((448 / 660) * 100%);
  }
  &.aspectRatio--660x834 {
    padding-top: calc((834 / 660) * 100%);
  }
  &.aspectRatio--760x498 {
    padding-top: calc((498 / 760) * 100%);
  }
  &.aspectRatio--860x586 {
    padding-top: calc((586 / 860) * 100%);
  }
  &.aspectRatio--960x633 {
    padding-top: calc((633 / 960) * 100%);
  }
  &.aspectRatio--1360x787 {
    padding-top: calc((787 / 1360) * 100%);
  }
  &.aspectRatio--1380x840 {
    padding-top: calc((840 / 1380) * 100%);
  }
  &.aspectRatio--1440x940 {
    padding-top: calc((940 / 1440) * 100%);
  }
  &.aspectRatio--1363x789 {
    padding-top: calc((789 / 1363) * 100%);
  }
  &.aspectRatio--192x259 {
    padding-top: calc((259 / 192) * 100%);
  }
  &.aspectRatio--132x88 {
    padding-top: calc((88 / 132) * 100%);
  }
  &.aspectRatio--860x537 {
    padding-top: calc((537 / 860) * 100%);
  }
  &.aspectRatio--272x186 {
    padding-top: calc((186 / 272) * 100%);
  }
  &.aspectRatio--656x600 {
    padding-top: calc((600 / 656) * 100%);
  }
  &.aspectRatio--713x524 {
    padding-top: calc((524 / 713) * 100%);
  }
  &.aspectRatio--1248x652{
    padding-top: calc((652 / 1248) * 100%);
  }
  &.aspectRatio--474x481{
    padding-top: calc((481 / 474) * 100%);
  }
  &.aspectRatio--472x314{
    padding-top: calc((314 / 472) * 100%);
  }
  &.aspectRatio--1250x560{
    padding-top: calc((560 / 1250) * 100%);
  }
  &.aspectRatio--377x256{
    padding-top: calc((256 / 377) * 100%);
  }
  &.aspectRatio--739x484{
    padding-top: calc((484 / 739) * 100%);
  }
  &.aspectRatio--1246x480{
    padding-top: calc((480 / 1246) * 100%);
  }
  &.aspectRatio--960x460{
    padding-top: calc((460 / 960) * 100%);
  }
  &.aspectRatio--511x335{
    padding-top: calc((335 / 511) * 100%);
  }
  &.aspectRatio--713x467{
    padding-top: calc((467 / 713) * 100%);
  }
  &.aspectRatio--834x481{
    padding-top: calc((481 / 834) * 100%);
  }
  &.aspectRatio--547x397{
    padding-top: calc((397 / 547) * 100%);
  }
  &.aspectRatio--487x450{
    padding-top: calc((450 / 487) * 100%);
  }
}
