.error404{
    height: auto;
    min-height: auto;
    display: flex;
    justify-content: center;
    @media (max-width: 992px) {
        min-height: auto;
    }

    &__content{
        padding: 73px 0 243px 0;
        text-align: center;
        @media (max-width: 992px) {
            padding: 60px 0 204px 0;
        }
        > img{
            width: 100%;
            max-width: 418px;
            height: auto;
            margin-bottom: 4px;
            @media (max-width: 992px) {
                width: 161px;
                margin-bottom: 18px;
            }
        }
        h1{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 24px;
            line-height: 38px;
            text-align: center;
            color: #000000;
            margin-bottom: 84px;
            @media (max-width: 992px) {
                @include font('Open Sans', sans-serif, 300, normal);
                font-size: 18px;
                line-height: 32px;
                width: 100%;
                max-width: 246px;
                margin: 0 auto 48px auto;
            }
        }
        &--btn{
            a{
                @include font('Open Sans', sans-serif, 700, normal);
                font-size: 20px;
                line-height: 20px;
                padding: 20px 40px;
                text-decoration: none;
                display: inline-block;
                color: #000000;
                @media (max-width: 992px) {
                    font-size: 15px;
                    line-height: 28px;
                    padding: 10px 24px;
                }
            }
        }
        .desktopShow{
            display: inline-block;
            @media (max-width: 992px) {
                display: none;
            }
        }
        .mobileShow{
            display: none;
            @media (max-width: 992px) {
                display: inline-block;
            }
        }
    }
}
