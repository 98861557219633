// CORE ELEMENTS

// NKI Brand
$brandOrange: #fff3e8;
$brandPurple: #483A54;
$brandRed: #E97C5D;
$darkGreen: #436A5F;
$lightGreen: #7DA27F;
$lightPurple: #FAF5FE;
$red: #C25839;
$orange: #F68D6F;
$lightOrange: #FBCFB7;
$lightOrange2: #EFCDAE;
$pink: #FF5867;
$yellow: #FFF5C5;
$brandPurple-h: #655573;
$blue: #63CDD4;

$lightGrey: #E2E2E7;
$lightGrey2: #F5F5F5;
$grey: #D6D6D6;
$grey2: #AFB2B5;
$darkGrey: #5A5A5A;


// MedLearn Brand
$Med-brandRed: #C44345;
$Med-brandGrayLight: #E0DFD9;
$Med-brandBLueLight: #AAC6CE;
$Med-brandBLue: #4E6EAD;
$Med-brandBlack1: #424A57;
$Med-brandBlack2: #506279;
$Med-brandBlack3: #748A9B;
$Med-btn-primary: #A2C6CF;
$Med-btn-primary-h: #F6CDD6;

// Text
$textDark1: #121212;
$textDark2: #222222;
$textDark3: #353637;



body {
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .page{
    overflow: hidden;
  }
  &.bgError404{
    background: $brandOrange;
  }
  &.bgBanner{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fff3e8+0,fff3e8+100&1+71,0+100 */
    background: -moz-linear-gradient(top,  rgba(255,243,232,1) 0%, rgba(255,243,232,1) 71%, rgba(255,243,232,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,243,232,1) 0%,rgba(255,243,232,1) 71%,rgba(255,243,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,243,232,1) 0%,rgba(255,243,232,1) 71%,rgba(255,243,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff3e8', endColorstr='#00fff3e8',GradientType=0 ); /* IE6-9 */
    background-size: 100% 1480px;
    background-repeat: no-repeat;
    @media(max-width: 992px){
      background-size: 100% 938px;
    }
  }

  &.bgBannerLightPurple{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#faf5fe+0,faf5fe+100&1+71,0+100 */
    background: -moz-linear-gradient(top,  rgba(250, 245, 254,1) 0%, rgba(250, 245, 254,1) 71%, rgba(250, 245, 254,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(250, 245, 254,1) 0%,rgba(250, 245, 254,1) 71%,rgba(250, 245, 254,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(250, 245, 254,1) 0%,rgba(250, 245, 254,1) 71%,rgba(250, 245, 254,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#faf5fe', endColorstr='#00faf5fe',GradientType=0 ); /* IE6-9 */
    background-size: 100% 1480px;
    background-repeat: no-repeat;
    @media(max-width: 992px){
      background-size: 100% 938px;
    }
  }

  &.overflowHidden{
    overflow: hidden;
  }


  &.bgBubble{
    &:before{
      z-index: -1;
      content: '';
      background-image: url("../images/form-title.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 60%;
      max-width: 60%;
      height: 90%;
      right: 10px;
      top: 0%;
      position: absolute;
      pointer-events: none;
      @media(max-width: 992px){
          background-image: url("../images/form-title-m.svg");
          width: 100%;
          height: 100%;
          max-width: 90%;
          right: 0;
          top: 50px;

          background-position: top right;
      }
  }
  }


}

h1,h2,h3{
  color: #506279;
  font-weight: 400;
}

h4, h5, h6{
  color: #000000;
  font-weight: 700;
}

h1 {
  font-size: rem(65px);
  // @media(max-width: 991px) {
  //   font-size: 3rem;// 48px
  // }
}
h2 {
  font-size: rem(36px);
}
h3 {
  font-size: rem(30px);
  // @media(max-width: 991px) {
  //   font-size: 2rem; // 32px
  // }
}
h4 {
  font-size: rem(24px);
}
h5 {
  font-size: rem(21px);
//  @media(max-width: 991px) {
//     font-size: 1.25rem;
//   }
}
h6 {
  font-size: rem(18px);
}


a {
  color: $brandPurple;
  text-decoration: underline;
  &:hover {
    color: $brandPurple-h;
  }
}

.block-link-bottom {
  margin-top: 0px;
  margin-bottom: 200px;
  display: inline-block;
  @media(max-width: 992px){
    margin-bottom: 100px;
    padding-top: 20px;
  }
}

.link-b-orange{
  color: #506279;
  text-decoration: none;
  border-bottom: 2px solid #F6CDD6;
  &:hover {
    color: #506279;
    border-bottom: 2px solid #F6CDD6;
    text-decoration: none;
  }
}
.line-grey {
  width: 100%;
  border-top: 1px solid $grey;
}
//Utilities

.divide + .detailed-info{
  margin-top: 80px;
}

.line-article {
  width: 100%;
  border-top: 1px solid $grey;
  margin-top: 60px;
  margin-bottom: 89px;
  @media (max-width: 992px) {
    margin-bottom: 60px;
    display: none;
  }
  &.part{
    margin-bottom: 80px;
    @media (max-width: 992px) {
      margin-bottom: 60px;
    }
  }
}

.mb-155{
  margin-bottom: 155px;
}
.mb-35{
  margin-bottom: 35px;
}

// Autozoom fix on safari mobile
@media (max-width: 992px) {
  body.ios{
    select,
    textarea,
    // input[type="text"],
    // input[type="number"],
    // input[type="tel"],
    // input[type="password"],
    // input[type="email"],
    input {
      font-size: 19px!important;
    }
  }
}
// Autozoom fix on safari mobile
.bodyBlock {
  overflow: hidden!important;
}

.app-cookie-line{
  z-index: 999999;
}

//GRID ViEW
.btnGridView{
  position: fixed;
  left: 15px;
  top: 15px;
  font-size: 8px;
  color: #000000;
  opacity: .5;
  z-index: 999999;
  text-decoration: none;
}
.btnGridView:hover{
  text-decoration: none;
  color: #000000;
  opacity: 1;
}

.gridView{
  display: none;
  position: fixed;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none;
}

.gridView.desktop.active{
  display: block;
}
@media (max-width: 992px){
  .gridView.desktop.active{
    display: none;
  }
}

.gridView.mobile.active{
  display: none;
}
@media (max-width: 992px){
  .gridView.mobile.active{
    display: block;
  }
}

.gridView .container{
  background: rgba(255, 0, 0,.1);
}
.gridView .container span{
  display: block;
  text-align: center;
  background: rgba(255, 0, 0,.1);
  height: 100vh;
  color: rgba(0,0,0,.5);
  padding-top: 10px;
}
//GRID ViEW

// Cookie Declaration - MED-102
.CookieDeclaration {
  margin-bottom: 100px;
  & > div {
      margin-left: calc(35.5% + 15px)!important;
      width: calc(64.5% - 16px);
      @media (max-width: 992px) {
          margin-left: 0!important;
          width: 100%;
      }
      p,ul,ol,h3,h4,h5,h6{
         margin-left: 0;
         width: 100%;
      }
  }
  .CookieDeclarationTableCell{
      @media (max-width: 992px) {
        font-size: 14px;
    }
  }
}
