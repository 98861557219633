.logIn{
    z-index: 1001;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-x: auto;
    right: -100vw;
    -ms-overflow-style: none;
    transition: all 300ms;
    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper{
        // background: #FAF5FE;
        // width: 858px;
        background: #FFF;
        width: 688px;
        height: 100%;
        float: right;
        overflow: auto;
        @media(max-width: 992px){
            width: 100%;
        }

        .btnClose{
            position: fixed;
            z-index: 10;
            top: 40px;
            right: -100vw;
            transition: all 350ms;
            @media(max-width: 992px){
                position: absolute;
                top: 20px;
                right: 20px;
            }
            img{
                //   width: 40px;
                width: 24px;

                height: auto;
                @media(max-width: 992px){
                    
                }
            }
        }

        &--content{

            .linksWrapper{
                // padding: 108px 20% 75px 16%;
                padding: 160px 130px 45px 130px;

                background: #ffffff;
                @media (max-width: 992px) {
                    padding: 20px 124px 30px 124px;
                }
                @media (max-width: 768px) {
                    padding: 20px 20px 30px 20px;
                }
                .logoWrapper{
                    display: none;
                    margin-bottom: 40px;
                    @media (max-width: 992px) {
                      display: block;  
                    }
                    img{
                        max-width: 179px;
                    }
                }
                .item{
                    // margin-bottom: 45px;
                    margin-bottom: 36px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .title{
                        display: block;
                        @include font('Open Sans', sans-serif, 400, normal);                   
                        // font-size: 21px;
                        // line-height: 36px;
                        font-size: 18px;
                        line-height: 30px;
                        color: #8D8E9B;
                        // margin-bottom: 4px;
                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 21px;
                        }
                    }
                    a{
                        @include font('Open Sans', sans-serif, 400, normal);
                        // font-size: 28px;
                        // line-height: 40px;
                        font-size: 20px;
                        line-height: 30px;
                        padding-right: 23px;
                        color: #506279;
                        text-decoration: none;
                        border-bottom: 2px solid #F6CDD6;
                        display: inline-block;
                        &:hover{
                            border-bottom: 2px solid #F6CDD6;
                        }
                        @media (max-width: 992px) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                        span{
                            position: relative;
                            &:after{
                                //   @include background-image("./icons/arrow-link-log-in.svg", 15.5, 15.5);
                                @include background-image("./icons/arrow-link-log-in.svg", 12, 12);
                                position: absolute;
                                right: -23px;
                                top: 50%;
                                margin-top: -5px;
                                @media (max-width: 992px){
                                    @include background-image("./icons/arrow-link-log-in.svg", 12, 12);
                                    right: -20px;
                                    margin-top: -5px;
                                }
                            }
                        }                        
                    }
                }
            }
            .logInBox{
                // padding: 48px 20% 59px 16%;
                // background: #FAF5FE;
                padding: 40px 124px 60px 124px;
                background:#506279;
                @media (max-width: 768px) {
                    padding: 44px 20px 59px 20px;
                }
                .title{
                    text-align: center;
                    // @include font('Open Sans', sans-serif, 400, normal);
                    // font-size: 21px;
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 20px;
                    line-height: 30px;
                    color: #ffffff;
                    display: block;
                    // margin-bottom: 32px;
                    margin-bottom: 48px;
                    @media (max-width: 768px) {
                      margin-bottom: 18px;  
                    }
                }
                .formLogin{
                    // margin-bottom: 40px;
                    // margin-bottom: 32px;

                    .form-group{
                        // width: 46.2%;
                        // display: inline-block;
                        // margin-right: 6.5%;
                        margin-bottom: 11px;
                        @media (max-width: 768px) {
                            width: 100%;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            margin-bottom: 22px;
                        }
                        &:last-child{
                            margin-right: 0;
                            @media (max-width: 768px) {
                                margin-right: auto;
                            }
                        }
                        label{
                            // margin-bottom: 8px;
                            // line-height: 28px;
                            font-size: 14px;
                            line-height: 52px;
                            color: #ffffff;
                            @media (max-width: 768px) {
                                font-size: 14px;
                                line-height: 25px;
                            }
                        }
                        .form-control{
                            border-radius: 12px;
                            // border: 1px solid #B1B1B1;
                            min-height: 52px;
                            font-size: 17px;
                            line-height: 28px;
                            color: #8D8E9B;
                            font-weight: 400;
                            @media (min-width: 993px) {
                                border-color: #C4C4C4;
                                &:active, &:focus, &:focus-within {
                                    border-bottom-color: $darkGrey;
                                }
                            }
                            @media (max-width: 768px) {
                                font-size: 16px;
                                line-height: 24px;
                                min-height: 53px;
                            }
                        }
                    }
                }
                .btnLogin{
                    margin-bottom: 55px;
                    margin-top: 32px;
                    text-align: center;
                    @media (max-width: 768px) {
                        margin-top: 44px;
                    }
                    button{
                        @include font('Open Sans', sans-serif, 400, normal);
                        text-decoration: none;
                        border:none;
                        // font-size: 18px;


                        display: inline-block;
                        font-size: 20px;
                        // padding: 14px 32px;
                        padding: 17px 80px;
                        border-radius: 40px;
                        color: #000000;

                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 21px;
                            padding: 17px 40px;
                        }

                    }
                }
                .btnPassword{
                    text-align: center;
                    a{
                        @include font('Open Sans', sans-serif, 400, normal);
                        // font-size: 16px;
                        // line-height: 28px;
                        // color: #121212;
                        // opacity: 0.6;
                        text-decoration: none;

                        font-size: 20px;
                        line-height: 30px;
                        color: #ffffff;
                        border-bottom: 2px solid #F6CDD6;
                        display: inline-block;
                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }

    }
    &.active{
        right: 0;
        .btnClose{
            right: 40px;
            @media(max-width: 992px){
                right: 20px;
            }
        }
        .overlay{
            animation: backgroundFade 300ms 300ms both;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    @keyframes backgroundFade {
        from {
            background: rgba($color: #000000, $alpha: 0);
        }

        to {
            background: rgba($color: #000000, $alpha: .5);
        }
    }
}
