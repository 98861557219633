.importantMessage{
    background: #DDEBEE;
    width: 100%;
    z-index: 10;
    position: relative;
    transition: all 250ms;
    opacity: 1;

    &__btn{
        text-align: center;
        background: #DDEBEE;
        a{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 16px;
            line-height: 28px;
            color: #000000;
            display: inline-block;
            padding: 16px 0;
            text-align: center;
            text-decoration: none;
            &:hover{
                color: #000000;
                text-decoration: none;
            }
            span{
                position: relative;
                &:after{
                  @include background-image("./icons/medlearn/arrow-important-message.svg", 14, 14);
                  position: absolute;
                  right: -23px;
                  top: 50%;
                  margin-top: -5.5px;
                  transition: all 150ms;
                }
            }
            &:hover{
                &:after{
                    opacity: 1;
                }
            }
            &.active{
                span{
                    &:after{
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &__content{
        position: absolute;
        background: #A2C6CF;
        width: 100%;
        padding: 28px 0 40px 0;
        display: none;
        .content{
            margin: 0 auto;
            // width: 33%;
            @media(max-width: 992px){
                // width: 66%;
            }
            @media(max-width: 768px){
                // width: 100%;
                padding: 28px 30px 40px 30px;
            }
            p{
                @include font('Open Sans', sans-serif, 300, normal);
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                color: #000000;
                margin-bottom: 20px;
                &:last-child{
                  margin-bottom: 0;
                }
                a{
                    @include font('Open Sans', sans-serif, 300, normal);
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: 0.24px;
                    color: #000000;
                    text-decoration: underline;
                }
            }
        }
    }
    &.toHide{
        pointer-events: none;
        opacity: 0;
    }
}
