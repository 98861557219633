.carousel{
    padding-top: 96px;
    padding-bottom: 120px;
    @media (max-width: 992px) {
        padding-top: 36px;
        padding-bottom: 60px;
    }
    &__slider{
        *{
            &:focus,
            &:active{
                outline: 0;
            }
        }
        &--item{
            .content{
                .imgWrapper{
                    img{
                        width: 100%;
                    }
                }
                .textWrapper{
                    @include font('Open Sans', sans-serif, 300, normal);
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                    line-height: 24px;
                    height: 24px;
                    letter-spacing: 0.24px;
                    color: #222222;
                    margin-top: 20px;
                    @media (max-width: 992px) {
                        margin-top: 10px;
                    }
                }
            }
        }
        .slick-track {
            display: flex;
            align-items: flex-end;
        }
        .slick-prev{
            display: none!important;
        }
        .slick-next{
            width: 11.248%; //160px
            height: auto;
            right: -2.6%;
            cursor: default!important;
            pointer-events: none;
            @media (max-width: 992px) {
                display: none!important;
            }
            &:before{
                display: none;
            }
            &.slick-disabled{
                display: none;
            }
        }
        .slick-list{
            overflow: visible;
        }
    }

    &.full{
        width: 96%;
        @media (max-width: 992px) {
            width: 100%;
        }
        .content{
            margin-right: 3%;
            @media (max-width: 992px) {
                margin-right: 10%;
            }
            @media (max-width: 768px) {
                margin-right: 9%;
            }
            @media (max-width: 440px) {
                margin-right: 7%;
            }
        }
    }

    &.half{
        width: 96%;
        @media (max-width: 992px) {
            width: 100%;
        }
        .content{
            margin-right: 6%;
            @media (max-width: 992px) {
                margin-right: 10%;
            }
            @media (max-width: 768px) {
                margin-right: 9%;
            }
            @media (max-width: 440px) {
                margin-right: 7%;
            }
        }
    }

}
