.blogPostPageHeader{
    position: relative;    
    &__category {
        @include font('Open Sans', sans-serif, 600, normal);
        color: $orange;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        margin-top: 89px;
        @media(max-width: 992px) {
            display: none;
            // font-size: 15px;
            // line-height: 24px;
        }
    }
    &__title{
        margin: 0 auto;
        max-width: 890px;
        h1{
            @include font('Open Sans', sans-serif, 400, normal);
            text-align: center;
            color: #506279;
            font-size: 64px;
            line-height: 74px;
            text-align: center;
            padding-top: 28px;
            margin-bottom: 110px;

            @media (max-width: 992px) {
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 36px;
                line-height: 41px;
                margin-bottom: 34px;
                padding-top: 20px;
            }

            @media (max-width: 768px) {
                @include font('Open Sans', sans-serif, 400, normal);
                max-width: 370px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &__image{
        margin-bottom: 94px;
        @media (max-width: 992px) {
            margin-bottom: 36px;
            margin-left: -5px;
            margin-right: -5px;
        }
        img{
            width: 100%;
        }
    }
    &__ingress{
        padding-bottom: 40px;
        @media (max-width: 992px) {
            padding-bottom: 15px;
        }
        h2{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 36px;
            line-height: 41px;
            color: #506279;
            width: calc(35.714% - 26px);      
            position: absolute;
            @media (max-width: 992px) {
                font-size: 24px;
                line-height: 30px;
                position: relative;
                text-align: center;
                width: 100%;
                margin-bottom: 10px;
                display: none;
            }
        }
        p{
            margin-bottom: 40px;
            margin-left: calc(35.5% + 15px);
            width: calc(64.5% - 16px);
            font-weight: 300;
            font-size: 36px;
            line-height: 44px;
            color: #000000;
            @media (max-width: 992px) {
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                width: 100%;
                margin-left: 0;
                margin-bottom: 25px;         
            }
        }
    }
}
