.homeHero{
  margin-bottom: 160px;

  @media (max-width: 992px) {
    // MED-86 by Leandro
    // margin-bottom: 64px;
    margin-bottom: 134px;
  }

  &.bg-gray{
    .homeHero__info{
      background: #E0DFD9;
      &:after,
      &:before{
        background: #E0DFD9;
      }
    }
  }

  &.bg-gray-light{
    .homeHero__info{
      background: #F2F5F6;
      &:after,
      &:before{
        background: #F2F5F6;
      }
    }
  }

  &__info{
    padding:66px 0 70px;
    position: relative;
    z-index: 0;

    @media (max-width: 992px) {
      background: none!important;
      padding: 0;
    }

    &:after{
      content: "";
      height: 100%;
      width: 50vw;
      position: absolute;
      top: 0;
      right: 100%;
      z-index: -1;
    }
    &:before{
      content: "";
      height: 100%;
      width: 70vw;
      position: absolute;
      top: 0;
      left: 100%;
      z-index: -1;
    }

    &:after,
    &:before{
      @media (max-width: 992px) {
        background: none!important;
      }
    }

    h2{
      @include font('Open Sans', sans-serif, 400, normal);
      font-size: rem(28px);
      line-height: rem(32px);
      color: #444A57;
      margin-bottom: 47px;
      @media (max-width: 992px) {
        font-size: rem(28px);
        line-height: rem(38px);
        margin: 0 auto 30px auto;
        max-width: 375px;
        text-align: center;
      }
    }

    h1{
      @include font('Open Sans', sans-serif, 600, normal);
      font-size: rem(60px);
      line-height: rem(82px);
      margin-bottom: 58px;
      max-width: 448px;
      color: #444A57;
      @media (max-width: 992px) {
        font-size: rem(60px);
        line-height: rem(82px);
        text-align: center;
        margin: 0 auto 25px auto;
        max-width: 500px;
      }
      @media (max-width: 430px) {
        font-size: rem(45px);
        line-height: rem(52px);
      }
    }
  }

  .courseSelect{
    width: 100%;
    max-width: 500px;
    position: relative;
    z-index: 0;

    @media (max-width: 992px) {
      max-width: 360px;
      margin: 0 auto;
    }

    &.desktop{
      display: block;
      @media (max-width: 992px) {
        display: none;
      }
    }

    &.mobile{
      display: none;
      margin-top: -95px;
      @media (max-width: 992px) {
        display: block;
      }
    }




    &__btn{
      width: 100%;
      position: relative;

      button{
        background: #A2C6CF;
        border-radius: 40px;
        display: block;
        width: 100%;
        padding: 16px 32px;
        border: none;
        position: relative;
        text-align: left;
        @include font('Open Sans', sans-serif, 600, normal);
        font-size: rem(28px);
        line-height: rem(28px);
        color: #000000;

        @media (max-width: 992px) {
          font-size: rem(20px);
          line-height: rem(20px);
          padding: 20px 32px;
        }

        &.active{
          img{
            transform: translateY(-50%) rotate(180deg);
          }
        }

        img{
          position: absolute;
          right: 14px;
          top: 50%;
          transform: translateY(-50%);
          transition: all 300ms;
          width: 40px;
        }

      }

    }
    &__options{
      background: rgba(221, 235, 238, 0.95);
      border-radius: 2px 2px 40px 40px;
      padding: 40px 32px 30px 32px;
      position: absolute;
      top: 30px;
      z-index: -1;
      display: none;
      width: 100%;

      .items{
        list-style: none;

        > li{

          > a{
            @include font('Open Sans', sans-serif, 700, normal);
            font-size: rem(24px);
            line-height: rem(50px);
            color: #000000;
            position: relative;
            display: inline;
            text-decoration: none;
            @media (max-width: 992px) {
              font-size: rem(14px);
              line-height: rem(36px);
            }
            &:hover{
              border-bottom: 2px #000000 solid;
              @media (max-width: 992px) {
                border-bottom: none;
              }
              &:after{
                content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbWFkYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTUuMSAxMi41IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNS4xIDEyLjU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZD0iTTE1LjEsNi4yTDguOCwwTDcuMiwxLjdsMy41LDMuNEgwdjIuNGgxMC42bC0zLjQsMy4zbDEuNywxLjdMMTUuMSw2LjJ6Ii8+Cjwvc3ZnPgo=");
                width: 15px;
                display: inline-block;
                margin-left: 15px;
                @media (max-width: 992px) {
                  display: none;
                }
              }
            }
          }

          ul{
            list-style: none;
            li{
              a{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: rem(20px);
                line-height: rem(41.7px);
                color: #000000;
                position: relative;
                display: inline;
                text-decoration: none;
                z-index: 0;
                &:before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: transparent;
                  z-index: -1;
                }
                &:hover{
                  font-weight: 600;
                  border-bottom: 2px #000000 solid;
                  @media (max-width: 992px) {
                    font-weight: 400;
                    border-bottom: none;
                  }
                  &:after{
                    content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbWFkYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTUuMSAxMi41IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNS4xIDEyLjU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZD0iTTE1LjEsNi4yTDguOCwwTDcuMiwxLjdsMy41LDMuNEgwdjIuNGgxMC42bC0zLjQsMy4zbDEuNywxLjdMMTUuMSw2LjJ6Ii8+Cjwvc3ZnPgo=");
                    width: 15px;
                    display: inline-block;
                    margin-left: 15px;
                    @media (max-width: 992px) {
                      display: none;
                    }
                  }
                }
                @media (max-width: 992px) {
                  font-size: rem(14px);
                  line-height: rem(36px);
                }
              }
            }
          }

        }
      }
    }
  }

  // .col-lg-10{
  //   padding: 0!important;
  // }

  &__intro{

    // MED-86 by Leandro
    display: none;

    max-width: 635px;
    padding-top: 43px;
    @media (max-width: 992px) {
      max-width: 350px;
      padding-top:70px;
      margin: 0 auto;
      text-align: center;
    }

    h3{
      @include font('Open Sans', sans-serif, 400, normal);
      font-size: rem(36px);
      line-height: rem(41px);
      margin-bottom: 20px;
      max-width: 520px;
      @media (max-width: 992px) {
        @include font('Open Sans', sans-serif, 400, normal);
      }
    }
    p{
      @include font('Open Sans', sans-serif, 400, normal);
      font-size: rem(20px);
      line-height: rem(23px);
      margin-bottom: 20px;
      color: #010101;
      &:last-child{
        margin-bottom: 0;
      }
      @media (max-width: 992px) {
        font-size: rem(18px);
        line-height: rem(21px);
      }
    }
  }


  .clipImage{
    width: 100%;
    margin-left: auto;

    @media (max-width: 992px) {
      margin: 0 auto;
    }

    .wrapper{
      width: 100%;
      position: absolute;

      @media (max-width: 992px) {
        max-width: 362px!important;
        margin: 0 auto;
        position: relative;
      }

      .svg {
        position: absolute;
        width: 0;
        height: 0;
      }
      .clipped {
        width: 100%;
        height: 100%;
        background-color: rgba(162, 198, 207, 0.4);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }


    &.heart{
      max-width: 656px;
      .wrapper{
        max-width: 656px;
        .clipped {
          // -webkit-clip-path: url(#heart-clip);
          // clip-path: url(#heart-clip);

          -webkit-mask-image: url(../images/medlearn/mask-heart.svg);
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-size: 100%;
          -webkit-mask-position: center;

          margin-top: -25px;
          @media (max-width: 992px) {
            margin-top: 0;
          }
        }
      }
    }

    &.cloud{
      max-width: 713px;
      .wrapper{
        max-width: 713px;
        .clipped {
          // -webkit-clip-path: url(#cloud-clip);
          // clip-path: url(#cloud-clip);

          -webkit-mask-image: url(../images/medlearn/mask-cloud.svg);
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-size: 100%;
          -webkit-mask-position: center;


          margin-top: -29px;
          @media (max-width: 992px) {
            margin-top: 0;
          }
        }
      }
    }

  }

  .withoutClipImage{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-top: -35px;

    @media (max-width: 992px) {
      display: none;
    }

    .wrapper{
      width: 100%;
      max-width: 713px;
      @media (max-width: 992px) {
        max-width: 362px;
      }
      .img{
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }

    & + .courseSelect.mobile{
      margin-top: 0;
    }

  }

  .z-index-1{
    z-index: 1;
    @media (max-width: 992px) {
      z-index: 2;
    }
  }

  .z-index-2{
    z-index: 2;
    @media (max-width: 992px) {
      z-index: 1;
    }
  }

  &.bg-blue-light{
    background: #D0E2E6;
    margin-top: -35px;
    @media (max-width: 992px) {
      padding: 45px 0 61px 0;
      margin-top: 0;
    }
    .homeHero__info{
      padding: 44px 0 52px 0;
      @media (max-width: 992px) {
        padding: 0;
      }
    }
    .courseSelect__btn button{
      background-color: #F7BBA9;
    }
    .courseSelect__options {
      background: rgba(255, 217, 206, 0.95);
    }
  }

}
