

.btn {
    padding: 20px 30px;
    border-radius: 40px;    
    transition: transform 0.3s;
    font-size: rem(20px);
    line-height: rem(20px);
    text-decoration: none;
    transition: all 300ms;

    &:disabled,
    &.disabled{
        background-color: $Med-btn-primary!important;
        border-color: $Med-btn-primary!important;
        opacity: 1;
        pointer-events: none;
    }

    &-primary{
        min-width: 233px;
        background-color: $Med-btn-primary;
        border-color: $Med-btn-primary;
        color: #000000;
        &:not(:disabled):not(.disabled){
            &:hover, &:active, &:focus, &:focus-within {
                color: #000000;
                background-color: $Med-btn-primary-h;
                border-color: $Med-btn-primary-h;
            }
        }
    }
    &-secondary {
        color: $textDark1;
        background-color: $lightGrey;
        border-color: $lightGrey;
        &:not(:disabled):not(.disabled){
            &:hover, &:active, &:focus, &:focus-within {
                color: $textDark1;
                background-color: $grey;
                border-color: $grey;
            }
        }
    }
    &-brand-red{
        background-color: $brandRed;
        border-color: $brandRed;
        color: #fff;
        &:not(:disabled):not(.disabled){
            &:hover, &:active, &:focus, &:focus-within {
                color: #fff;
                background-color: $brandRed;
                border-color: $brandRed;
            }
        }
    }
    span {
        transition: transform 0.3s;
    }
    &--loading{
        position: relative;        
        &:not(:disabled):not(.disabled){

            &:hover,&:active, &:focus,&:hover:active {
                background: #F6CDD6;
                cursor: default;
                box-shadow: none;
            }
        }
        span {
            display: block;
            opacity: 0;
            transform: translateY(16px) scale(.7) translateZ(0);
            transition: transform 0.3s, opacity 0.3s;
        }
        // svg {
        //     height: 37px;
        //     width: 37px;
        //     display: block;
        //     position: absolute;
        //     left: 50%;
        //     top: 50%;
        //     margin: -18px 0 0 -18px;
        //     fill: none;
        //     stroke:  #fff;
        //     // stroke-linecap: square;
        //     stroke-width: 1.2;
        //     /* opacity: var(--svg-o, 0); */
        //     transform:  rotate(-90deg) ;
        //     circle {
        //         &:first-child {
        //             stroke-opacity: 0.3;
        //         }
        //         &:last-child {
        //             stroke: #fff;
        //             stroke-dasharray: 38px;
        //             stroke-dashoffset: 114px;
        //             -webkit-animation: load 1.4s cubic-bezier(0.42, -0.01, 1, 1) infinite 0.3s;
        //             animation: load 1.4s cubic-bezier(0.42, -0.01, 1, 1) infinite .3s;
        //         }
        //     }

        // }

        svg{
            height: 42px;
            width: 42px;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -21px 0 0 -21px;


            @keyframes animate-svg-stroke-1 {

              0% {
                stroke-dashoffset: 167.89785766601562px;
                stroke-dasharray: 167.89785766601562px;
              }


              30%{
                stroke-dashoffset: 335.79571533203125px;
                stroke-dasharray: 167.89785766601562px;
              }

              69%{
                stroke-dashoffset: 335.79571533203125px;
                stroke-dasharray: 167.89785766601562px;
              }

              70% {
                stroke-dashoffset: 335.79571533203125px;
                stroke-dasharray: 167.89785766601562px;
              }

              100% {
                stroke-dashoffset: 503.6935729980469px;
                stroke-dasharray: 167.89785766601562px;
              }

              

            }

            .svg-elem-1 {
              animation: animate-svg-stroke-1 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;              
            }


        }

    }
}
.btn-remove{
    color: transparent;
    width: 29px;
    height: 29px;
    padding: 0;
    background-image: url(../images/icons/x-remove.svg);
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    &:hover{
        color: transparent;
    }
}
.btn-float {
    @include font('EB Garamond', serif, 400, normal);
    text-align: center;
    color: #fff;
    font-size: $p-m;
    border-radius: 50%;
    background-color: $darkGreen;
    width: 140px;
    height: 140px;
    position: fixed;
    top: 50%;
    right: 45px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    z-index: 1000;
    &:hover {
        color: #fff;
    }
    @media (max-width: 765px) {
        font-size: 1.0625rem; //17
        height: 120px;
        width: 120px;
        right: 0;
    }
    span {
        @include font('Open Sans', sans-serif, 300, normal);
        font-size: $p-sm;
        font-weight: 300;
        display: block;
        @media (max-width: 765px) {
            font-size: 0.75rem; //12
        }
    }
    &.light {
        background-color: $lightGreen;
    }
    &.purple {
        background-color: $brandPurple;
    }
    &+ .btn-float{
        top: calc(50% - 142px);
        @media (max-width: 765px) {
            top: calc(50% - 122px);
        }
    }
}

.btn-help {
    color: #000000;
    font-size: 19px;
    line-height: 27px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: #F6CDD6;
    width: 27px;
    height: 27px;
    font-weight: 400;
}
.btn-file {
    width: 100%;
    height: 88px;
    background-color: #E1DFD8;
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    @media (max-width: 992px) {
        margin-bottom: 20px;
    }
    &:hover {
        .btn-file__progress {
            transition: 1s;
            width: 100%;
        }
    }
    &__progress{
        display: flex;
        height: 100%;
        width: 0;
        background-color: #AAC6CE;
    }
    &__content {
        padding: 0 15px;
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 21px;
        color: #000000;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
       @media(max-width: 991px) {
            font-size: 15px;
        }
        &:before {
            content: '';
            margin-right: 20px;
            display: inline-block;
            height: 23px;
            width: 19px;
            background-image: url(../images/icons/download.svg);
            background-repeat: no-repeat;
           @media(max-width: 991px) {
                height: 12px;
                width: 10px;
                min-width: 10px;
                margin-right: 12px;
                background-size: contain;
            }
        }
    }
    #fileToUpload{
        background-color: #E1DFD8;
    }
    input {
        height: 100%;
        width: 100%;        
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 10;
        &:focus-within{
            &+ .btn-file__content {
                text-decoration: underline;
            }
        }
    }
    &--list {
        padding-left: 20px;
        margin-bottom: 24px;
        margin-top: -40px;
        font-size: 21px;
        line-height: 40px;
        @include font('Open Sans', sans-serif, 400, normal);
       @media(max-width: 991px) {
            margin-top: -20px;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 4px;
        }
        li {
            &+ li {
                div {
                    border-top: 1px solid $grey;
                }
            }
            div {
                padding: 24px 0;
                padding-left: 24px;
                margin-left: -20px;
                position: relative;
                padding-right: 150px;
                word-break: break-word;
                @media(max-width: 992px) {
                    padding: 15px 0;
                    padding-left: 20px;
                    padding-right: 120px;
                }
                .btn-secondary{
                    font-size: 16px;
                    line-height: 28px;
                    padding: 7px 24px;
                    border-radius: 38px;
                    position: absolute;
                    right: 0;
                    top: 24px;
                    @media(max-width: 992px) {
                        top: 50%;
                        transform: translateY(-50%);
                        padding: 5px 20px;
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
                &.invalid{
                    color: red
                }
            }
        }
    }
}
@keyframes loading-orange{
    0%   {width: 0;}
    25%  {width: 100%;}
}
//      LINK

.back-link {
    a {
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 13px;
        line-height: 38px;
        text-transform: uppercase;        
        color: rgba(0, 0, 0, 0.3);
        text-decoration: none;
        @media(max-width: 991px) {
            font-size: 14px;
            @include font('Open Sans', sans-serif, 300, normal);
        }
        &:hover{
            text-decoration: underline;
        }
        &:last-child {
            color: #000000;
            text-decoration: underline;
            &:hover{
                color: #353637;
            }
            @media(max-width: 991px) {
                text-decoration: none;
            }
        }
        & + a {
            margin-left: 12px;
        }
    }
}
.link-blue {
    text-decoration: underline;
    color: #3972B4;
    padding-bottom: 3px;
    @include font('EB Garamond', serif, 400, normal);
    font-weight: 500;
    &:hover {
        
    }
}

.link-orange {
    text-decoration: none;
    border-bottom: 2px solid rgba(246,141,111,0.6);
    color: #506279;
    &:hover {
        border-color: #f68d6f;
        text-decoration: none;
        color: #506279;
    }
}
@keyframes load {
    from {
        stroke-dashoffset: 114px;
    }
    to {
        stroke-dashoffset: 38px;
    }
}

// MACRO Call to action button
.article__html-area a{
    &.cta-button{
        margin-left: calc(35.714% + 15px);
        color: $brandPurple !important;
        text-decoration: none;
        padding: 25px 42px 19px 42px;
        border-radius: 68px;
        @include font('EB Garamond', serif, 400, normal);
        font-size: 24px;
        line-height: 28px;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;
        background: $brandRed;

        @media (max-width:1680px){
            font-size: 1.3108vw;
            line-height: 1.6683vw;
            letter-spacing: -0.88px;
            padding: 20px 36px 16px;
          }

          @media (max-width: 1200px){
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.6px;
            padding: 20px 28px 14px 28px;
          }

          @media (max-width: 992px){
            margin-top: 20px;
            margin-bottom: 30px;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.6px;
            margin-left: 0;
            padding: 20px 28px 14px 28px;
          }
    }
}
