.homeHero{
    &.bg-blue-light{
        margin-bottom: 80px!important;
    }
}

.linkCards{
    position: relative;
    z-index: 0;
    padding: 0 0 80px 0;

    &__items{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 40px;
        width: 100%;
        margin: 0 auto;
        @media(max-width: 1200px){
            max-width: 975px;
        }
        @media(max-width: 992px){
            flex-direction: column;
            align-items: center;
        }
    }

    &__item{
        width: calc(50% - 40px / 2);
        min-height: 450px;
        padding: 41px 41px 54px 41px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media(max-width: 992px){
            width: 100%;
            max-width: 564px;
            padding: 80px 80px 60px 80px;
            &:last-child{
                margin-bottom: 0;
            }
        }

        @media(max-width: 480px){
            padding: 50px 30px 60px 30px;
            min-height: initial;
        }

        .wrapper{
            height: 100%;
            // position: relative;
            // width: 100%;
            // overflow: hidden;
            // padding-top: calc((450 / 546) * 100%);
            @media(max-width: 1200px){
                // padding-top: initial;
                // overflow: visible;
            }
            .content{
                // position: absolute;
                // top: 0;
                // left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media(max-width: 1200px){
                    position: relative;
                }
            }
        }

        .text{

            .title{
                margin-bottom: 49px;
                @include font('Open Sans', sans-serif, 600, normal);
                font-size: rem(38px);
                line-height: rem(49px);
                @media(max-width: 480px){
                    margin-bottom: 24px;
                    font-size: rem(34px);
                    line-height: rem(49px);
                }
            }

            .ingress{
                margin-bottom: 80px;
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: rem(30px);
                line-height: rem(39px);
                // display: -webkit-box;
                // -webkit-box-orient: vertical;
                // -webkit-line-clamp:6;
                // overflow: hidden;
                @media(max-width: 992px){
                    margin-bottom: 100px;
                }
                @media(max-width: 480px){
                    font-size: rem(24px);
                    line-height: rem(32px);
                    margin-bottom: 50px;
                }
            }

        }

        .button{
            text-align: center;
            .btn-link{
                background: #FFFFFF;
                border-radius: 40px;
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: rem(28px);
                line-height: rem(28px);
                display: inline-block;
                margin: 0 auto;
                text-align: center;
                color: #000000;
                padding: 16px 32px;
                text-decoration: none;
                width: 100%;
                transition: all 150ms;
                @media(max-width: 992px){
                    color: #444A57;
                    max-width: 360px;
                }
                @media(max-width: 480px){
                    font-size: rem(24px);
                    line-height: rem(24px);
                }
                &:hover{
                    font-weight: 700;
                }
            }
        }

        &--dark-blue{
            background-color: #444A57;
            .title,.ingress{ color: #ffffff; }
        }
        &--blue-light{
            background-color: #D0E2E6;
            .title, .ingress{ color: #434A57; }
        }

    }

}
