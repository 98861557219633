.videoYT.macro{
    //margin-top: 80px;
    //margin-left: calc(35.714% + 15px);
    //width: calc(57.1428% - 16px);
    width: calc(100%);
    margin: 0 auto 80px auto;
    @media(max-width: 992px){
        //margin-left: 0;
        //margin-top: 40px;
        width: 100%;
        margin: 0 auto 80px auto;
    }

    &.mb-63{
        margin: 0 auto 34px auto;
    }

    .featuredImageIngress__image,
    .featuredImageIngress__video{
        max-width: 834px;
    }

    .featuredImageIngress__video{
        .img{
            @media(max-width: 992px){
                padding-top: calc((240 / 373) * 100%);
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .playVideo{
            width: 100px;
            height: 85px;
            margin-top: calc(-100px / 2);
            margin-left: calc(-85px / 2);
            background-size: 100% 100%;
            @media(max-width: 992px){
                width: 100px;
                height: 85px;
                margin-top: calc(-100px / 2);
                margin-left: calc(-85px / 2);
            }
        }
    }

    // // &[data-portal-component-type="part"]{
        // // width: calc(100% + 40px);
        // max-width: 1328px;
        // // margin: 0 auto 109px auto;
        // padding: 0 40px;
        // // &.mb-63{
            // //margin: 0 auto 34px auto;
        // // }

        // // @media(max-width: 992px){
            // padding: 0 30px;
            // // width: 100%;
            // // margin: 0 auto 48px auto;
        // // }
   // // }
}

.article{
    .featuredImageIngress__image,
    .featuredImageIngress__video{
        max-width: 100%!important;
    }
}
