.accordion {
    border-top: 1px solid #C4C4C4;
    margin-bottom: 206px;
    @media (max-width: 992px) {
        margin-bottom: 100px;
    }
    .card {
      border-radius: 0!important;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #C4C4C4;
      &:not(:last-of-type) {
        border: 0;
        border-bottom: 1px solid #C4C4C4;
      }
      .card-body {
        padding: 0 0 18px 0;
        margin: 28px 0 0 34.5%;
        @include font('EB Garamond', serif, 400, normal);    
        @media(max-width: 992px) {
            @include font('EB Garamond', serif, 500, normal);  
         } 
        font-size: 24px;
        line-height: 36px;        
        color: #000000;
        @media (max-width: 992px) {
          padding: 0 0 22px 0;
          margin: 0 30px 0 13%;
        }
        p,ol,ul {
            @include font('EB Garamond', serif, 400, normal);    
            @media(max-width: 992px) {
                @include font('EB Garamond', serif, 500, normal);  
             } 
            font-size: 24px;
            line-height: 36px;            
            color: #000000;
            margin-bottom: 24px;
            &:last-child{
                margin-bottom: 0;
            }
            @media(max-width: 992px){
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 16px;
            }
            a{
                // color: #121212;
                // display: inline;
                // position: relative;
                // border-bottom: 2px solid rgba(246, 140, 111, 0.6);
                // text-decoration: none;
                color: #3972B4;
                display: inline;
                position: relative;                
                &:hover{
                    //border-bottom: 2px solid rgba(246, 140, 111, 1);
                }
            }
        }

        .linksBooks{
            ul, ol{
                padding-left: 20px;
                margin-bottom: 20px;
                li{
                    @include font('Open Sans', sans-serif, 300, normal);
                    font-size: 21px;
                    line-height: 36px;                    
                    color: #121212;
                    padding-left: 10px;
                    margin-bottom: 8px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    @media(max-width: 992px){
                        font-size: 15px;
                        line-height: 26px;
                    }

                    a{
                        color: #121212;
                        span{
                            position: relative;
                            &:after{
                            @include background-image("./icons/arrow-links-books.svg", 14, 14);
                            position: absolute;
                            right: -23px;
                            top: 50%;
                            margin-top: -5px;
                            @media (max-width: 992px){
                                @include background-image("./icons/arrow-links-books.svg", 9, 9);
                                right: -17px;
                                margin-top: -3px;
                            }
                            }
                        }
                    }

                }
            }
        }

        .box-orange-article{
            // margin-bottom: 0;
            p{
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
      }
      &-header {
            padding: 28px 0 28px 0;
            background-color: transparent;
            border: none;
            @media(max-width: 992px){
                padding: 28px 0 28px 0;
            }
            h2 {
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 24px;
                line-height: 28px;
                display: block;
                width: 100%;
                @media(max-width: 992px){
           
                }
                button,
                .button {
                    color: #506279;
                    text-decoration: none;
                    border: 0;
                    background: transparent;
                    padding: 0;
                    display: block;
                    text-align: left;
                    padding-right: 37px;
                    cursor: pointer;
                    // position: relative;
                    width: 100%;
                    @media(max-width: 992px){
                        text-align: left;
                        padding-right: 70px;
                        position: static;
                    }
                    &:hover,
                    &:focus {
                        text-decoration: none;
                        outline: 0;
                    }
                    &:after {
                        @include background-image("icons/icon-minus.svg", 20, 2);
                        position: absolute;
                        top: 41px;
                        right: 0;
                        @media(max-width: 992px){
                            @include background-image("icons/icon-minus.svg", 13, 2);
                            top: 36px;
                            right: 15px;
                        }
                    }
                    &.collapsed {
                        font-weight: 500;
                        &:after {
                            @include background-image("icons/medlearn/icon-plus.svg", 20, 20);
                            position: absolute;
                            top: 32px;
                            right: 0;
                            @media(max-width: 992px){
                                @include background-image("icons/medlearn/icon-plus.svg", 13, 13);
                                top: 30px;
                                right: 15px;
                            }
                        }
                    }

                }
            }
        }
    }

    &.studie{

        .card {

            &-header {
                padding: 35px 0;
                background-color: transparent;
                border: none;
                @media(max-width: 992px){
                    padding: 29px 0;
                }
                h2 {
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 36px;
                    line-height: 41px;
                    color: #506279;
                    display: block;
                    @media(max-width: 992px){
                        font-size: 24px;
                        line-height: 28px;
                        width: 87%;
                    }
                    button.arrowIcon,
                    .button.arrowIcon {
                        color: #506279;
                        &:after {
                            display: none;
                        }
                        &.collapsed {
                            &:after {
                            display: none;
                            }
                        }

                        span{                            
                            position: relative;
                            @media (max-width: 992px) {
                                position: inherit;
                            }
                            &:before{
                                @include background-image("./icons/medlearn/AccordionArrowDown.svg", 16, 23);
                                position: absolute;
                                right: -38px;
                                top: 10px;
                                transform: rotate(180deg);
                                @media (max-width: 992px) {
                                    @include background-image("./icons/medlearn/AccordionArrowDown.svg", 11.38, 16.34);
                                    right: 15px;
                                    top: 35px;
                                }
                            }
                        }
                        &.collapsed{
                            span{
                                &:before{
                                    transform: rotate(0deg);
                                }
                            }
                        }

                    }
                }
            }

            .card-body {
                // margin-left: calc(35.714% + 15px);
                // margin-right: calc(6.314% + 15px);
                margin-left: 0;
                margin-right: 0;
                padding: 0;

                @media (max-width: 992px) {
                    padding: 0;
                    margin: 0;
                    margin-top: -4px;
                    // margin-left: calc(2% + 15px);
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 40px;
                }

                // p{
                //     &:last-child{
                //         @media (max-width: 992px) {
                //             margin-bottom: 40px;
                //         }
                //     }
                // }


                .accordionContent__html-area{
                    @media(max-width: 992px) {
                       padding-left: 70px;
                       padding-right: 30px;
                    }
                }

                ul, ol{
                    margin-bottom: 20px;
                    @media (max-width: 992px) {
                        margin-bottom: 40px;
                    }

                    & + .box-orange-article{
                        margin-bottom: 0;
                        @media (max-width: 992px) {
                            margin-top: 0;
                        }
                    }

                }
                // .box-orange-article{
                //     @media (max-width: 992px) {
                //         margin-top: 0px;
                //     }
                //     p{
                //         &:last-child{
                //             @media (max-width: 992px) {
                //                 margin-bottom: 0px;
                //             }
                //         }
                //     }
                // }
                &.card-body-accordion{
                    margin-left: calc(6.214% + 15px);
                    margin-right: calc(6.314% + 15px);
                    @media (max-width: 992px) {
                        margin-right: 0;
                        margin-left: calc(2% + 15px);
                        margin-right: 15px;
                    }
                }

                .accordion{
                    border: none;

                    &.SUB-accordion{
                        margin-bottom: 80px;

                        @media (max-width: 992px) {
                            margin-bottom: 40px;
                        }

                        .card {
                            overflow: visible;
                            border-bottom: 1px solid #C4C4C4;

                            .card-header{
                                h2{
                                    button,
                                    .button{
                                        color: #506279;
                                    }
                                }
                            }

                            &:first-child{
                                .card-header{
                                    h2{
                                        button,
                                        .button{
                                            padding-top: 0;
                                            color: #506279;
                                            @media (max-width: 992px) {
                                                padding-top: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            &:last-child{
                                border-bottom: none;
                                @media (max-width: 992px) {
                                    // border-bottom: 1px solid #C4C4C4;
                                }

                                .card-body{
                                    margin-bottom: 40px;

                                    @media (max-width: 992px) {
                                        margin-bottom: 25px;
                                    }
                                }
                            }

                            .card-body{
                                // margin-left: calc(33.3% + 15px);
                                margin-bottom: 80px;

                                @media (max-width: 992px) {
                                    margin-left: 0;
                                    margin-bottom: 50px;
                                }

                                .accordionContent__html-area{
                                    overflow: visible;
                                    @media(max-width: 992px) {
                                       padding-left: 50px;
                                       padding-right: 0;
                                    }
                                    p, ul, ol, h3, h4, h5, h6, .courseTypeAccordion{
                                        margin-left: calc(35.3% + 15px);
                                        width: inherit;
                                        @media(max-width: 992px) {
                                            width:100%;
                                            margin-left: 0;
                                            font-size: 16px;
                                            line-height: 21px;
                                        }
                                    }
                                    p,ol,ul{
                                        @include font('EB Garamond', serif, 400, normal);    
                                        @media(max-width: 992px) {
                                            @include font('EB Garamond', serif, 500, normal);  
                                         }                                    
                                    }
                                    .box-orange-article{
                                        margin-left: calc(33.3% + 15px);
                                        width: calc(68% - 16px);
                                        @media(max-width: 992px) {
                                            width: 100%;
                                            margin-left: 0;
                                        }
                                    }

                                }
                            }
                            &:first-child{
                                .card-header{
                                    h2 {
                                        button,
                                        .button {
                                            &:after {
                                                top: 18px;
                                                @media(max-width: 992px){
                                                   top: 17px;
                                                }
                                            }
                                            &.collapsed {
                                                &:after {
                                                    top: 9px;
                                                    @media(max-width: 992px){
                                                       top: 12px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .card-header {
                            padding: 0;
                            background-color: transparent;
                            border: none;
                            @media(max-width: 992px){
                                padding: 0;
                            }
                            h2 {
                                @include font('Open Sans', sans-serif, 400, normal);
                                font-size: 26px;
                                line-height: 30px;
                                color: #506279;
                                @media(max-width: 992px){
                                    font-size: 18px;
                                    line-height: 21px;
                                    width: 87%;
                                }
                                button,
                                .button {
                                    padding: 35px 0;
                                    width: 100%;
                                    @media(max-width: 992px){
                                        padding: 25px 0;
                                    }
                                    small{
                                        @include font('Open Sans', sans-serif, 300, normal);
                                        font-size: 21px;
                                        line-height: 36px;
                                        color: #222222;
                                        @media(max-width: 992px){
                                            font-size: 17px;
                                            line-height: 28px;
                                            width: 87%;
                                        }
                                    }
                                    &:after {
                                        display: block;
                                        @include background-image("icons/icon-minus.svg", 20, 2);
                                        right: 0;
                                        top: 41px;
                                        @media(max-width: 992px){
                                            @include background-image("icons/icon-minus.svg", 12, 2);
                                            top: 34px;
                                        }
                                    }
                                    &.collapsed {
                                        &:after {
                                          display: block;
                                          @include background-image("icons/medlearn/icon-plus.svg", 20, 20);
                                          right: 0;
                                          top: 32px;
                                          @media(max-width: 992px){
                                            @include background-image("icons/medlearn/icon-plus.svg", 14, 14);
                                            top: 29px;
                                          }
                                        }
                                    }
                                }
                                span{
                                    &:before{
                                        display: none;
                                    }
                                }
                            }
                        }

                        .tippy-box[data-theme~='NKI'] {
                            @media (max-width: 768px) {
                                width: calc(71vw - 58px);
                            }
                            @media (max-width: 575px) {
                                width: calc(90vw - 58px);
                                margin-left: calc(1vw + 20px);
                            }
                        }

                    }

                }

            }

        }


    }
    &.faq{
        border-top: none;
        margin-bottom: 407px;
        @media (max-width: 992px) {
            margin-bottom: 100px;
        }
        .card-body {
            margin-top: 0;
            margin-bottom: 18px;
            @media (max-width: 992px) {
                margin-top: 0;
            }
        }
    }
}
.accordion {
    &--basic{
        margin-bottom: 2px;
        .card, .card-header, .card-header button, .card-header .button{
            background-color: transparent;
            border: none;

            &:focus {
                outline: none;
            }
        }
        .card-header button,
        .card-header .button{
            padding-right: 35px;
            font-size: 28px;
            line-height: 44px;
            color: #121212;
            border-bottom-width: 2px;
            position: relative;
            text-align: left;
            word-break: break-word;
            @media(max-width: 991px) {
                font-size: 22px;
                line-height: 32px;
            }
            span {
                border-bottom: 2px solid transparent;
                span{
                    position: relative;
                    &:after{
                        content: '';
                        width: 18px;
                        height: 20px;
                        background-image: url(../images/icons/arrow-down-menu.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        display: inline-block;
                        position: absolute;
                        right: -35px;
                        bottom: 10px;
                        transition: all 150ms linear;
                        @media(max-width: 991px) {
                            width: 14px;
                            height: 16px;
                            right: -26px;
                            bottom: 8px;
                        }
                    }
                }
            }

            &[aria-expanded="true"] {

                span {
                    border-color: rgba($color: $orange, $alpha: 0.8);
                    span{
                        &:after{
                            transform: rotate(180deg);
                        }
                    }
                }

            }

            &:hover, &:focus-visible {
                border-color: $orange;
            }
        }
        .card-body {
            padding-bottom: 25px;
            padding-top: 0;
            label {
                width: 100%;

            }
        }
    }
    &--purple {
        // height: 500px;
        margin-bottom: 0!important;
        @media(max-width: 992px) {
            // height: auto;
        }
        @media(min-width: 992px) {
            border-top: none;
        }
        .card {
            &:first-child{
                .card-header h2 button,
                .card-header h2 .button  {
                    // padding-top: 6px;
                    @media(max-width: 991px) {
                        padding-top: 24px;
                    }
                }
            }
            &-header {
                padding: 0;
                h2 {

                    @media(max-width: 991px) {
                        width: 100%;
                    }
                    button,
                    .button {
                        @include font('Open Sans', sans-serif, 400, normal);
                        font-size: 21px;
                        line-height: 36px;
                        color: #121212;
                        text-align: left;
                        padding: 33px 40px 33px 40px;
                        background-color: transparent;
                        transition: all 300ms linear;
                        @media(max-width: 991px) {
                            padding: 20px 0 24px;
                            background-color: transparent;
                            font-size: 17px;
                            line-height: 24px;
                            padding-right: 40px;
                        }
                        &[aria-expanded="true"]{
                            padding-bottom: 20px;
                            .btnTitle{
                                a{
                                    border-bottom: 2px solid #F6CDD6;
                                    @media(max-width: 991px) {
                                        border-bottom: 2px solid #F6CDD6;
                                    }
                                }

                            }
                        }
                        .btnTitle{
                            @media(max-width: 991px) {
                                margin-bottom: 10px;
                                display: block;
                            }
                        }
                        &.collapsed{
                            background-color: transparent;
                            &:hover {
                                @media (hover: hover) and (pointer: fine) {
                                    background-color: transparent;
                                    .btnTitle{
                                        a{
                                            border-bottom: 2px solid #F6CDD6;
                                            @media(max-width: 991px) {
                                                border-bottom: 2px solid #F6CDD6;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.collapsed:after{
                            top: 20px;
                            @media(max-width: 991px) {
                                top: 23px;
                            }
                        }
                        &:after{
                            top: 29px;
                            @media(max-width: 991px) {
                                top: 30px;
                            }
                        }
                        a{
                            text-decoration: none;
                            @include font('Open Sans', sans-serif, 400, normal);
                            font-size: 24px;
                            line-height: 42px;
                            color: #506279;
                            display: inline;
                            border-bottom: 2px solid transparent;
                            @media(max-width: 991px) {
                                font-size: 18px;
                                line-height: 21px;
                            }
                        }
                    }
                }
                .card-header__tags{
                    margin-top: 4px;
                    display: block;
                        span {
                            @include font('Open Sans', sans-serif, 300, normal);
                            font-size: 20px;
                            line-height: 42px;
                            color: #000000;
                            @media(max-width: 991px) {
                                font-size: 16px;
                                line-height: 18px;
                            }
                            + span {
                                &:before {
                                    content: '';
                                    vertical-align: middle;
                                    background-color: #000000;
                                    border-radius: 50%;
                                    margin: 12px;
                                    width: 8px;
                                    height: 8px;
                                    display: inline-block;
                                    opacity: 0.7;
                                    @media(max-width: 991px) {
                                        margin: 8px;
                                        width: 5px;
                                        height: 5px;
                                    }
                                }
                            }
                        }
                    }
            }
            .card-body {

                background-color: transparent;
                padding: 10px 100px 40px 40px;
                margin-top: 0;
                margin-left: 0;
                max-width: 850px;
                p {
                    @include font('Open Sans', sans-serif, 300, normal);
                    font-size: 20px;
                    line-height: 32px;
                    color: #000000;
                    @media(max-width: 991px) {
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
                @media(max-width: 991px) {
                    padding: 0 0 32px;
                    background-color: transparent;
                }
            }
        }
    }
}

.readMoreStudie{
    &+ .container + .quoteSmall{
        margin-top: 26px;
    }
}


.accordionGroup{
    border-top: 1px solid #D6D6D6;
    margin-bottom: 86px;

    @media(max-width: 992px){
        margin-bottom: 40px;
    }

    .accordion{
        border-top: none;
        // &:first-child{
        //     border-top: 1px solid #D6D6D6;
        // }
        margin-bottom: 0;

    }

    &__article{
        .accordion{
            &:last-child{                
                .card{
                    border-bottom: none;
                }
            }
        }
    }

}





.courseTypeAccordion{
    margin-top: 48px;
    padding-top: 48px;
    border-top: 1px #D6D6D6 solid;
    z-index: 0;
    @media (max-width: 992px) {
        margin-top: 28px;
        padding-top: 28px;
    }
    &__item{
        z-index: 1;
        margin-bottom: 60px;
        @media (max-width: 992px) {
            margin-bottom: 24px;
        }
        &.half{
            float: left;
            width: 50%;
            &:nth-child(2n+1){
                padding-right: 20px;
            }
            &:nth-child(2n){
                padding-left: 20px;
            }
        }
        .label{
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 25px;
            line-height: 40px;
            color: #B4B4B4;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }

        }
        .item,
        .item a{
            display: block;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 25px;
            line-height: 40px;
            color: #121212;
            b{
                font-weight: 400;
            }
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }

        .link{
            display: block;
            a{
                display: inline-block;
                @include font('Open Sans', sans-serif, 300, normal);
                font-size: 16px;
                line-height: 28px;                
                text-decoration-line: underline;
                color: #B4B4B4;
            }
        }
        .item{
            position: relative;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            @media (max-width: 992px) {
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        .item.help{
            padding-right: 35px;
            span{
                position: relative;
                .help {
                    padding: 0;
                    border-radius: 50%;
                    border: none;
                    background: #FBCFB7;
                    @include background-image("./icons/icon-question.svg", 20, 20);
                    position: absolute;
                    bottom: 7px;
                    right: -32px;
                    &:active,
                    &:focus{
                        outline: 0;
                    }
                    @media (max-width: 992px) {
                        @include background-image("./icons/icon-question.svg", 16, 16);
                        bottom: 3px;
                        right: -22px;
                    }
                }
            }

        }


    }

    .btnBuy{
        display: inline-block;
        text-decoration: none;
        border: none;
        background: #F68D6F;
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 21px;
        line-height: 28px;
        border-radius: 62px;
        padding: 20px 40px;
        color: #ffffff;
        transform: translateY(-22px);
        margin-bottom: 20px;
        z-index: 2;
        @media (max-width: 1200px) {
            transform: translateY(0);
            margin-bottom: 48px;
        }
        @media (max-width: 992px) {
            margin-top: 24px;
            margin-bottom: 22px;
            font-size: 18px;
            line-height: 28px;
            border-radius: 38px;
            padding: 14px 32px;
        }
        &:hover{
            border: none;
        }
    }

    .col-xs-12.col-lg-12{
        @media (max-width: 1200px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .col-xs-4.col-lg-4{
        @media (max-width: 1200px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

}

