.blogPosts{
    margin-bottom: 120px;
    @media(max-width: 992px){
        margin-bottom: 90px;
    }
    .line-article{
        @media (max-width:1680px) {
            margin-bottom: 48px;
        }
    }
    &__title{
        margin-bottom: 139px;
        @media(max-width: 992px){
            margin-bottom: 32px;
        }
        h2{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 36px;
            line-height: 41px;
            color: #506279;
            @media(max-width: 992px){
                font-size: 36px;
                line-height: 41px;
                text-align: center;
            }
        }
    }
    &.blogPage{
        @media(max-width: 992px){
            margin-bottom: 5px;
        }
        .blogPosts__title{
            margin-bottom: 78px;
            @media(max-width: 992px){
                margin-bottom: 31px;
            }
            h2{
                font-size: 48px;
                line-height: 55px;
                color: #506279;
                @media(max-width: 992px){
                    font-size: 36px;
                    line-height: 41px;
                    text-align: center;
                }
            }
        }
        .date{
            display: none!important;
            @media(max-width: 992px){
                display: block!important;
            }
        }
    }
    &.frontPage{
        .blogPosts__title{
            margin-bottom: 125px;
            @media(max-width: 992px){
                margin-bottom: 23px;
            }
            h2{
                font-size: 48px;
                line-height: 55px;
                color: #506279;
                @media(max-width: 992px){
                    font-size: 36px;
                    line-height: 41px;
                    text-align: center;
                }
            }
        }
    }
    &__sliderWrapper{
        &--slider{
            margin-right: -7%;
            margin-bottom: 60px;
            .slick-list{
                overflow: visible!important;
            }
            .slick-track{
                display: flex;
                // align-items: flex-end;
            }
            *{
                outline: none;
            }
            @media(max-width: 992px){
                margin-bottom: 70px;
                margin-right: 0;
            }
            .item{
                // width: 100%!important;
                // max-width: 33.2%!important;

                @media(max-width: 992px){
                    // max-width: 33.2%!important;
                }

                .content{
                    margin-right: 20%;
                    @media(max-width: 992px){
                        margin-right: 6.6%;
                    }
                }
                a{
                    text-decoration: none;
                }
                .imgWrapper{
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 30px;
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    padding-top: calc((285 / 434) * 100%);
                    @media(max-width: 992px){
                        margin-bottom: 20px;
                    }
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        transform: translate(-50%, -50%);
                        transition: all 250ms;
                    }
                }
                .info{
                    .date{
                        @include font('Open Sans', sans-serif, 400, normal);
                        font-size: 20px;
                        line-height: 23px;
                        display: flex;
                        align-items: center;
                        color: #C4C4C4;
                        margin-bottom: 18px;
                        @media(max-width: 992px){
                            font-size: 20px;
                            line-height: 23px;
                        }
                    }
                    .title{
                        @include font('Open Sans', sans-serif, 400, normal);
                        font-size: 24px;
                        line-height: 28px;
                        color: #506279;

                        @media(max-width: 992px){
                            font-size: 24px;
                            line-height: 28px;
                        }
                    }
                }
                &.blogSpin{
                    .content{
                        position: relative;
                        &:after{
                            @include background-image("./blog-icon.svg", 162, 162);
                            position: absolute;
                            right: -81px;
                            top: 16%;
                            -webkit-animation: spin 12500ms infinite linear;
                            animation: spin 12500ms infinite linear;
                            @media(max-width: 768px ){
                                @include background-image("./blog-icon.svg", 100, 100);
                                right: -50px;
                                display: none;
                            }
                            display: none;
                        }

                        @-webkit-keyframes spin {
                            0% {
                                -webkit-transform: rotate(0deg);
                                transform: rotate(0deg);
                            }
                            100% {
                                -webkit-transform: rotate(359deg);
                                transform: rotate(359deg);
                            }
                        }
                        @keyframes spin {
                            0% {
                                -webkit-transform: rotate(0deg);
                                transform: rotate(0deg);
                            }
                            100% {
                                -webkit-transform: rotate(359deg);
                                transform: rotate(359deg);
                            }
                        }


                    }
                }
                &:hover{
                    img{
                        opacity: .9;
                    }
                }
            }
        }
        &--button{
            text-align: center;
            a{
               display: inline-block;
                @media(max-width: 992px){

                }
            }
        }
        .slick-track{
            margin-left: unset !important;
            margin-right: unset !important;
        }
    }
}
