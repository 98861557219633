.inspirationBlocks{
    margin-bottom: 310px;
    @media(max-width: 992px){
        margin-bottom: 100px;
    }
    &__header{
        h2{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 60px;
            line-height: 72px;
            letter-spacing: -0.44px;
            color: #483A54;
            margin-top: 20px;
            word-break: break-word;
            @media (max-width:1680px) {
                font-size: 3.5749vw;
                line-height: 4.289vw;
            }
            @media(max-width: 992px){
                margin-top: 40px;
                font-size: 28px;
                line-height: 36px;
                margin-bottom: 12px;
            }
        }
        p{
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 25px;
            line-height: 40px;
            letter-spacing: 0.24px;
            color: #121212;
            margin-top: 25px;
            @media (max-width:1680px) {
                font-size: calc(12px + 0.784vw);
                line-height: 2.38vw;
                margin-top: 23px;
            }
            @media(max-width: 992px){
                font-size: 17px;
                line-height: 28px;
                letter-spacing: 0.24px;
                margin-top: 0;
                margin-bottom: 32px;
            }
        }
    }
    &__sliderWrapper{
        margin-top: 72px;
        @media(max-width: 992px){
            margin-top: 0;
        }
        *{
            outline: none;
        }
        .draggable{
            overflow: visible!important;
        }
        &--slider{
            .item{
                width: 400px;
                margin-right: 80px;
                position: relative;
                padding-bottom: 76px;
                @media(max-width: 992px){
                    max-width: 78vw;
                    margin-right: 22px;
                    padding-bottom: 0;
                }
                a{
                    text-decoration: none;
                }
                .imgWrapper{
                    width: 100%;
                    @media(max-width: 992px){
                        max-width: 78vw;
                    }
                    img{
                        transition: all 300ms;
                    }
                }
                .info{
                    background: #ffffff;
                    padding-top: 32px;
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    transition: all 300ms;
                    @media(max-width: 992px){
                        padding-top: 16px;
                        position: relative;
                        bottom: inherit;
                    }
                    h3{
                        @include font('Open Sans', sans-serif, 400, normal);
                        margin-bottom: 4px;
                        font-size: 25px;
                        line-height: 40px;
                        letter-spacing: -0.24px;
                        color: #121212;
                        @media(max-width: 992px){
                            font-size: 20px;
                            line-height: 32px;
                        }
                    }
                    p{
                        @include font('Open Sans', sans-serif, 300, normal);
                        font-size: 21px;
                        line-height: 36px;
                        letter-spacing: 0.24px;
                        color: #121212;
                        height: 0;
                        overflow: hidden;
                        transition: all 300ms;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        @media(max-width: 992px){
                            font-size: 15px;
                            line-height: 22px;
                            height: auto;
                            padding-right: 10px;
                        }
                        br{
                            display: none;
                        }
                    }
                }
                &:hover{
                    .imgWrapper{
                        img{
                            opacity: .9;
                        }
                    }
                    .info{
                        p{
                            height: 40px;
                            @media(max-width: 992px){
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    &__swipe {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-transform: uppercase;
        @include background-image("/bubble.svg", 160, 160);
        position: absolute;
        right: -20px;
        top: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        @media(max-width: 992px){
            // width: 100px;
            // height: 100px;
            display: none;
        }
    }
}
