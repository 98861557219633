#confirmationPage{
	
	.checkout__title{
		h1,h2,h3,h4 {
		    border-bottom: 1px solid #C4C4C4;
		    padding-bottom: 128px;
		    @media(max-width: 992px){
				padding-bottom: 44px;
		    }
		}
	} 

	.checkout__text-info{
		max-width: 810px;
		margin: 85px 0 400px 0;
		@media(max-width: 992px){
			margin: 0px 0 5px 0;
	    }
		p{
			margin-bottom: 30px;
		}
	}
}