.quoteBig{
    margin-bottom: 80px;
    z-index: 0;

    @media (max-width: 992px) {
        margin-bottom: 70px;
    }

    &.background-color-sand{
        background: #F0EEEA;
        padding: 68px 0;
    }

    .container{
        .row{
            @media (max-width: 992px) {
                flex-direction: column-reverse;
            }
        }
    }

    &__content{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // max-width: 397px;
        margin-right: 36px;
        margin-left: auto;
        @media(max-width: 992px){
            margin: 0 auto;
            max-width: 347px;
        }
        blockquote{
            @include font('Open Sans', sans-serif, 400, italic);
            font-size: 30px;
            line-height: 39px;
            color:  #444A57;
            margin-bottom: 52px;
            @media (max-width: 1200px){
                font-size: 36px;
                line-height: 44px;
            }
            @media (max-width: 992px) {
                margin-top: 0;
                font-size: 26px;
                line-height: 30px;
                margin-bottom: 15px;
                margin-top: 28px;
                max-width: 100%;
            }
            a{
                color:  #444A57;
                text-decoration: none;
            }
        }
        .info{
            @media(max-width: 992px){

            }
            .author{
                @media (max-width: 992px) {
                    height: 22px;
                }
                h4, a{
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 18px;
                    line-height: 108.5%;
                    letter-spacing: 0.24px;
                    color: #444A57;
                    display: inline-block;
                }
                h4{
                    a{
                        text-decoration: none;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .btnQuote{
                margin-top: 20px;
                @media (max-width: 992px) {

                }
                a{
                    @include font('Open Sans', sans-serif, 600, normal);
                    display: inline-block;
                    font-size: 16px;
                    line-height: 30px;
                    letter-spacing: 0.24px;
                    color: #444A57;
                    position: relative;
                    text-decoration-line: underline;
                    text-decoration-thickness: 2px;
                    text-decoration-color: #444A57;
                    text-underline-offset: 8px;
                    @media (max-width: 992px){

                    }

                }
            }

        }
    }
    &__image{
        position: relative;
        max-width: 487px;
        // margin-left: 23px;
        @media(max-width: 992px){
            margin: 0 auto;
        }
        // &:before{
        //     z-index: -1;
        //     content: url("../images/medlearn/form-quote-big.svg");
        //     height: auto;
        //     right: 100%;
        //     top: calc(100% - 260px);
        //     position: absolute;

        //     @media (max-width: 1350px) {
        //         transform: scale(.6);
        //         right: calc(100% - 95px);
        //     }

        //     @media (max-width: 1200px) {
        //         top: calc(100% - 230px);
        //     }

        //     @media(max-width: 992px){
        //         content: url("../images/medlearn/form-quote-mobile.svg");
        //         transform: scale(1);
        //         right: calc(100%);
        //         top: initial;
        //         bottom: 0;
        //     }
        // }

        // .eyeWrapper{
        //     position: absolute;
        //     top: 61.5%;
        //     transform: translateY(-50%);
        //     left: 0;
        //     margin-left: -70px;
        //     @include background-image("../images/eye_star.svg", 140, 140);
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;

        //     @media(max-width: 992px){
        //         @include background-image("../images/eye_star.svg", 100, 100);
        //         margin-left: -50px;
        //         display: flex;
        //     }

        //     @media(max-width: 768px){
        //         @include background-image("../images/eye_star.svg", 92, 92);
        //         margin-left: -46px;
        //         top: 66.5%;
        //         display: flex;
        //     }

        //     .eye{
        //         background-size: 100%;
        //         @include background-image("../images/eye_socket.svg", 60, 32);
        //         border-radius: 50%;
        //         position: relative;
        //         @media(max-width: 992px){
        //             @include background-image("../images/eye_socket.svg", 52, 28);
        //         }
        //         @media(max-width: 768px){
        //             @include background-image("../images/eye_socket.svg", 40, 21);
        //         }
        //     }

        //     .ball{
        //         width: 10px;
        //         height: 10px;
        //         background: #483A54;
        //         position: absolute;
        //         top: 50%;
        //         left: 50%;
        //         transform: translate(-50%,-50%);
        //         border-radius: 50%;
        //         @media(max-width: 992px){
        //             width: 6.57px;
        //             height: 6.57px;
        //         }
        //     }

        // }

    }
}


.quoteSmall{
    margin-bottom: 200px;
    @media (max-width: 992px) {
        margin-bottom: 100px;
    }
    z-index: 0;

    &__image{
        width: 100%;
        max-width: 192px;
        margin-right: 50px;
        z-index: 0;
        position: relative;
        float: right;

        @media (max-width: 992px) {
            margin-right: 0;
        }

        &:before{
            z-index: -1;
            content: url("../images/medlearn/form-quote-small.svg");
            width: 200%;
            max-width: 200%;
            height: auto;
            left: -30px;
            top: -70px;
            position: absolute;
        }

    }
    &__content{
        blockquote{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 56px;
            line-height: 64px;
            letter-spacing: -0.16px;
            color:  #444A57;
            margin-bottom: 24px;
            @media (max-width: 1200px){
                font-size: 36px;
                line-height: 44px;
                margin-bottom: 16px;
            }
            @media (max-width: 992px){
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 16px;
            }
            a{
                color: #444A57;
                text-decoration: none;
            }

        }
        .info{
            h4{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                color: #121212;
                @media (max-width: 992px){
                    font-size: 14px;
                    line-height: 22px;
                }
            }
            a{
                text-decoration: none;
                @include font('Open Sans', sans-serif, 300, normal);
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                color: #121212;
                padding-bottom: 5px;
                border-bottom: thin solid rgba(246,141,111,0.5);
                &:hover{
                    border-bottom: thin solid rgba(246,141,111,1);
                }
                @media (max-width: 992px){
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }

}
