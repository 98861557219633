@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700;800&display=swap');


// ------------------------------------------------------------------ //
// Font: Open Sans                                                    //
// ------------------------------------------------------------------ //
// @include font('Open Sans', sans-serif, 300, normal);                //
// @include font('Open Sans', sans-serif, 400, normal);               //
// @include font('Open Sans', sans-serif, 600, normal);               //
// @include font('Open Sans', sans-serif, 700, normal);               //
// @include font('Open Sans', sans-serif, 800, normal);               //
// ------------------------------------------------------------------ //


// ------------------------------------------------------------------ //
// Font: EB Garamond                                                  //
// ------------------------------------------------------------------ //
// @include font('EB Garamond', serif, 400, normal);                  //
// @include font('EB Garamond', serif, 500, normal);                  //
// @include font('EB Garamond', serif, 600, normal);                  //
// @include font('EB Garamond', serif, 700, normal);                  //
// @include font('EB Garamond', serif, 800, normal);                  //
// ------------------------------------------------------------------ //

// Font sizes
$p-sm: .875rem;   // 14
$p: 1rem;         // 16
$p-m: 1.3125rem; // 21
$p-lg: 1.5625rem; // 25

// 12 13(.8125) *14 15(.9375) *16 17(1.0625) 18 19 20(1.25) *21 22(1.375) 24 *25 28(1.75) 29 32

html,
body {
  font-size: 16px;
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  @include font('Open Sans', sans-serif, 400, normal);
}
