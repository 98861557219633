header{
    position: relative;
    z-index: 3;
    margin-bottom: 35px;
    @media (max-width: 992px){
      margin-bottom: 0;
    }

    @media (max-width: 425px){
      .container{
        padding: 0 10px;
      }
    }

    .header__wrapper{
      display: flex;
      justify-content: flex-end;
      height: 140px;
      width: 100%;
      position: relative;
      &.transparent{
        background: transparent;
      }

      @media (max-width: 992px){
        height: 110px;
      }

      &--logo{
        position: absolute;
        top: 40px;
        left: 0;

        @media (max-width: 992px) {
          top: 40px;
        }

        img{
          width: 100vw;
          max-width: 270px;
          height: auto;
          @media (max-width: 1390px) {
            max-width: 210px;
            top: 40px;
          }
          @media (max-width: 992px) {
            width: 180px;
          }
          &.mobile{
            display: none;
            @media (max-width: 992px) {
              display: block;
            }
          }
          &.desktop{
            @media (max-width: 992px) {
              display: none;
            }
          }
        }
      }


      .main__menu{
        display: flex;
        align-items: center;

        &--desktop{
          display: block;
          @media (max-width: 992px){
            display: none;
          }
          .item{
            position: relative;
            color: #000000;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 24px;
            line-height: 100%;
            font-weight: 400;
            margin-left: 62px;
            text-decoration: none;
            display: inline-block;

            @media (max-width: 1390px) {
              font-size: 20px;
              margin-left: 30px;
            }

            @media (max-width: 1199px) {
              font-size: 18px;
              margin-left: 26px;
            }


            &.dropDown{
              padding-right: 26px;
              &::after{
                @include background-image("./icons/medlearn/arrow-down-menu.svg", 16,22);
                position: absolute;
                right: 0;
                top: 1px;
              }
            }

            &.shoppingCart{
              padding-right: 38px;
              &::after{
                @include background-image("./icons/medlearn/shopping_cart.svg", 17, 21);
                position: absolute;
                right: 6px;
                top: 0px;
              }
            }

            &.search{
              padding-right: 38px;
              &::after{
                @include background-image("./icons/medlearn/search-icon.svg", 21, 21);
                position: absolute;
                right: 6px;
                top: 0px;
              }
            }

            &:before{
              transition: all 150ms;
              opacity: 0;
              content: "";
              width: 100%;
              height: 2px;
              background: #F6CDD6;
              position: absolute;
              left: 0;
              bottom: -5px;
            }

            &:hover{
              &:before{
                opacity: 1;
              }
            }

          }

        }

        &--mobile{
          display: none;
          @media (max-width: 992px){
            display: block;
          }

          .item{
            position: relative;
            margin-left: 28px;
            text-decoration: none;
            color: #000000;

            @media (max-width: 425px){
              margin-left: 28px;
            }

            &.shoppingCart{
              @include background-image("./icons/medlearn/shopping_cart.svg", 17, 22);
            }

            &.search{
              @include background-image("./icons/medlearn/search-icon.svg", 21, 21);
            }

            &.menu-hamburguer{
              @include background-image("./icons/medlearn/menu-hamburguer.svg", 23, 20);
            }

          }

        }
      }
    }
    &.header--steps {
      margin-bottom: 0;
      .header__wrapper {
        height: 128px;
         justify-content: flex-end;
        @media (max-width: 992px){
          display: block;
          height: auto;
        }
        &--logo {
          @media (max-width: 992px){
            position: static;
            img {
              padding: 40px 0;
            }
          }
        }
      }
      .main__menu {
        @media (max-width: 992px) {
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 48px;
        }
        .item {
            position: relative;
            color: #506279;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 24px;
            line-height: 28px;
            margin-left: 40px;
            text-decoration: none;

            @media (max-width: 1390px) {
              font-size: 18px;
              line-height: 36px;
            }

            @media (max-width: 1200px) {
              font-size: 18px;
              line-height: 36px;
              margin-left: 25px;
            }

            @media (max-width: 992px) {
              margin-left: 0;
              font-size: 18px;
              line-height: 30px;
              color: #000000;
            }

          &.done {
            color: #C4C4C4;
          }
          &.active {
            border-bottom: 2px solid #F6CDD6;
            color: #506279;
          }
          &.disabled {
            color: #C4C4C4;
            pointer-events: none;
            @media (max-width: 992px) {
              color: #878787;
            }
          }
        }
      }
    }

    .dropDownMenu{
      background: url("../images/medlearn/form-header.svg") no-repeat #506279;
      background-size: 1194px 861px;
      background-position-x: 33vw;
      background-position-y: 22vh;
      position: fixed;
      width: 100vw;
      height: 100%;
      top: calc(-100% - 200px);
      overflow-x: hidden;
      transition: all 350ms;


      &.subActive{
        @media (max-width: 992px){
          background-color: #748A9B;
        }
      }


      @media (max-width: 992px) {
        background-size: 809px 765px;
        background-position-x: 21vw;
        background-position-y: 90px;
      }
      &.active{
        top: 0;
        .btnClose{
          @media (max-width: 992px){
            position: fixed;
          }
        }
        @media (max-width: 992px){
          .btnBack {
            display: block;
          }
        }
      }

      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: none;
      }
      .btnBack {
        border-radius: 0;
        height: 80px;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 3;
        background-color: #323E4F!important;
        border-color: #323E4F!important;
        display: none;
        color: #FFFFFF;
        &:focus,
        &:hover,
        &:active{
          box-shadow: none!important;
          color: #FFFFFF!important;
        }
      }
      .btnClose {
        margin-left: auto;
        @media(max-width: 992px) {
            font-size: 0;
            padding: 16px;
            top: 24px;
            right: 20px;
            z-index: 99;
        }
        img{
          width: 20px;
          height: auto;

          margin-bottom: 2px;
        }

      }
      &__top {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        padding-top: 20px;
        margin-bottom: 50px;

        @media(max-height: 780px) and (min-width: 993px) {
          margin-bottom: 6vh;
        }
        @media(max-width: 992px) {
          padding-top: 85px;
          margin-bottom: 0;
          height: auto;
        }
        .dropDownMenu__logo{
          @media(max-width: 992px) {
            display: none;
          }
          img {
            width: 100%;
            max-width: 270px;
            margin-left: 10px;
            height: auto;
            @media(max-width: 992px) {
              margin-left: 0;
            }
          }
        }
        a {
          position: relative;
          color: #ffffff;
          @include font('Open Sans', sans-serif, 400, normal);
          font-size: 18px;
          line-height: 1;
          font-weight: 500;
          text-decoration: none;
          display: inline-block;
          top: unset;
        }
        .search{
          margin-left: 28px;
          padding-right: 38px;
          @media(max-width: 992px) {
            display: none;
          }
          &::after {
            background-image: url(../images/./icons/medlearn/search-icon-light.svg);
            background-repeat: no-repeat;
            background-position: center center;
            width: 21px;
            height: 21px;
            content: "";
            display: inline-block;
            background-size: cover;
            position: absolute;
            right: 6px;
            top: 0px;
          }
        }
      }
      &__col {

        @media(max-width: 992px) {
          position: absolute;
          width: calc(100% - 40px);
          margin-top: 0;

          &.active{
            display: block;
          }
        }

        @media(min-height: 900px) {
          margin-top: 50px;
        }

      }
      &__desktop{
        z-index: 0;
        display: block;

        .item--more {
          padding-right: 50px;
          span{
            position: relative;
            &:after{
              opacity: 0;
              @include background-image("./icons/medlearn/link-menu-arrow.svg", 21.96, 14.96);
              position: absolute;
              right: -35px;
              top: 50%;
              margin-top: -7px;
              transform: translateX(-30px);
              pointer-events: none;
            }

          }
          a:hover{
            span:after{
              opacity: 1;
              transition: all 0.1s;
              @media (min-width: 993px){
                transform: translateX(0);
              }
            }
          }
        }
        &--itemsLine {
          display: none;
        }
        &--items{
          padding-left: 35px;
          @media (max-width: 993px){
            padding-left: 0;
          }
          .item{
            margin-bottom: 32px;
            @media(max-height: 780px) and (min-width: 993px){
              margin-bottom: 3.6vh;
            }
            a{
              @include font('Open Sans', sans-serif, 400, normal);
              text-decoration: none;
              font-size: 28px;
              line-height: 44px;
              color: #ffffff;
              position: relative;
              overflow-wrap: break-word;
              word-wrap: break-word;
              -webkit-hyphens: auto;
              -moz-hyphens: auto;
              -ms-hyphens: auto;
              hyphens: auto;
              border-bottom: 2px solid transparent;
              @media (max-width:1680px) {
                font-size: 28px;
                line-height: 40px;
              }

                &:hover{
                  border-bottom: 2px solid #F6CDD6;
                }

              @media (min-width:993px) {
                &.active{
                  border-bottom: 2px solid #F6CDD6;
                  &:after{
                    border-bottom: 2px solid #F6CDD6;
                  }
                }
              }

            }
            &--sm {
              margin-bottom: 12px;
              a {
                font-size: 21px;
                line-height: 36px;
                @media (max-width:1680px) {
                  font-size: 1.251vw;
                  line-height: 2.1445vw;
                }
              }
            }
          }
        }
        &--subItems, &--sub-subItems{
          pointer-events: none;
          opacity: 0;
          transform: translateX(-100px);
          position: absolute;
          top: 0;
          left: 20;

          .thisItem,
          .item{
            margin-bottom: 22px;
            @media(max-height: 780px) and (min-width: 993px) {
              margin-bottom: 3.05vh;
            }
            a{
              @include font('Open Sans', sans-serif, 400, normal);
              text-decoration: none;
              font-size: 18px;
              line-height: 36px;
              color: #ffffff;
              position: relative;
              overflow-wrap: break-word;
              word-wrap: break-word;
              -webkit-hyphens: auto;
              -moz-hyphens: auto;
              -ms-hyphens: auto;
              hyphens: auto;
              border-bottom: 2px solid rgba(246,205,214, .5);

              @media(max-width: 993px) {
                border-bottom: 2px solid #F6CDD6;
              }

              @media(min-width: 993px) {
                &:hover, &.active{
                  border-bottom: 2px solid #F6CDD6;
                }
              }

            }
          }
          &.active{
            pointer-events: all;
            transition: all 350ms;
            opacity: 1;
            transform: translateX(0);
          }
        }
        @media (max-width: 992px){
          position: relative;

          &--itemsLine{
            margin-bottom: 46px;
            display: block;
            a{
              @include font('Open Sans', sans-serif, 400, normal);
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              text-decoration: none;
              margin-right: 24px;
              display: inline-block;
              position: relative;
              border-bottom: 2px solid #F6CDD6;

              &:hover{
                border-bottom: 2px solid #F6CDD6;
              }
            }
          }

          &--items{
            margin-bottom: 120px;
            display: none;
            &.active {
              display: block;
            }
            .item{
              margin-bottom: 24px;
              > a{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 24px;
                line-height: 32px;
                color: #ffffff;
                text-decoration: none;
                position: relative;
                border-bottom: 2px solid #F6CDD6;

                span{
                  position: relative;
                }

                &:hover,
                &.active{
                  border-bottom: 2px solid #F6CDD6;
                }


              }
            }
          }
          .item--more{
            padding-right: 0px;
            >a{
              span{
                &:after{
                  opacity: 1;
                  transition: all 150ms;
                  @include background-image("./icons/arrow-down-item-menu.svg", 15, 17);
                  position: absolute;
                  right: -27px;
                  top: 50%;
                  margin-top: -7.5px;
                  pointer-events: none;
                  transform: rotate(-90deg);
                  display: none;
                }
              }
            }
          }
          &--subItems, &--sub-subItems{
            margin-bottom: 120px;
            transform: translateX(100px);
            &.active {
              position: static;
            }

            .thisItem,
            .item{
              margin-bottom: 30px;
              a {
                font-size: 24px;
                line-height: 32px;
              }
            }
            .item--more{
              >a{
                span{
                  &:after{
                    width: 14px;
                    height: 16px;
                  }
                }
              }
            }
          }


        }
      }
      &__mobile{

        padding: 85px 0 85px 0;
        position: relative;
        display: none;

        &__btn-back{
          border-radius: 0;
          height: 80px;
          width: 100%;
          position: fixed;
          left: 0;
          bottom: 0;
          right: 0;
        }

        &--itemsLine{
          margin-bottom: 46px;
          a{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 16px;
            line-height: 24px;
            color: #212121;
            text-decoration: none;
            margin-right: 24px;
            display: inline-block;
            position: relative;
            border-bottom: 2px solid #F6CDD6;

            &:hover{
              border-bottom: 2px solid #F6CDD6;
            }
          }
        }

        &--items{
          .item{
            margin-bottom: 24px;
            > a{
              @include font('Open Sans', sans-serif, 400, normal);
              font-size: 22px;
              line-height: 32px;
              color: #506279;
              text-decoration: none;
              position: relative;
              border-bottom: 2px solid #F6CDD6;

              span{
                position: relative;
              }

              &:hover,
              &.active{
                border-bottom: 2px solid #F6CDD6;
              }

            }

            .subItems{
              transition: all 150ms;
              margin-top: 0;
              opacity: 0;
              height: 0;
              overflow: hidden;
              .subItem{
                margin-bottom: 18px;
                &:last-child{
                  margin-bottom: 15px;
                }
                a{
                  @include font('Open Sans', sans-serif, 400, normal);
                  font-size: 16px;
                  line-height: 24px;
                  color: #212121;
                  position: relative;
                  text-decoration: none;
                  border-bottom: 2px solid #F6CDD6;

                  &:hover{
                    border-bottom: 2px solid #F6CDD6;
                  }

                }
              }
              &.active{
                height:auto;
                opacity: 1;
                margin-top: 20px;
              }
            }

            &.item--expandable{
              >a{
                span{
                  &:after{
                    transition: all 150ms;
                    @include background-image("./icons/arrow-down-item-menu.svg", 15, 17);
                    position: absolute;
                    right: -27px;
                    top: 50%;
                    margin-top: -7.5px;
                    rotate: -90deg;
                    pointer-events: none;
                    display: none;
                  }
                }
              }
            }

          }
        }


      }
    }

    > .inner-wrapper-sticky{
      transition: top 0.2s ease-in-out, background 0.2s;
      width: 100%!important;
      &.nav-up {
          top: -140px !important;
      }
    }

    &.header--steps{
      > .inner-wrapper-sticky{
        &.nav-up {
            top: -310px !important;
        }
      }
    }

    &.is-affixed{
      .inner-wrapper-sticky{
        background-color: #fff;
        // box-shadow: 0px 3px 30px 1px #0000001a;

        .header__wrapper{
          @media (max-width: 992px){
            margin-top: 0;
          }
          @media (max-height: 700px){ // If viewport is less than 700px tall

            margin-top: 0;
          }
          .header__wrapper--logo{
            @media (max-height: 700px){ // If viewport is less than 700px tall
              top: 24px;
            }

            @media (max-width: 992) , (max-height: 700px){ // If viewport is less than 992px wide and 700px tall
              top: 40px;
            }
          }
        }
      }
    }
  }
