.box-orange {
    @include font('Open Sans', sans-serif, 300, normal);
    font-weight: 300;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: 0.24px;
    color: #121212;
    padding: 50px 55px;
    background-color: $brandOrange;
    @media(max-width: 992px) {
        font-size: 17px;
        line-height: 24px;
    }
    strong{
        @include font('Open Sans', sans-serif, 600, normal);
    }
    a {
        text-decoration: none;
        color: $brandPurple;
        padding-bottom: 0.24em;
        border-bottom: 2px solid $orange;
        &:hover {
            color: $brandPurple-h;
            text-decoration: none;
        }
    }
    div{
        margin-bottom: 4px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.box-gray {
    @include font('Open Sans', sans-serif, 300, normal);
    font-weight: 300;
    font-size: 21px;
    line-height: 36px;
    letter-spacing: 0.24px;
    color: #121212;
    padding: 50px 55px;
    background-color: #E1DFD8;
    @media(max-width: 992px) {
        font-size: 17px;
        line-height: 24px;
    }
    strong{
        @include font('Open Sans', sans-serif, 600, normal);
    }
    a {
        text-decoration: none;
        color: #506279;
        padding-bottom: 0.24em;
        border-bottom: 2px solid #F6CDD6;
        &:hover {
            color: #506279;
            text-decoration: none;
        }
    }
    div{
        margin-bottom: 4px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.box-orange-article {
    background-color: #E1DFD8;
    padding: 48px 60px;
    margin-top: 40px;
    margin-bottom: 60px;
    display: inline-block;
    margin-left: calc(37.5% + 15px);
    width: calc(62.5% - 16px);
    @media(max-width: 992px) {
        width: 100%;
        margin-left: 0;
        padding: 24px 24px;
        margin-top: 12px;
        margin-bottom: 40px;
    }

    h1,h2,h3,h4,h5,h6{
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 21px;
        line-height: 36px;
        color: #222222!important;
        margin-bottom: 20px!important;
        width: 100%!important;
        margin-left: 0!important;
        padding-top: 0!important;
        @media(max-width: 992px) {
            font-size: 17px;
            line-height: 28px;
            margin-bottom: 15px!important;
        }
    }
    p{
        @include font('Open Sans', sans-serif, 300, normal);
        font-size: 21px;
        line-height: 36px;
        letter-spacing: 0.24px;
        color: #222222!important;
        width: 100%!important;
        margin-left: 0!important;
        &:last-child{
            margin-bottom: 0;
        }
        @media(max-width: 992px) {
            font-size: 17px;
            line-height: 28px;
        }

        a {
            color: $brandPurple;
            padding-bottom: 0.24em;
            border-bottom: 2px solid $orange;
            text-decoration: none;
            padding-bottom: 0!important;
            &:hover {
                color: $brandPurple-h;
                text-decoration: none;
            }
        }

    }


}
.card-info {
    @include font('Open Sans', sans-serif, 300, normal);
    font-size: 21px;
    line-height: 36px;
    letter-spacing: 0.24px;
    color: #222222;
    padding: 40px 45px 0px;
    border: 1px solid rgba($color: $brandPurple, $alpha: 0.2);
    margin-bottom: 20px;
    width: 100%;
    position: relative;

    @media(max-width: 992px) {
        font-size: 15px;
        line-height: 28px;
        padding: 20px 20px 0px;
    }

    h6 {
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 24px;
        line-height: 44px;
        letter-spacing: -0.24px;
        color: #483A54;
        margin-bottom: 16px;
        @media(max-width: 992px) {
            font-size: 18px;
            line-height: 32px;
        }
    }
    ul{
        padding-left: 20px;
        li {
            margin-bottom: 8px;
            padding-left: 10px;
        }
    }
    &__topics{

        width: 100%;
        span {
            float:left;
            @include font('Open Sans', sans-serif, 600, normal);
            @media(max-width: 992px) {
                float: none;
                margin-bottom: 8px;
                display: inline-block;
            }
        }
        ul{
            margin-bottom: 24px;
        }
        ul, .btn {
            margin-left: calc(53.2% - 15px);
            @media(max-width: 992px) {
                margin-left: 0;
            }
        }

    }
    & >:last-child{
        margin-bottom: 48px;
        @media(max-width: 992px) {
            margin-bottom: 25px;
        }
    }
    .disclaimer {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.24px;
        color: #121212;
        text-align: center;
        background-color: rgba($color: $yellow, $alpha: 0.5);
        margin: 0 -45px;
        padding: 24px 100px;
        @media(max-width: 992px) {
            padding: 24px 15px 24px 15px;
            font-size: 15px;
            margin: 0 -20px;
        }
    }
}

.mb-132{
    margin-bottom: 132px;
    @media(max-width: 992px) {
        margin-bottom: 56px;
    }
}
