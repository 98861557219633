.promoted-articles{
    @media(max-width: 992px) {
        margin-bottom: 40px;
    }
    &__sand {
        background-color: #F7F7F4;
        padding: 80px 0 0 0;
        margin-bottom: 80px;
    }
    &__wrapper{
        // max-width: 975px;
        max-width: 100%;
        margin: 0 auto;
        @media(max-width: 992px) {
            max-width: 564px;
        }
    }
    &__title{
        @include font('Open Sans', sans-serif, 600, normal);
        font-size: 38px;
        line-height: 49px;
        margin-bottom: 25px;
        color: #444A57;
    }
    &__items{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 40px;
    }
    &__item{
        margin-bottom: 80px;
        @media(max-width: 992px) {
            margin-bottom: 40px;
        }
        .content{
            a{
                text-decoration: none;
                &:hover{
                    .image{
                        opacity: .9;
                    }
                }
            }
            .image{
                margin-bottom: 25px;
                position: relative;
                width: 100%;
                overflow: hidden;
                transition: all 250ms;
                // 260x200
                padding-top: calc((200 / 350) * 100%);
                @media (max-width: 992px) {
                    padding-top: calc((200 / 350) * 100%)!important;
                }
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 100%;
                    width: 100%;
                    transform: translate(-50%, -50%);
                    object-fit: cover;
                }
            }
            .ingress{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 18px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 15px;
            }
            .link-text{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 18px;
                line-height: 23px;
                color: #000000;
                text-decoration: underline;
            }
        }
    }


    // TEMPLATE: Default
    &.layout-default{
        .promoted-articles{
            &__item{
                width: calc(100% / 3 - 27px);
                @media(max-width: 992px) {
                    width: 100%;
                }
                .content{
                    // max-width: 260px;
                    // max-width: 80.008%;
                    @media(max-width: 992px) {
                        max-width: 100%;
                    }
                }
                .image{
                    // 260x200
                    padding-top: calc((200 / 350) * 100%);
                }
                &:nth-child(3n+2){
                    .content{
                        margin: 0 auto;
                    }
                }
                &:nth-child(3n+3){
                    .content{
                        margin-left: auto;
                    }
                }
            }
        }
    }

    // TEMPLATE: 2 , 4 , 8
    &.layout-2,
    &.layout-4,
    &.layout-8{
        .promoted-articles{
            &__item{
                width: calc(50% - 20px);
                @media(max-width: 992px) {
                    width: 100%;
                }
                .content{
                    max-width: 100%;
                    @media(max-width: 992px) {
                        max-width: 100%;
                    }
                }
                .image{
                    // 475x400
                    padding-top: calc((450 / 546) * 100%);
                }
                &:nth-child(even){
                    .content{
                        margin-left: auto;
                    }
                }
            }
        }
    }

    // TEMPLATE: 3 , 6 , 9
    &.layout-3,
    &.layout-6,
    &.layout-9{
        .promoted-articles{
            &__item{
                width: calc(100% / 3 - 27px);
                @media(max-width: 992px) {
                    width: 100%;
                }
                .content{
                    // max-width: 260px;
                    // max-width: 80.008%;
                    @media(max-width: 992px) {
                        max-width: 100%;
                    }
                }
                .image{
                    // 260x200
                    padding-top: calc((200 / 350) * 100%);
                }
                &:nth-child(2),
                &:nth-child(5),
                &:nth-child(8){
                    .content{
                        margin: 0 auto;
                    }
                }
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(9){
                    .content{
                        margin-left: auto;
                    }
                }
            }
        }
    }

    // TEMPLATE: 5 , 10
    &.layout-5,
    &.layout-10{
        .promoted-articles{
            &__item{
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8){
                    width: calc(100% / 3 - 27px);
                    @media(max-width: 992px) {
                        width: 100%;
                    }
                    .content{
                        // max-width: 260px;
                        // max-width: 80.008%;
                        @media(max-width: 992px) {
                            max-width: 100%;
                        }
                    }
                    .image{
                        // 260x200
                        padding-top: calc((200 / 350) * 100%);
                    }
                }
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(9),
                &:nth-child(10){
                    width: calc(50% - 20px);
                    @media(max-width: 992px) {
                        width: 100%;
                    }
                    .content{
                        max-width: 100%;
                        @media(max-width: 992px) {
                            max-width: 100%;
                        }
                    }
                    .image{
                        // 475x400
                        padding-top: calc((450 / 546) * 100%);
                    }
                }
                &:nth-child(2),
                &:nth-child(7){
                    .content{
                        margin: 0 auto;
                    }
                }
                &:nth-child(3),
                &:nth-child(5),
                &:nth-child(8),
                &:nth-child(10){
                    .content{
                        margin-left: auto;
                    }
                }

            }
        }
    }

    // TEMPLATE: 7
    &.layout-7{
        .promoted-articles{
            &__item{
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3){
                    width: calc(100% / 3 - 27px);
                    @media(max-width: 992px) {
                        width: 100%;
                    }
                    .content{
                        // max-width: 260px;
                        // max-width: 80.008%;
                        @media(max-width: 992px) {
                            max-width: 100%;
                        }
                    }
                    .image{
                        // 260x200
                        padding-top: calc((200 / 350) * 100%);
                    }
                }
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7){
                    width: calc(50% - 20px);
                    @media(max-width: 992px) {
                        width: 100%;
                    }
                    .content{
                        max-width: 100%;
                        @media(max-width: 992px) {
                            max-width: 100%;
                        }
                    }
                    .image{
                        // 475x400
                        padding-top: calc((450 / 546) * 100%);
                    }
                }
                &:nth-child(2){
                    .content{
                        margin: 0 auto;
                    }
                }
                &:nth-child(3),
                &:nth-child(5),
                &:nth-child(7){
                    .content{
                        margin-left: auto;
                    }
                }
            }
        }
    }


}
