.question{
    margin-bottom: 77px;
    @media(max-width: 991px) {
        margin-bottom: 100px;
    }
    &__wrapper{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media(max-width: 991px) {
            justify-content: center;
        }
        a{
            display: block;
            background: #94C6D4;
            padding: 20px 35px;
            @include font('Open Sans', sans-serif, 700, normal);
            font-size: 20px;
            line-height: 23px;
            color: #463B52;
            text-decoration: none;
            border-radius:100px 100px 100px 0;
            position: relative;
           
            &:after{
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 54.4px;
                height: 27.1px;
                background: url(../images/icons/medlearn/arrow-blue.svg) no-repeat top left;
            }
            @media(max-width: 991px) {
                background: #F6CDD6;
                &:after{
                    background: url(../images/icons/medlearn/arrow-blue-active.svg) no-repeat top left;    
                }   
            }
        }
    }
}