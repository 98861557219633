.ml-40{
    margin-left: 40px;
    @media(max-width: 992px){
        margin-left: 0;
    }
}

.mr-40{
    margin-right: 40px;
    @media(max-width: 992px){
        margin-right: 0;
    }
}

.blogPageHeader{
    margin-bottom: 106px;
    @media(max-width: 920px){
        margin-bottom: 12px;
    }
    &__image{
        max-width: 539px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 144px;
        text-align: center;
        @media(max-width: 920px){
            margin-bottom: 15px;
            max-width: 260px;
        }
        img{
            max-width: 539px;
            display: inline-block;
            @media(max-width: 920px){
                max-width: 70%;
            }
        }
    }
    &__title{
        margin-bottom: 80px;
        text-align: center;
        @media(max-width: 920px){
            margin-bottom: 48px;
        }

        h1{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 250px;
            line-height: 287px;
            text-align: center;
            color: #506279;
            @media(max-width: 920px){
                font-size: 70px;
                line-height: 80px;                
            }
            img{
                width: 142px;
                @media(max-width: 920px){
                    width: 45px;
                    height: auto;
                }
            }
        }

    }
    &__ingress{
        margin: 0 auto;
        max-width: 746px;
        @media(max-width: 920px){
            max-width: 300px;
        } 
        p{
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 34px;
            line-height: 39px;
            text-align: center;
            color: #010101;
            text-align: center;
            @media(max-width: 920px){
                font-size: 20px;
                line-height: 30px;
                width: 100%;
                max-width: 91%;
                margin: 0 auto;
                br{
                    display: none;
                }
            }
        }
    }
}

.blogPageCategory{
    margin-bottom: 120px;
    @media(max-width: 1240px){
        margin-bottom: 34px;
    }

    &__items{
        border-bottom: 2px solid #C4C4C4;
        position: relative;

        @media(max-width: 1240px){
            border-bottom: none;
            display: inline-block;
            min-width: 176px;
            padding: 7px 26px 0 13px;
            margin-left: -13px;
            margin-top: -7px;  
        }

        &.active{
           @media(max-width: 1240px){
                border-bottom: none;
                background: rgba(221, 235, 238, 0.95);
                border-radius: 10px 10px 0 0;
                padding: 7px 26px 0 13px;
                margin-left: -13px;
                margin-top: -7px;             
                .blogPageCategory__items--wrapper{
                    display: block;
                    background: rgba(221, 235, 238, 0.95);
                    border-radius: 0 0 10px 10px;
                    padding: 0 26px 27px 13px;
                    width: 100%;
                }

            } 
            
        }

        .selectedItem{
            display: none;
            @include font('Open Sans', sans-serif, 700, normal);
            color: #506279;
            font-size: 16px;
            line-height: 40px;
            cursor: pointer;
            padding-right: 28px;
            position: relative;
            padding: 7px 26px 0 13px;
            margin-left: -13px;
            margin-top: -7px;
            img{
                margin-left: 15px;
            }
            @media(max-width: 1240px){
                display: block;
            }
        }

        &--wrapper{
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            list-style: none;

            @media(max-width: 1240px){
                padding: 0;
                display: block;
                align-items: unset;
                justify-content: unset;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
                display: none;
                background: rgba(221, 235, 238, 0.95);
                    border-radius: 0 0 10px 10px;
                    padding: 0 26px 27px 13px;
                    width: 100%;
            }

            .item{
                @include font('Open Sans', sans-serif, 700, normal);
                font-size: 18px;
                line-height: 50px;
                color: #C4C4C4;
                text-decoration: none;
                white-space: nowrap;

                @media(max-width: 1240px){
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 40px;
                    color: #506279;

                }

                &.active,
                &:hover{
                    color: #463B52;
                    @media(max-width: 1240px){
                        color: #506279;                        
                    }
                }
            }
        }
    }
}


.blogCategory{

    z-index: 1001;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-x: auto;
    right: -100vw;
    -ms-overflow-style: none;
    transition: all 300ms;
    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper{
        background: #FFF3E8;
        width: 616px;
        height: 100vh;
        float: right;
        padding: 140px 120px 140px 96px;
        overflow: auto;
        @media (max-width: 768px) {
            padding: 85px 30px 85px 30px;
        }

        @media(max-width: 992px){
            width: 100%;
        }

        .btnClose{
            position: fixed;
            z-index: 10;
            top: 40px;
            right: -100vw;
            transition: all 350ms;
            @media(max-width: 992px){
              top: 35px;
            }
            img{
              width: 40px;
              height: auto;
              @media(max-width: 992px){
                width: 17.5px;
              }
            }
        }

        &--items{

            .item{
                margin-bottom: 26px;

                @media(max-width: 992px){
                    margin-bottom: 24px;
                }

                a{
                    @include font('Open Sans', sans-serif, 400, normal);
                    text-decoration: none;
                    font-size: 28px;
                    line-height: 44px;                    
                    color: #121212;
                    position: relative;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -webkit-hyphens: auto;
                    -moz-hyphens: auto;
                    -ms-hyphens: auto;
                    hyphens: auto;
                    border-bottom: 2px solid rgba($color: #F68D6F, $alpha: .4);
                    transition: all 150ms linear;

                    @media(max-width: 992px){
                        font-size: 22px;
                        line-height: 32px;
                    }

                    &:hover{
                        border-bottom: 2px solid rgba($color: #F68D6F, $alpha: 1);
                    }

                }
            }
        }
    }

    &.active{
        right: 0;
        .btnClose{
            right: 40px;
            @media(max-width: 992px){
                right: 35px;
            }
        }
        .overlay{
            animation: backgroundFade 300ms 300ms both;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    @keyframes backgroundFade {
        from {
            background: rgba($color: #000000, $alpha: 0);
        }

        to {
            background: rgba($color: #000000, $alpha: .5);
        }
    }


}


.blogListPosts{

    @media(max-width: 992px){
        padding: 0 10px;
    }

    &__itemsWrapper{
        display: flex;
        align-items: flex-start;
        margin-bottom: 135px;

        @media(max-width: 992px){
            display: block;
            align-items: inherit;
            margin-bottom: 48px;
        }        

        .item{
            width: 100%;
            max-width: 33.2%;
            @media(max-width: 992px){
                max-width: 100%;
                overflow: hidden;
                margin-bottom: 40px;
            }

            .content{
                margin-right: 20%;
                @media(max-width: 992px){
                    max-width: 100%;
                    width: 100%;
                    margin-right: 0;
                }
            }
            a{
                text-decoration: none;
            }
            .imgWrapper{
                display: flex;
                align-items: flex-end;
                margin-bottom: 24px;
                @media(max-width: 992px){
                    margin-bottom: 31px;
                }
                img{
                    width: 100%;
                    transition: all 250ms;
                }
            }
            .info{
                height: auto;
                @media(max-width: 992px){
                    height: auto;
                }
                .date{
                    margin-bottom: 8px;
                    @include font('Open Sans', sans-serif, 300, normal);
                    font-size: 18px;
                    line-height: 28px;
                    display: flex;
                    align-items: center;
                    color: #ACACAC;
                    @media(max-width: 992px){
                        font-size: 15px;
                        line-height: 22px;
                    }
                }
                .title{
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 24px;
                    line-height: 28px;
                    color: #506279;
                    display: block;                    

                    @media(max-width: 992px){
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
            }
            &.blogSpin{
                .content{
                    position: relative;
                    &:after{
                        @include background-image("./blog-icon.svg", 162, 162);
                        position: absolute;
                        right: -81px;
                        top: 16%;
                        -webkit-animation: spin 12500ms infinite linear;
                        animation: spin 12500ms infinite linear;
                        @media(max-width: 768px ){
                            @include background-image("./blog-icon.svg", 100, 100);
                            right: -50px;
                            display: none;
                        }
                        display: none;
                    }

                    @-webkit-keyframes spin {
                        0% {
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                        }
                        100% {
                            -webkit-transform: rotate(359deg);
                            transform: rotate(359deg);
                        }
                    }
                    @keyframes spin {
                        0% {
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                        }
                        100% {
                            -webkit-transform: rotate(359deg);
                            transform: rotate(359deg);
                        }
                    }


                }
            }
            &:hover{
                img{
                    opacity: .9;
                }
            }
        }

        &.odd{
            .item{
                @media(max-width: 992px){
                    &:nth-child(odd){
                        .content{
                            float: right;
                        }
                    }
                }
            }
        }

        &.even{
            .item{
                @media(max-width: 992px){
                    &:nth-child(even){
                        .content{
                            float: right;
                        }
                    }
                }
            }
        }

        &.spin{
            .item{
                &.blogSpin{
                    .content{
                        &:after{
                            display: inline-block!important;
                            @media(max-width: 768px ){
                                display: inline-block!important;
                            }
                        }
                    }
                }
            }
        }
    }

    &__itemLandscaspeWrapper{
        margin-bottom: 160px;

        @media(max-width: 992px ){
            margin-bottom: 48px;
        }

        a{
            text-decoration: none;
        }

        .item{

            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media(max-width: 992px){
                display: block;
                align-items: inherit;
                justify-content: inherit;
            }

            .imgWrapper{
                width: 100%;
                max-width: 52%;
                @media(max-width: 992px){
                    max-width: 50%;
                    margin-bottom: 50px;
                    margin-left: auto;
                }
                .img{
                    @media(max-width: 992px){
                        padding-top: calc((230 / 174) * 100%);
                        img{
                            height: 100%;
                            width: auto;
                        }
                    }
                }
                img{
                    width: 100%;
                    transition: all 250ms;
                }
            }

            .info{
                width: 100%;
                max-width: 31.5%;

                @media(max-width: 992px){
                    max-width: 100%;
                    margin-top: 0;
                }

                .date{
                    @include font('Open Sans', sans-serif, 300, normal);
                    font-size: 18px;
                    line-height: 28px;
                    color: #ACACAC;
                    margin-bottom: 12px;

                    @media(max-width: 992px){
                        font-size: 15px;
                        line-height: 22px;                        
                    }
                }
                .title{
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 36px;
                    line-height: 41px;
                    color: #506279;
                    margin-bottom: 25px;

                    @media(max-width: 992px){
                        font-size: 24px;
                        line-height: 28px;
                        margin-bottom: 12px;
                    }
                }
                .content{
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 24px;
                    line-height: 28px;
                    color: #000000;

                    @media(max-width: 992px){
                        font-size: 18px;
                        line-height: 22px;
                    }

                }
            }
        }

        &.imgLeft{
            margin-bottom: 122px;

            @media(max-width: 992px ){
                margin-bottom: 55px;
            }

            .item{
                .imgWrapper{
                    margin-right: 9.65%;
                    @media(max-width: 992px ){
                        margin-right: 0;
                    }
                }
            }
        }

        &.imgRight{
            margin-bottom: 260px;

            @media(max-width: 992px ){
                margin-bottom: 55px;
            }
            .item{
                flex-direction: row-reverse;
                @media(max-width: 992px ){
                    flex-direction: inherit;
                }
                .imgWrapper{
                    margin-left: 9.65%;
                    @media(max-width: 992px ){
                        margin-left: auto;
                    }
                }
            }
        }

        &.bg-cloud{
            &.imgRight{
                .imgWrapper{
                    position: relative;
                    z-index: 0;
                    &:before{
                        z-index: -1;
                        content: url("../images/medlearn/blog-cloud.svg");
                        height: auto;
                        left: -23%;
                        top: 53%;
                        position: absolute;
                        @media(max-width: 992px){
                            left: inherit;
                            right: calc(100% - 195px);
                            top: calc(100% - 215px);
                            transform: scale(.5);
                        }
                    }
                }
            }
            .imgWrapper{
                @media(max-width: 992px){
                    margin-bottom: 50px;
                }
            }
        }


        &.bg-flower{
            &.imgLeft{
                .imgWrapper{
                    position: relative;
                    z-index: 0;                    
                    &:before{
                        display: none;
                        z-index: -1;
                        content: url("../images/medlearn/blog-flower.svg");
                        right: calc(100% - 96px);
                        top: calc(100% - 122px);
                        position: absolute;
                        @media(max-width: 992px){
                            display: block;                            
                        }
                    }
                }
            }
            .imgWrapper{
                @media(max-width: 992px){
                    margin-bottom: 83px;
                }
            }
        }




    }


    &__showMore{
        text-align: center;
        margin-bottom: 120px;
        margin-top: -25px;

        @media(max-width: 992px){
            margin-top: -10px;
            margin-bottom: 57px;
        }

        a{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 24px;
            line-height: 42px;            
            color: #506279;
            display: inline-block;
            text-decoration: none;
            border-bottom: 2px solid rgba(246, 141, 111, .4);

            @media(max-width: 992px){
                font-size: 18px;
                line-height: 42px;
            }

            &:hover{
                border-bottom: 2px solid rgb(246, 141, 111);
            }

        }
    }

}
