.tippy-box[data-theme~='MED'] {
    background: #E1DFD8;
    color: #000000;
    padding: 42px 86px 34px 43px;
    width: 100vw;
    max-width: 444px!important;
    @include font('Open Sans', sans-serif, 300, normal);
    font-size: 19px;
    line-height: 22px;    
    border-radius: 0;
    @media (max-width: 992px) {
        padding: 35px 68px 24px 15px;
        font-size: 16px;
        line-height: 18px;
    }
    @media (max-width: 575px) {
       width: 100%;
    }
    .tippy-content{
        padding: 0;
        a{
            color: #506279;
            font-weight: 400;
        }
    }
    .tippy-arrow {
        width: 26px;
        height: 23px;
        @media (max-width: 992px) {
            font-size: 13px;
            line-height: 20px;
        }
    }
    &[data-placement^='top'] > .tippy-arrow::before {
        color: #E1DFD8;
        bottom: -17px;
        left: -10px;
        border-width: 23px 23px 0;
        @media (max-width: 992px) {
            bottom: -16px;
        }
        @media (max-width: 575px) {
            //transform: translateX(-12px);
        }
    }

    &[data-placement^='bottom'] > .tippy-arrow::before {
        color: #E1DFD8;
        top: -17px;
        left: -10px;
        border-width: 0 23px 23px;
        @media (max-width: 992px) {
            top: -16px;
        }
        @media (max-width: 575px) {
            //transform: translateX(-12px);
        }
    }
    .closeTooltip{
        width: 16px;
        height: 16px;
        position: absolute!important;
        top: -27px;
        right: -65px;
        display: block;
        @include background-image("./icons/medlearn/close-tooltip.svg", 25.41, 25.71);
        cursor: pointer;
        @media (max-width: 992px) {
            top: -20px;
            right: -57px;
        }
    }
    &[data-placement="bottom"]{
        @media (max-width: 992px) {
            margin-top: -10px;
        }
    }
    &[data-placement="top"]{
        @media (max-width: 992px) {
            margin-bottom: -10px;
        }
    }
}

.helpTooltip{
    cursor: pointer;
}
