.employers{
  margin-bottom: 92px;

    @media(max-width: 992px){
      margin-bottom: 60px;
    }

  &__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;

    @media(max-width: 992px){
      display: block;
    }

    .image{
      width: 100%;
      max-width: 472px;
      z-index: 1;
      position: relative;

      &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 70%;
        background: -moz-linear-gradient(left,  rgba(255,255,255,0) 20%, rgba(255,255,255,0.01) 1%, rgba(255,255,255,1) 74%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 20%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,1) 74%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(255,255,255,0) 20%,rgba(255,255,255,0.01) 1%,rgba(255,255,255,1) 74%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
      }

      @media(max-width: 1200px){
        max-width: 350px;
      }
      @media(max-width: 992px){
        display: none;
      }
    }
    .content{
      width: 100%;
      max-width: calc(100% - 472px);
      z-index: 2;
      transform: translateX(-90px);
      
      @media(max-width: 1200px){
        max-width: calc(100% - 350px);
        transform: translateX(-50px);
      }
      
      @media(max-width: 992px){
        max-width: 348px;
        margin: 0 auto;
        transform: translateX(0);
      }

      .innerImage{
        display: none;
        margin-bottom: 10px;
        @media(max-width: 992px){
          display: block;
        }
      }

    }

  }

  &__title{
    @include font('Open Sans', sans-serif, 400, normal);
    font-size: rem(48px);
    line-height: rem(55px);
    color: #506279;
    margin-bottom: 30px;
    @media(max-width: 992px){
      margin-bottom: 10px;
      font-size: rem(36px);
      line-height: rem(41px);
       text-align: center;
    }
  }
  &__list{

    a{
      @include font('Open Sans', sans-serif, 400, normal);
      font-size: rem(24px);
      line-height: rem(30px);
      color: #3972B4;
      margin-bottom: 12px;
      display: inline-block;
      // text-decoration-thickness: 2px;
      @media(max-width: 992px){
        font-size: rem(18px);
        // text-decoration-thickness: 1px;
      }
    }

    p{
      margin-bottom: 20px;
    }

    h1,h2,h3,h4,h5,h5{
      @include font('Open Sans', sans-serif, 300, normal);
      font-size: rem(28px);
      line-height: rem(32px);
      color: #000000;
      margin-bottom: 7px;
      @media(max-width: 992px){
        font-size: rem(24px);
        line-height: rem(28px);       
      }
    }
    ol{
      list-style: none;
      counter-reset: li;
      padding-left: 25px;
      @media(max-width: 992px){
        padding-left: 15px;
      }
      li{
        font-size: rem(24px);
        line-height: rem(30px);
        counter-increment: li;
        @media(max-width: 992px){
          font-size: rem(18px);
        }

        &::before {content:
          counter(li);
          color: #3972B4;
          display: inline-block;
          width: 1em;
          margin-left: -1em
        }

      }
    }
    ul{
      list-style: none;
      li{
        padding-left: 25px;
        position: relative;
        font-size: rem(24px);
        line-height: rem(30px);
        @media(max-width: 992px){
          padding-left: 15px;
          font-size: rem(18px);
        }
        &:after{
          content: "";
          width: 9px;
          height: 9px;
          background: #3972B4;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 12px;
          @media(max-width: 992px){
            width: 6px;
            height: 6px;
          }
        }                          
      }
    }
  }
}