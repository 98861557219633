.block-cards{
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media(max-width: 992px){
        margin-bottom: 100px;
    }
    h4 {
        color: $brandPurple;
        margin-bottom: 60px;
        width: 100%;
        @media(max-width: 992px){
            margin-bottom: 32px;
        }
    }
    .item{
        color: $textDark1;
        width: calc(100%/3 - 54px);
        margin-bottom: 120px;
        margin-right: 80px;
        @media(max-width: 992px){
            width: calc(100%/2 - 11px);
            margin-bottom: 40px;
            margin-right: 21px;
        }
        &:nth-of-type(3n){
            margin-right: 0;
            @media(max-width: 992px){
                margin-right: 21px;
            }
        }
        &:nth-of-type(2n){
            @media(max-width: 992px){
                margin-right: 0;
            }
        }
        h3 {
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 21px;
            line-height: 36px;
            letter-spacing: -0.12px;
            color: #121212;
            margin-top: 32px;
            @media(max-width: 991px) {
                font-size: 16px;
                line-height: 21px;
                margin-top: 16px;
              }
        }
        a{
            color: $textDark1;
            text-decoration: none;
        }
        .imgWrapper{
            display: flex;
            align-items: flex-end;
            margin-bottom: 24px;
            @media(max-width: 992px){
                margin-bottom: 20px;
            }
            img{
                width: 100%;
                transition: all 250ms;
            }
        }
        &:hover{
            img{
                opacity: .9;
            }
        }
    }
    &--section {
        margin-bottom: 0px;
        .item {
            margin-bottom: 80px;
            @media(max-width: 991px) {
                margin-bottom: 40px;
            }

            h3 {
                font-size: $p-m;
                @media(max-width: 991px) {
                    font-size: $p;
                  }
            }
        }
    }
    &.related-content{
        padding-top: 60px;
        margin-bottom: 0;
    }
}
