footer{
  .footerTop{
    background: #323E4F;
    padding: 40px 0 60px 0;
    position: relative;

    @media (max-width: 992px){
      padding: 40px 0;
    }

    .container{
      position: relative;
    }
    &__logo{
      position: absolute;
      right: 48px;
      bottom: 0;
      width: 100%;
      max-width: 193px;

      @media (max-width: 992px){
        display: block;
        position: relative;
        right: unset;
        bottom: unset;
        margin-bottom: 30px;
      }

    }

    &__col{
      @media (max-width: 992px){
        margin-top: 40px;
      }
      h2{
        @include font('Open Sans', sans-serif, 400, normal);
        // font-size: 25px;
        // line-height: 44px;
        font-size: 22px;
        line-height: 38px;
        color: #FFFFFF;
        opacity: 0.5;
        margin-top: 40px;
        @media (max-width: 992px){
          font-size: 16px;
          line-height: 24px;
          margin-top: 25px;
          margin-bottom: 4px;
        }
        &:first-child{
          margin-top: 0;
        }
        &+div a{
          margin-top: 0;
        }

      }

      div{
        display: block;
      }

      p{
        a{
          &:first-child{
            margin-top: 0;
          }
        }

      }



        // &.alternate-links{
        //   p{
        //     margin-top: 10px;
        //   }
        // }


      &.alternateLinks {
        // p:first-of-type {
        //   a {
        //     margin-top: 0;
        //   }
        // }
        h2{
          &+p{
          margin-top: -10px;
        }

        }
        p {
          a {
            margin-top: 10px;
          }
        }
      }


      .terms{
        margin-top: 34px;
        margin-bottom: 40px;
        @media (max-width: 992px){
          margin-top: 25px;
           margin-bottom: 0;
        }
        input[type=checkbox]+label:before,
        input[type=checkbox]+label:after {
          width: 18px;
          height: 18px;
          border-radius: 0;
        }
        input[type=checkbox]+label:before{
          opacity: 1!important;
          background-color: transparent;
          border: 1px solid #DDEBEE;
        }
        input[type=checkbox]+label:after {
          transform: scale(.6);
          background-image: url(../images/icons/medlearn/check-light.svg);
        }
        label, label p{
          @include font('Open Sans', sans-serif, 400, normal);
          font-size: 18px;
          line-height: 22px;
          color: rgba(255, 255, 255, 0.5);
          padding-left: 0;
          text-indent: 30px;
          @media (max-width: 992px){
            font-size: 16px;
            line-height: 22px;
            margin-top: 0px;
            margin-bottom: 4px;
          }

          a {
            margin: 0;
            display: inline;
            padding: 0;
            margin-top: 0;
            font-size: 18px;
            color: inherit;
            line-height: inherit;
            letter-spacing: inherit;
            font-family: inherit;

            @media (max-width: 992px){
                font-size: 16px;
            }

            span {
                display: none;
            }
          }
        }

        &.invalid {
          label, label p {
            color: $red;
          }
        }
      }

      a{
        border-bottom: 2px solid #F7BBA9;
        margin-top: 10px;
        display: inline-block;
      }

      p,
      p a,
      div a,
      .link{
        @include font('Open Sans', sans-serif, 400, normal);
        // font-size: 25px;
        // line-height: 44px;
        font-size: 21px;
        line-height: 36px;
        color: #FFFFFF;
        text-decoration: none;
        @media (max-width: 992px){
          font-size: 16px;
          line-height: 24px;
        }
      }

      div a,
      .link{
        @include font('Open Sans', sans-serif, 400, normal);
        border-bottom: 2px solid #F7BBA9;
        margin-top: 10px;
        display: inline-block;
        padding-right: 27px;
        @media (max-width: 992px){
          margin-top: 10px;
          padding-right: 21px;
        }
        span{
          position: relative;
          &:after{
            @include background-image("./icons/arrow-link.svg", 12, 12);
            position: absolute;
            right: -23px;
            top: 50%;
            margin-top: -5px;
            @media (max-width: 992px){
              @include background-image("./icons/arrow-link.svg", 9, 9);
              right: -17px;
              margin-top: -3px;
            }
          }
        }
      }

      &.colLink{
        @media (max-width: 992px){
          margin-top: 30px;
        }

        .link{
          margin-top: 0;
        }

        .email{
          position: relative;

          @media (max-width: 992px){
           max-width: 360px;
          }

          input{
            display: block;
            width: 100%;
            @include font('Open Sans', sans-serif, 400, normal);
            // font-size: 25px;
            // line-height: 44px;
            font-size: 21px;
            line-height: 36px;
            color: #FFFFFF;
            background: none;
            border: none;
            border-bottom: 2px solid rgba($color: #ffffff, $alpha: .5);
            padding-right: 35px;
            padding-bottom: 8px;

            @media (max-width: 992px){
              font-size: 16px;
              line-height: 24px;
              padding-bottom: 6px;
            }

            &:focus{
              outline: none;
            }

            &::-webkit-input-placeholder {
              color: rgba(255,255,255,.5);
            }

            &:-ms-input-placeholder {
              color: rgba(255,255,255,.5);
            }

            &::placeholder {
              color: rgba(255,255,255,.5);
            }
          }

          .button {
            background-color: #323E4F;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 44px;
            width: 35px;
            position: absolute;
            top: 0;
            right: 0;

            @media (max-width: 992px){
              height: 30px;
            }

            button{
              display: block;
              background: none;
              border: none;
              @include background-image("./icons/medlearn/arrow-email.svg", 35, 23);

              @media (max-width: 992px){
                @include background-image("./icons/medlearn/arrow-email.svg", 35, 23);
                margin-bottom: 5px;
              }

              &:focus{
                outline: none;
              }
            }
          }

          .error-message{
            display: none;
            margin-top: 10px;
            color: $red;
          }

          &.error{
            input{
              color: $red;
              border-color: $red;
            }

            .error-message{
              display: block;
            }
          }
        }
      }
    }
  }

  .footerBottom{
    background: #121324;
    padding:  17px 0;

    @media (max-width: 992px){
      padding:  24px 0;
    }

    &__wrapper{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 992px){
        flex-direction: column;
      }

      &--copyright,
      &--links{
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 14px;
        line-height: 100%;
        font-weight: 400;
        color: #FFFFFF;

        @media (max-width: 1200px){
          font-size: 12px;
        }

        @media (max-width: 992px){
          font-size: 14px;
          line-height: 25px;
        }

        a{
          @include font('Open Sans', sans-serif, 400, normal);
          font-size: 14px;
          line-height: 100%;
          font-weight: 400;
          color: #FFFFFF;
          @media (max-width: 1200px){
            font-size: 12px;
          }
          @media (max-width: 992px){
            font-size: 14px;
            line-height: 25px;
          }
        }

        span{
          display: inline-block;
          margin: 0 10px;
        }

      }

      &--copyright{
        @media (max-width: 992px){
          width: 100%;
          margin-bottom: 20px;
          text-align: center;

        }
      }

      &--links{
        @media (max-width: 992px){
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          span{
            display: none;
          }
        }
      }
    }

  }

  .showMobile{
    display: none;
    @media (max-width: 992px){
      display: block;
    }
  }

  .hideMobile{
    display: block;
    @media (max-width: 992px){
      display: none;
    }
  }
  .hide{
    display: none;
  }

}


.mb-90{
      margin-bottom: 90px;
}
