.embeddedServiceHelpButton{
    z-index: 2 !important;

    .helpButton{
        bottom: 30px !important;
        left: 80px;
        right: inherit !important;
        height: auto !important;

        @media (min-width: 1780px) {
            left: calc((100vw - 1640px)/2);
        }

        @media (max-width: 1780px) {
            left: 80px;
        }

        @media(max-width: 1200px) {
            left: 30px;
        }

        @media(max-width: 992px) {
            left: 20px;
        }

        button.uiButton{
            display: block;
            width: auto;
            height: auto;
            padding: 16px 32px 16px 32px;
            border-radius: 30px;
            white-space: nowrap;
            transform: none;
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: normal;
            line-height: normal;
            color: white;
            background-color: #483A54;
            max-width: 100% !important;
            box-shadow: none !important;

            @media(max-width: 992px) {
                height: 53px;
                min-width: auto !important;
                background-image: url(./../images/icons/chat-face.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 25px;
            }

            &::after{
                content: "";
                display: block;
                position: absolute;
                background-image: url(./../images/icons/chat-i.svg);
                background-repeat: no-repeat;
                width: 34px;
                height: 20px;
                background-size: contain;
                top: 99%;
                left: 12px;

                @media(max-width: 992px) {
                    top: 92%;
                    left: 0px;
                }
            }

            &::before{
                display: none !important;
            }

            &:focus{
                .helpButtonLabel{
                    text-decoration: none !important;
                }
            }

            .helpButtonLabel{
                @media(max-width: 992px) {
                    display: none !important;
                }
            }

            .embeddedServiceIcon{
                display: none !important;
            }
        }
    }
}

.dockableContainer{
    left: 15px !important;

    @media(max-width: 992px) {
        left: 0 !important;
        width: 90% !important;
    }

    .formContent{
        background-color: #EBEBEB !important;
    }

    button{
        &:focus{
            text-decoration: none !important;
        }
    }

    .embeddedServiceSidebarForm.buttonWrapper{
        background: #EBEBEB !important;

        .startButton{
            border-radius: 80px !important;

            &:hover{
                background: #ea8b6e !important;
            }
        }
    }

    .endChatContainer{
        .endChatButton.closeChatButton{
            border-radius: 80px !important;

            &:hover{
                background: #ea8b6e !important;
            }
        }
    }
}

.sidebarMinimized button{
    left: 15px !important;
}

.embeddedServiceSidebar{
    z-index: 2 !important;

    &.sidebarMaximized{
        @media(max-width: 992px) {
            z-index: 10 !important;
        }
    }
}
