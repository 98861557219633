.imageBlocks {
    margin-bottom: 112px;
    @media(max-width: 992px){
        margin-bottom: 16px;
    }

    &__header{

        h2{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 60px;
            line-height: 72px;
            color: #506279;
            word-break: break-word;
            margin-bottom: 70px;
            display: none;

            @media (max-width:1680px) {
                font-size: 3.5749vw;
                line-height: 4.289vw;
            }
            @media(max-width: 992px){
                font-size: 36px;
                line-height: 41px;
                margin-bottom: 12px;
                display: block;
                text-align: center;
                margin-bottom: 32px;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media(max-width: 992px){

        }
        .item {
            position: relative;
            width: 25%;

            @media(max-width: 992px){
                width: 50%;
                margin-bottom: 40px;
            }

            .imgWrapper{
                padding: 0 10px;
                img{
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 132px;
                    display: block;
                    @media(max-width: 992px){
                        max-height: 110px;
                    }
                }
            }

            .info{
                padding-top: 37px;
                padding-left: 10px;
                padding-right: 10px;
                width: 100%;
                transition: all 300ms;
                text-align: center;
                @media(max-width: 992px){
                    padding-top: 20px;
                    position: relative;
                    bottom: inherit;
                }
                h3{
                    @include font('Open Sans', sans-serif, 700, normal);
                    margin-bottom: 27px;
                    font-size: 28px;
                    line-height: 32px;
                    color: #000000;
                    @media(max-width: 992px){
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
                p{
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 20px;
                    line-height: 23px;
                    color: #000000;
                    overflow: hidden;
                    transition: all 300ms;
                    @media(max-width: 992px){
                        font-size: 16px;
                        line-height: 18px;
                        height: auto;
                        padding: 0 10px;
                    }
                    br{
                        display: none;
                    }
                }
            }

        }
    }
}
