.listLinks{
    position: relative;
    z-index: 0;
    padding: 100px 0 200px 0;
    @media(max-width: 992px){
        padding: 60px 0 120px 0;
    }

    &:before{
        opacity: 0;
        transition: all 1500ms;
        z-index: -1;
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: -moz-linear-gradient(top, rgba(255,243,232,0) 0%, rgba(255,243,232,1) 19%, rgba(255,243,232,1) 20%, rgba(255,243,232,1) 50%, rgba(255,243,232,1) 80%, rgba(255,243,232,1) 81%, rgba(255,243,232,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,243,232,0) 0%,rgba(255,243,232,1) 19%,rgba(255,243,232,1) 20%,rgba(255,243,232,1) 50%,rgba(255,243,232,1) 80%,rgba(255,243,232,1) 81%,rgba(255,243,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,243,232,0) 0%,rgba(255,243,232,1) 19%,rgba(255,243,232,1) 20%,rgba(255,243,232,1) 50%,rgba(255,243,232,1) 80%,rgba(255,243,232,1) 81%,rgba(255,243,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00FFF3E8', endColorstr='#00fff3e8',GradientType=0 ); /* IE6-9 */
    }

    &.background{
        &:before{
            opacity: 1;
        }
    }
    &__wrapper{
        &--title{
            text-align: center;
            margin-bottom: 80px;
            @media (max-width: 1200px){
                margin-bottom: 60px;
            }
            @media (max-width: 992px){
                margin-bottom: 48px;
            }
            h3{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 25px;
                line-height: 40px;
                letter-spacing: -0.24px;
                color: #E97C5D;
                @media (max-width: 992px){
                    font-size: 18px;
                    line-height: 32px;
                }
            }
        }

        &--wordCloud{
            text-align: center;
            margin-bottom: 32px;
            margin-right: -120px;
            @media (max-width: 992px){
                margin-right: 0;
                margin-bottom: 24px;
                overflow: hidden;
            }
            .item{
                display: inline-block;
                margin-bottom: 48px;
                position: relative;
                z-index: 0;
                padding-right: 60px;
                margin-right: 60px;
                @media (max-width: 1200px){
                    margin-bottom: 32px;
                    padding-right: 40px;
                    margin-right: 40px;
                }
                @media (max-width: 992px){
                    margin-bottom: 24px;
                    padding-right: 0px;
                    margin-right: 0px;
                    display: block;
                }

                &.bullet{
                    &:before{
                        @include background-image("./icons/point.svg", 8, 8);
                        position: absolute;
                        right: 0;
                        top: 50%;
                        margin-top: -4px;
                        z-index: -1;
                        @media (max-width: 992px){
                            display: none;
                        }
                    }
                }


                &:last-child{
                    &:before{
                        display: none;
                    }
                }

                a,span{
                    @include font('Open Sans', sans-serif, 400, normal);
                    position: relative;
                    font-size: 72px;
                    line-height: 88px;
                    letter-spacing: -0.44px;
                    color: #483A54;
                    text-decoration: none;
                    @media (max-width:1680px) {
                        font-size: 4.289vw;
                        line-height: 5.2433vw;
                    }
                    @media (max-width: 992px){
                        font-size: 24px;
                        line-height: 32px;
                    }
                    &:hover{
                        border-bottom: 4px solid rgba(72, 58, 84, .7);
                        @media (max-width: 992px){
                            border-bottom: none;
                        }
                    }
                }
                span{
                    cursor: default;
                    z-index: 0;
                    .imgWrapper{
                        display: none;
                    }
                    // .imgWrapper{
                    //     width: 400px;
                    //     height: auto;
                    //     max-width: 400px;
                    //     position: absolute;
                    //     top: 50%;
                    //     left: 53%;
                    //     transform: translate(-10%, -50%);
                    //     z-index: -1;
                    //     transition: all 300ms;
                    //     opacity: 0;
                    //     pointer-events: none;
                    // }

                    // &:hover{
                    //     .imgWrapper{
                    //         opacity: 1;
                    //         transform: translate(0, -50%);
                    //     }
                    //     @media (max-width: 992px){
                    //         .imgWrapper{
                    //             display: none;
                    //         }
                    //     }
                    // }
                }
                &:hover{
                    z-index: 3;
                }

            }

            // .bullet{
            //     width: 8px;
            //     height: 8px;
            //     margin: 0 60px;
            // }

        }

        &--button{
            text-align: center;
            .button{
                color: #ffffff;
                text-decoration: none;
                padding: 14px 32px;
                border-radius: 38px;
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 18px;
                line-height: 28px;
                display: inline-block;
                background: $brandRed;
                @media (max-width: 992px){
                    padding: 10px 24px;
                }
            }
        }

    }
}
