.textBlocks{
    margin-bottom: 156px;

    @media(max-width: 992px) {
        margin-bottom: 0px;
    }

    &__htmlArea{

        h1, h2, h3, h4, h5, h6{
            @include font('Open Sans', sans-serif, 600, normal);
            font-size: 20px;
            line-height: 38px;
            color: #000000;
            margin-bottom: 12px;
            @media(max-width: 992px) {
                font-size: 17px;
                line-height: 28px!important;
            }
        }

        p{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 20px;
            line-height: 38px;
            color: #000000;
            margin-bottom: 12px;
            @media(max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
                margin-bottom: 40px!important;
            }
        }

        overflow: hidden;
        @media(max-width: 992px) {
            font-size: 1.025rem;
        }

        a{
            text-decoration: none;
            border-bottom: 2px solid rgba($color: #F68D6F , $alpha: .6);
            &:hover{
                border-bottom: 2px solid rgba($color: #F68D6F , $alpha: 1);
            }
        }

        p, ul, ol{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 20px;
            line-height:38px;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }

        ul, ol{
            color: #222222;
            a{
                text-decoration: underline;
                border-bottom: none;
                &:hover{
                    border-bottom: none;
                }
            }
        }

        p, ul, ol, h3, h4, h5, h6{
            margin-bottom: 24px;
            width: 100%;
            @media(max-width: 992px) {
                margin-bottom: 28px;
                margin-left: 0;
                width: 100%;
            }
        }

        h1, h3, h4, h5, h6{
            padding-top: 20px;
            @media (max-width: 992px) {
                padding-top: 0px;
            }
        }

        h2{
            padding-top: 36px;
            clear: both;
            @media(max-width: 992px) {
                margin-bottom: 12px;
                width: 100%;
                float: none;
                padding-top: 12px;
            }

            + p, + h3, + h4, + h5, + h6, + ul, + ol, + .imageWrapper{
                padding-top: 36px;
                @media(max-width: 992px){
                    padding-top: 0;
                }
            }

            &:first-child{
                padding-top: 0;
                + p, + h3, + h4, + h5, + h6, + ul, + ol, + .imageWrapper{
                    padding-top: 0;
                }
            }

        }

        .imageWrapper{
            width: 100%;
            padding-top: 56px;
            margin-bottom: 80px;
            @media (max-width: 992px) {
                margin-left: 0;
                width: 100%;
                padding-top: 10px;
                margin-bottom: 40px;
            }
            &+ h2 {
                margin-top: 0;
            }
            figure{
                    width: 100%;
                    height: auto;
                img{
                    width: 100%;
                    height: auto;
                    margin-bottom: 16px;
                }
                figcaption{
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: 0.24px;
                    color: #121212;
                    text-align: center;
                    display: block;
                    @media(max-width: 992px){
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }

            &+ .imageWrapper {
                padding-top: 0;
                @media (max-width: 992px) {
                    padding-top: 0;
                }
            }
        }

        p {
            &+ ul, &+ ol {
                margin-top: -4px;
            }
        }

        ul, ol {
            padding-left: 20px;
            li {
                padding-left: 4px;
                margin-bottom: 8px;
              &:marker {
                width: 5px;
              }
            }
        }

        .line-grey {
            margin: 80px 0;
            @media(max-width: 992px){
                margin: 60px 0;
            }
            &+ h1,
            &+ h2,
            &+ h3,
            &+ h4,
            &+ h5,
            &+ h6,
            &+ p,
            &+ ul,
            &+ ul{
                padding-top: 0;
            }
            &+ h1 + *,
            &+ h2 + *,
            &+ h3 + *,
            &+ h4 + *,
            &+ h5 + *,
            &+ h6 + *,
            &+ p + *,
            &+ ul + *,
            &+ ul + *{
                padding-top: 0;
            }
        }


    }

}
