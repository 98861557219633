label {
    color: #000000;
    font-size: 15px;
    line-height: 52px;
    @include font('Open Sans', sans-serif, 400, normal);

    @media(max-width: 992px) {
        font-size: 15px;
        line-height: 52px;
    }

    + .btn-help {
        margin-left:6px;
    }
}

textarea.form-control{
  line-height: 1.3;
}

.form-control,
.bootstrap-select.form-control {
    color: #000000;
    @include font('Open Sans', sans-serif, 300, normal);
    font-size: 19px;
    line-height: 52px;    
    border: 1px solid #C4C4C4;
    min-height: 75px;
    padding: 16px 20px;
    border-radius: 12px;

    &::-webkit-input-placeholder {
        color: #8D8E9B;
    }

    &::-ms-input-placeholder {
        color: #8D8E9B;
    }

    &::placeholder{
        color: #8D8E9B;
    }

    @media(max-width: 992px) {
        font-size: 19px;
        min-height: 75px;
    }
    &:active, &:focus, &:focus-within {
        border-color: #C4C4C4;
        border-bottom-color: $darkGrey;
        box-shadow: none;
    }
    &.valid {
        color: $darkGreen;
        border-bottom-color: $darkGreen!important;
    }
    &.invalid {
        border-bottom-color: $red;
        &::-webkit-input-placeholder {
            color: $red;
            opacity: 1;
        }
    }
    &.invalidField {
        color: red;
        border-bottom-color: $red;
    }
    &.invalidFieldSelect {
        color: red;
        border-bottom-color: $red;
        &+ .btn.dropdown-toggle{
            border-bottom: 1px solid red!important;
            border-radius: 8px;
            .filter-option-inner-inner{
                color: red;
            }
        }
    }
}

.bootstrap-select{
    &.form-control{
        padding: 0;

        &:focus-within {
            border-bottom-color: rgba($color: #B1B1B1, $alpha: .5);
        }

        .btn-light{
            color: initial;
            background-color: initial;
            border: none;
            padding: 16px 20px;
            font-size: 19px;
            line-height: 41px;
            font-weight: 300;
            box-shadow: none!important;
            @media (max-width: 992px){
               
            }
            &:focus{
                outline: none!important;
                border: none;
            }
            &:active{
                color: initial;
                background-color: initial;
            }

        }
    }

    .dropdown-toggle::after{
        border: none;
        @include background-image("icons/arrow-down-select.svg",18.41, 10.12);
        transition: all 400ms;
    }

    &.show .dropdown-toggle::after {
        transform: rotate(180deg);
    }

    .dropdown-menu{
        padding: 0;

        .dropdown-item{
            color: #000000;
            font-weight: 300;
        }

        .dropdown-item:focus,
        .dropdown-item:hover {
            color: initial;
            text-decoration: none;
            background-color: #f8f9fa;
            outline: none;
        }

        .dropdown-item.active,
        .dropdown-item:active {
            background-color: #A2C6CF;
            color: #000000;
            &:hover{
                cocolor: #000000!important;
            }
        }

    }

}




textarea.form-control{
    @media(max-width: 992px) {
        line-height: 24px;
    }
}
select {
    background-image: url(../images/icons/arrow-down-select.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &:-ms-expand {
        display: none; /* remove default arrow on ie10 and ie11 */
    }
}
input[type= textarea] {
    padding: 20px;
}
input[type= checkbox] {
    opacity: 0;
    position: absolute;
    &:checked {
        &+ label:after {
            opacity: 1;
        }
    }
    &:focus-within {
        + label:before {
            opacity: 1;
        }
    }
    + label {
        font-size: $p-m;
        position: relative;
        padding-left: 60px;
        cursor: pointer;
        &:before {
            content: '';
            width: 34px;
            height: 34px;
            background-color: #fff;
            border: 1px solid $brandPurple;
            border-radius: 8px;
            top: 0;
            left: 0;
            position: absolute;
            display: block;
            opacity: .5;
        }
        &:after {
            content: '';
            width: 34px;
            height: 34px;
            background-image: url(../images/icons/check.svg);
            top: 0;
            left: 0;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
        }
        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }
}

.radio-card{
    cursor: pointer;
    h5 {
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 21px;
        line-height: 36px;
        color: #483A54;
        margin-bottom: 15px;
       @media(max-width: 992px) {
            font-size: 20px;
            line-height: 33px;
            margin-bottom: 5px;
        }
    }
    img {
        margin-top: 20px;
        margin-right: 20px;
    }
    p {
        @include font('Open Sans', sans-serif, 300, normal);
        font-size: 18px;
        line-height: 28px;        
        color: #121212;
        @media(max-width: 992px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    input[type= radio] {
        opacity: 0;
        position: absolute;
        &:focus-within{
            + .label {
                border-color: rgba($color: $brandPurple, $alpha: 1);
            }
        }
        &.active {
            + .label {
                border-color: rgba($color: $brandPurple, $alpha: 1);
                &:after {
                    background-color: #F68D6F;
                    border-color: #F68D6F;
                }

                .disclaimer,
                .alternativePayment {
                    height: auto;
                    padding: 24px 45px 24px 104px;
                    margin-top: -10px;
                   @media(max-width: 992px) {
                        margin-top: 0px;
                        padding: 15px 15px 15px 55px;
                    }
                }

            }
        }
    }









    .label {
        border: 1px solid rgba($color: $brandPurple, $alpha: 0.2);
        margin-bottom: 20px;
        width: 100%;
        position: relative;
        cursor: pointer;
        border-radius: 12px;
        div {
            padding: 44px 48px 48px 104px;
           @media(max-width: 992px) {
                padding: 15px 15px 15px 55px;
            }
        }
        &:after {
            content: '';
            border: 1px solid $textDark1;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            position: absolute;
            left: 44px;
            top: 50px;
            display: block;
           @media(max-width: 992px) {
                left: 15px;
                top: 20px;
            }
        }
        &:hover {
            border-color: rgba($color: $brandPurple, $alpha: 1);
        }
        .disclaimer {
            background-color: rgba($color: $yellow, $alpha: 0.5);
            width: 100%;
            height: 0;
            overflow: hidden;
            padding: 0 45px 0 105px;
            transition: .5s;
            font-size: 18px;
            line-height: 28px;            
            color: #121212;
            @include font('Open Sans', sans-serif, 300, normal);
           @media(max-width: 992px) {
                padding: 0 15px 0 55px;
                font-size: 15px;
                line-height: 24px;
            }
        }
        .alternativePayment{
            cursor: default;
            width: 100%;
            height: 0;
            overflow: hidden;
            padding: 0 45px 0 105px;
            transition: .5s;
            font-size: 18px;
            line-height: 28px;            
            color: #121212;
            @include font('Open Sans', sans-serif, 300, normal);
            margin-bottom: 24px;
            @media (max-width: 992px) {

            }
            div{
                padding: 0;
            }
            &__content{
                font-size: 16px;
                line-height: 20px;
                @media (max-width: 992px) {
                    font-size: 14px;
                    line-height: 18px;
                }
                @media (max-width: 420px) {
                    margin-bottom: 10px;
                }
                &--note{
                    @include font('Open Sans', sans-serif, 400, normal);
                    margin-bottom: 20px;
                    display: block;
                }
                &--items{
                    input{
                        &:focus{
                         outline: none;
                         border-bottom: 1px solid rgba(72,58,84,1);
                        }
                    }
                    .item{
                        border: 1px solid rgba(72,58,84,0.2);
                        margin-bottom: 20px;
                        padding: 15px 20px;
                        width: 100%;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;

                        &:last-child{
                            margin-bottom: 0;
                        }
                        &.dropDownCard{
                            z-index: 1;
                            padding: 0;
                            cursor: pointer;
                            position: relative;
                            border-radius: 12px;
                            .arrowSelect{
                                z-index: 2;
                                @include background-image("icons/down.svg",12, 6);
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                margin-top: -3px;
                            }
                            .selectedItem{
                                padding: 24px 60px 24px 20px;
                                width: 100%;
                                display: block;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                @media (max-width: 992px) {
                                    padding: 15px 60px 15px 20px;
                                }
                            }
                            .options{
                                z-index: 1;
                                display: none;
                                position: absolute;
                                background: #ffffff;
                                border: 1px solid #ffffff;
                                border-radius: 12px;
                                width: calc( 100% + 2px);
                                left: -1px;
                                top: -1px;
                                padding: 15px 60px 15px 20px;
                                border: 1px solid rgba(72,58,84,0.2);
                                max-height: 212px;
                                overflow-y: auto;
                                .option{
                                    margin-bottom: 10px;
                                    @media (max-width: 992px) {
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                    }
                                    &.active{
                                        font-weight: 600;
                                    }
                                }
                            }
                            &.active{
                                .options{
                                    display: block;
                                }
                                .arrowSelect{
                                    transform: rotate(180deg);
                                }

                                &.instalments{
                                    height: 110px;

                                }
                            }
                        }
                    }
                    .infoNAV{
                        display: none;
                        max-width: 600px;
                        margin-bottom: 20px;
                        p{
                            font-size: 15px;
                            line-height: 24px;
                        }
                    }
                }
            }
            @media(max-width: 992px) {
                padding: 0 15px 0 55px;
            }

            &.option3{
                .alternativePayment__content{
                    .alternativePayment__content--items{
                        .item{
                            &.active{
                                height: inherit;
                            }

                            .arrowSelect{
                                top: 36px;
                                @media(max-width: 992px) {
                                    top: 26px;

                                }
                            }

                            .options{
                                position: relative;
                                margin-top: -59px;
                                border: none;
                                background-color: transparent;

                                @media(max-width: 992px) {
                                    margin-top: -46px;
                                }

                                .option{
                                    &:first-child{
                                        background-color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
.form-group {
    margin-bottom: 37px;
   @media(max-width: 992px) {
        margin-bottom: 22px;
    }
    .textArea{
        @media(max-width: 992px) {
            font-size: 19px;
            line-height: 30px;
        }
    }
    &.wrap {
        position: relative;
        display: inline-block;
    }

    &.wrap {
        span {
            position: absolute;
            bottom: 1px;
            right: 37px;
            font-size: 14px;
            color: gray;
            background: white;
            padding: 4px;
            @media(max-width: 992px) {
                font-size: 9px;
                right: 20px;
            }
        }
    }

    &.loading{
        .form-control{
            width: calc(100% - 40px);
        }

        .spinner{
            height: 37px;
            width: 37px;
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            margin: -18px 0 0 -18px;
            fill: none;
            stroke: #c7c7c7;
            // stroke-linecap: square;
            stroke-width: 1.2;
            /* opacity: var(--svg-o, 0); */
            transform:  rotate(-90deg) ;
            circle {
                &:first-child {
                    stroke-opacity: 0.3;
                }
                &:last-child {
                    stroke: $brandPurple;
                    stroke-dasharray: 38px;
                    stroke-dashoffset: 114px;
                    -webkit-animation: load 1.4s cubic-bezier(0.42, -0.01, 1, 1) infinite 0.3s;
                    animation: load 1.4s cubic-bezier(0.42, -0.01, 1, 1) infinite .3s;
                }
            }
        }
    }
}

.form-required {
    label:after {
        content: '*';
        color: $pink;
        display: inline;
    }
}
.form-readonly {
    @include font('Open Sans', sans-serif, 300, normal);
    font-size: 19px;
    line-height: 36px;    
    color: #000000;
    @media(max-width: 992px) {
        font-size: 19px;
        line-height: 52px;
    }
    label {
        @include font('Open Sans', sans-serif, 300, normal);
        font-size: 15px;
        line-height: 52px;       
        color: #C4C4C4;
        margin-bottom: 4px;
       @media(max-width: 992px) {
            margin-bottom: 0;
            line-height: 15px; 
            color: #878787;
            display: block;
        }
    }
    div{
        word-break: break-all;
    }
}

.styled-radio {
    input[type= checkbox],
    input[type= radio] {
        opacity: 0;
        position: absolute;
        &:focus{
            + label:after  {
                border-color:  #C4C4C4;
            }
        }
        &:checked{
            +label:after {
                background: #A2C6CF;
                border: #A2C6CF;
            }

        }

        &.active{
            + label {
                &:after {
                    background-color: #A2C6CF;
                    border-color: #A2C6CF;
                }
            }
            &:focus-visible{
                + label:after  {
                    border-color: #C4C4C4;
                }
            }
        }
        &.invalid {
            + label {
                color: red;
                > a {
                    color: red;
                }
            }
        }
        + label {
            font-size: $p-m;
            @include font('Open Sans', sans-serif, 300, normal);
            position: relative;
            padding-left: 44px;
            cursor: pointer;
            @media(max-width: 992px) {
                padding-left: 25px;
            }
            &:after {
                content: '';
                border: 1px solid #C4C4C4;
                background-image: none;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                position: absolute;
                left: 0;
                top: 4px;
                display: block;
                cursor: pointer;
                opacity: 1;
               @media(max-width: 992px) {
                    width: 20px;
                    height: 20px;
                    left: 0px;
                    top: 5px;
                }
            }
            &:before{
                display: none;
            }
        }
    }
}
.radio-styled {
    position: relative;
    input[type= checkbox],
    input[type= radio] {
        opacity: 0;
        position: absolute;
        &:focus{
            + label:after  {
                border-color: #A2C6CF;
            }
        }
        &:checked {
            + label:after {
                background-color: #A2C6CF;
                border-color: #A2C6CF;
            }
            &:focus-visible{
                + label:after  {
                    border-color: #C4C4C4;
                }
            }

        }
    }
    label {
        padding-left: 40px;
        width: 100%;
        position: relative;
        border-radius: 12px;
        font-size: 1.125rem;
        @include font('Open Sans', sans-serif, 300, normal);
        @media(max-width: 992px) {
            font-size: $p;
            padding-left: 30px;
        }
        &:after {
            content: '';
            border: 1px solid #C4C4C4;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            position: absolute;
            left: 0px;
            top: 0;
            display: block;
            cursor: pointer;

        }
        p {
            font-size: 1.125rem;
            @media(max-width: 992px) {
                font-size: $p;
            }
        }
        h5 {
            font-family: "SuisseIntl-Book", sans-serif;
            font-size: $p-m;
            color: $brandPurple;
            margin-bottom: 15px;
            @media(max-width: 992px) {
                font-size: $p;
            }
        }
    }
    + .radio-styled {
        margin-top: 21px;
    }
}


// FORM BUILDER (MACRO)
form.xp-formbuilder-form{
    margin-top: 60px;
    margin-bottom: 24px;
    margin-left: calc(35.714% + 15px);
    width: calc(57.1428% - 16px);

    @media(max-width: 992px) {
        margin-top: 40px;
        margin-bottom: 28px;
        margin-left: 0;
        width: 100%;
    }

    h2{
        display: none;
    }

    .xp-form-input{
        margin-bottom: 40px;

        @media(max-width: 992px) {
            margin-bottom: 20px;
        }

        label{
            display: block;
            margin-bottom: 0;

            span{
                &:first-of-type{
                    display: inline-block;
                    margin-bottom: 8px;

                    @media(max-width: 992px) {
                        margin-bottom: 4px;
                    }
                }

                &.required-star{
                    color: $pink;
                }
            }
        }

        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="phone"],
        textarea,
        .dropdown-toggle{
            width: 100%;
            min-height: 75px;
            height: 75px;
            padding: 16px 20px;
            background: white;
            color: #121212;
            border: 1px solid rgba($color: #B1B1B1, $alpha: .5);
            border-radius: 12px;
            outline: none;
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 21px;
            line-height: 36px;            

            @media(max-width: 992px) {
                height: 48px;
                min-height: 48px;
                padding: 6px 20px;
                border-radius: 8px;
            }

            &::-webkit-input-placeholder {
                color: #121212;
                opacity: .6;
            }

            &::-ms-input-placeholder {
                color: #121212;
                opacity: .6;
            }

            &::placeholder{
                color: #121212;
                opacity: .6;
            }

            @media(max-width: 992px) {
                font-size: 15px;
                min-height: 48px;
            }
            &:active, &:focus, &:focus-within {
                border-color: $lightGrey;
                border-bottom-color: $darkGrey;
                box-shadow: none !important;
                outline: none !important;
            }
            &.valid {
                color: $darkGreen;
                border-bottom-color: $darkGreen!important;
            }
            &.invalid {
                border-bottom-color: $red;
                &::-webkit-input-placeholder {
                    color: $red;
                    opacity: 1;
                }
            }
            &.invalidField {
                color: red;
                border-bottom-color: $red;
            }
            &.invalidFieldSelect {
                color: red;
                border-bottom-color: $red;
                &+ .btn.dropdown-toggle{
                    border-bottom: 1px solid red!important;
                    border-radius: 8px;
                    .filter-option-inner-inner{
                        color: red;
                    }
                }
            }
        }

        textarea{
            height: 200px;
            padding: 20px 20px;
            line-height: 32px;

            @media(max-width: 992px) {
                padding: 16px 20px;
                line-height: 27px;
            }
        }

        // select wrapper
        .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
            width: 100%;

            .dropdown-toggle{
                background-color: white;
                border-color: #A1B4AF;
            }

            ul.dropdown-menu{
                li{
                    padding-left: 0;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    margin-bottom: 0;

                    &.active{
                        background-color:$brandPurple;

                        &:hover{
                            background-color: $brandPurple-h;
                        }

                        a{
                            background-color: inherit;
                            color:white !important;
                        }
                    }

                    &:last-child{
                        margin-bottom: 0;
                    }

                    a{
                        border: none !important;
                    }
                }
            }
        }

        // Input radio and checkbox
        .input-radio__wrapper{
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            input[type= radio] {
                opacity: 0;
                position: absolute;
                top: 0;
                width: 22px;
                height: 22px;
                cursor: pointer;

                @media(max-width: 992px) {
                    width: 20px;
                    height: 20px;
                }

                &:checked {
                    &+ label:after {
                        opacity: 1;
                    }
                }

                &:focus-within {
                    + label:before {
                        opacity: 1;
                    }
                }
                + label {
                    font-size: $p-m;
                    position: relative;
                    padding-left: 40px;
                    pointer-events: none;
                    color: $brandPurple;

                    @media(max-width: 992px) {
                        padding-left: 32px;
                        font-size: 17px;
                    }

                    &:before {
                        content: '';
                        width: 22px;
                        height: 22px;
                        background-color: #fff;
                        border: 1px solid black;
                        border-radius: 100%;
                        top: 2px;
                        left: 0;
                        position: absolute;
                        display: block;
                        opacity: 1;

                        @media(max-width: 992px) {
                            width: 20px;
                            height: 20px;
                        }
                    }
                    &:after {
                        content: '';
                        width: 22px;
                        height: 22px;
                        background-color: $orange;
                        border-radius: 100%;
                        top: 2px;
                        left: 0;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        opacity: 0;

                        @media(max-width: 992px) {
                            width: 20px;
                            height: 20px;
                        }
                    }
                    &:hover {
                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        // Input checkbox
        .input-checkbox__wrapper {
            input[type= checkbox] {
                opacity: 0;
                position: absolute;
                &:checked {
                    &+ label:after {
                        opacity: 1;
                    }
                }
                &:focus-within {
                    + label:before {
                        opacity: 1;
                    }
                }
                + label {
                    font-size: $p-m;
                    position: relative;
                    padding-left: 50px;
                    color: $brandPurple;
                    cursor: pointer;

                    @media(max-width: 992px) {
                        padding-left: 42px;
                        font-size: 17px;
                    }

                    &:before {
                        content: '';
                        width: 34px;
                        height: 34px;
                        background-color: #fff;
                        border: 1px solid $brandPurple;
                        border-radius: 8px;
                        top: -3px;
                        left: 0;
                        position: absolute;
                        display: block;
                        opacity: .5;

                        @media(max-width: 992px) {
                            top: -2px;
                            width: 28px;
                            height: 28px;
                            border-radius: 6px;
                        }
                    }
                    &:after {
                        content: '';
                        width: 34px;
                        height: 34px;
                        background-image: url(../images/icons/check.svg);
                        top: -3px;
                        left: 0;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-position: center;
                        opacity: 0;

                        @media(max-width: 992px) {
                            top: -2px;
                            width: 28px;
                            height: 28px;
                        }
                    }
                    &:hover {
                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    // Submit
    input[type="submit"]{
        margin-top: 0px;
        padding: 15px 28px;
        border-radius: 40px;

        @media(max-width: 992px) {
            margin-top: 0px;
        }
    }

    &.form-loading{
        #xp-formbuilder-spinner{
            display: inline-block !important;
            fill: $brandPurple;
            animation: xp-fb-rotation 2s infinite linear !important;
            margin: 0 !important;
        }
    }

    // Thank you message
    p{
        width: 100% !important;
        margin-left: 0 !important;
    }
}
