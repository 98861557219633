.hero{
    &__portrait,
    &__landscape,
    &--horizontal,
    &__square {
        z-index: 0;
        margin-bottom: 122px;
        @media(max-width: 992px){
            margin: 0 auto 60px auto;
            max-width: 564px;
        }
        .img{
            margin-bottom: 35px;
            @media(max-width: 920px){
                margin-bottom: 24px;
            }
        }
        h3{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: rem(36px);
            line-height: rem(41px);
            color: #506279;
            margin-bottom: 35px;
            @media(max-width: 992px){
                font-size: rem(26px);
                line-height: rem(30px);
                margin-bottom: 38px;
            }
        }
        p{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: rem(24px);
            line-height: rem(28px);
            color: #000000;
            margin-bottom: 75px;
            @media(max-width: 992px){
                display: none;
            }
        }
        .btn{
            margin: 0 auto;
            display: table;
            @media(max-width: 992px){
                max-width: 204px;
                padding: 14px 30px;
            }
        }
    }


    &.hero-carousel{
        // @media(max-width: 992px){
        //     width: 93%;
        // }
        .slick-slider{
            @media(max-width: 992px){
                width:106%;
            }
        }
        .offset-lg-1.col-lg-15{
            @media(max-width: 992px){
                padding: 0!important;
            }
        }
        .slick-list{
            overflow: visible;
        }
    }

    &__title{
        margin-bottom: 65px;
        @media(max-width: 992px){
            text-align: center;
            margin-bottom: 40px;
        }
        h2{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: rem(48px);
            line-height: rem(55px);
            color: #506279;
            display: inline-block;
            margin-right: 40px;
            @media(max-width: 992px){
                font-size: rem(36px);
                line-height: rem(41px);
                display: block;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        a{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: rem(22px);
            line-height: rem(25px);
            color: #506279;
            display: inline-block;
            padding-bottom: 5px;
            @media(max-width: 992px){
                font-size: rem(18px);
                line-height: rem(20px);
                border-bottom: 1px solid #506279;
                padding-bottom: 0;
            }
        }
    }

    &__square{
        &--blue{
            &:before{
                z-index: -1;
                content: url("../images/medlearn/hero-blue.svg");
                width: 111.93%;
                max-width: 111.93%;
                height: auto;
                left: -22%;
                top: -5%;
                position: absolute;
                @media(max-width: 992px){
                    display: none;
                }
            }
        }
    }

    &__landscape{
        margin-top: 38%;
        position: relative;
        @media(max-width: 992px){
            margin-top: 0;
        }
    }

    &__landscape{
        &--sand{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-landscap-sand.svg");
                width: 111.93%;
                max-width: 111.93%;
                height: auto;
                left: -20%;
                top: -21%;
                position: absolute;
                @media(max-width: 992px){
                    content: url("../images/form-hero-landscap-sand-mobile.svg");
                    width: 100%;
                    max-width: 98.74%;
                    left: inherit;
                    right: -34%;
                    top: -8.5%;
                }
            }
        }
        &--purple{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-landscap-purple.svg");
                width: 111.93%;
                max-width: 111.93%;
                height: auto;
                left: -20%;
                top: -21%;
                position: absolute;
                @media(max-width: 992px){
                    content: url("../images/form-hero-landscap-purple-mobile.svg");
                    width: 100%;
                    max-width: 98.74%;
                    left: inherit;
                    right: -34%;
                    top: -8.5%;
                }
            }
        }
    }

    &__portrait{
        &--sand{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-portrait-sand.svg");
                width: 108%;
                max-width: 108%;
                height: auto;
                left: -22%;
                top: 15%;
                position: absolute;
                @media(max-width: 992px){
                    content: url("../images/form-hero-portrait-sand.svg");
                    width: 100%;
                    max-width: 98.74%;
                }
            }
        }
        &--purple{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-portrait-purple.svg");
                width: 108%;
                max-width: 108%;
                height: auto;
                left: -22%;
                top: 15%;
                position: absolute;
                @media(max-width: 992px){
                    content: url("../images/form-hero-portrait-purple.svg");
                    width: 100%;
                    max-width: 98.74%;
                }
            }
        }
    }

    &--horizontal{
        &__img {
            .img{
                margin-bottom: 0;
                @media(max-width: 992px) {
                    margin-bottom: 20px;
                }
            }

        }
        &__info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        &.right-image{
            .row{
                @media (max-width: 992px) {
                    flex-direction: column-reverse;
                }
            }
        }

    }


    &--horizontal--sand{
        .hero--horizontal__img{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-horizontal-sand.svg");
                width: 100%;
                max-width: 79%;
                height: auto;
                right: -31%;
                top: -5%;
                position: absolute;
                @media(max-width: 992px){
                    width: 100%;
                    max-width: 98.74%;
                    left: inherit;
                    right: -34%;
                    top: 0%;
                }
            }
        }
        &.right-image{
            .hero--horizontal__img{
                &:before{
                    right: inherit;
                    left: -20%;
                    transform: scaleX(-1);
                }
            }
        }
    }
    &--horizontal--purple{
        .hero--horizontal__img{
            &:before{
                z-index: -1;
                content: url("../images/form-hero-horizontal-purple.svg");
                width: 100%;
                max-width: 79%;
                height: auto;
                right: -31%;
                top: -5%;
                position: absolute;
                @media(max-width: 992px){
                    width: 100%;
                    max-width: 98.74%;
                    left: inherit;
                    right: -34%;
                    top: 0%;
                }
            }
        }
    }


}

