.studieradgiving{
    z-index: 1001;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-x: auto;
    right: -100vw;
    -ms-overflow-style: none;
    transition: all 300ms;
    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper{
        background: #ffffff;
        width: 688px;
        height: 100%;
        float: right;
        overflow: auto;        

        @media (max-width: 768px) {
            padding: 0 20px 38px 20px;
        }

        @media(max-width: 992px){
            width: 100%;
        }

        .contactWrapper{
            padding: 110px 94px 60px 130px;
            background: #506279;
            position: relative;
            z-index: 0;
            @media (max-width: 992px) {
               padding: 110px 130px 60px 130px; 
            }
            @media (max-width: 768px) {
                padding: 20px 0 38px 0;
                &:after{
                    content: "";
                    height: 100%;
                    width: 100vw;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    background: #506279;
                    z-index: -1;
                }
             
            }
            .logoWrapper{
                display: none;
                margin-bottom: 40px;
                @media (max-width: 768px) {
                  display: block;  
                }
                img{
                    max-width: 179px;
                }
            }
            .item{
                display: inline-block;
                width: 46.5%;
                max-width: 46.5%;
                margin-right: 6.1%;
                @media(max-width: 992px){
                    width: 49%;
                    max-width: 49%;
                    margin: 0!important; 
                }
                @media(max-width: 374px){
                    width: 100%;
                    max-width: 100%;
                }

                .title{
                    display: block;
                    @include font('Open Sans', sans-serif, 300, normal);                     
                    font-size: 20px;
                    line-height: 38px;
                    color: #ffffff;
                    @media (max-width: 992px) {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }

                .tel{
                    a,
                    span{
                        @include font('Open Sans', sans-serif, 400, normal);
                        font-size: 25px;
                        line-height: 38px;
                        color: #FFFFFF;
                        display: inline-block;
                        margin-right: 4px;
                        @media (max-width: 992px) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                    a{
                        text-decoration: none;
                        border-bottom: 2px solid #F6CDD6;
                        &:hover{
                            border-bottom: 2px solid #F6CDD6;
                        }
                    }
                }

                .schedule{
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 25px;
                    line-height: 38px;
                    color: #FFFFFF;
                    display: inline-block;
                    @media (max-width: 992px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                &:last-child{
                    margin-right: 0;
                    margin-bottom: 0;
                    @media(max-width: 992px){
                        float: right;
                    }
                    @media(max-width: 374px){
                        margin-top: 20px!important;
                        float: none;
                    }
                    .title{
                        text-align: right;
                        @media(max-width: 374px){
                            text-align: left;
                        }
                    }
                    .schedule{
                        text-align: right;
                        display: inline-block;
                        width: 100%;
                        @media(max-width: 374px){
                            text-align: left;
                        }
                    }
                }

            }


        }

        .infoWrapper{
            padding: 37px 124px 0px;
            @media (max-width: 768px) {
                padding: 35px 0 0;
            }
            p{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                color: #506279;
                @media (max-width: 992px) {
                    max-width: 375px;
                    margin: 0 auto;
                }
            }
        }

        .formWrapper{
            padding: 36px 130px 80px 130px;
            @media (max-width: 768px) {
                padding: 24px 0 0;
                background: transparent;
            }
            .form-group{
                width: 100%;
                display: inline-block;
                margin-right: 6.5%;
                margin-bottom: 20px;
                @media (max-width: 768px) {
                    width: 100%;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 22px;
                }
                &:last-child{
                    margin-right: 0;
                    @media (max-width: 768px) {
                        margin-right: auto;
                    }
                }
                label{
                    font-size: 15px;
                    line-height: 52px;
                    line-height: 24px;
                    margin-bottom: 4px;
                    span{
                        display: none;
                        @media (max-width: 768px) {                            
                            display: inline-block;
                        }
                    }
                    @media (max-width: 768px) {
                        font-size: 14px;
                        line-height: 25px;
                        margin-bottom: 8px;
                        
                    }
                }

                .form-control{
                    min-height: 55px; 
                    font-size: 19px;
                    line-height: 52px;
                    border-radius: 12px;

                    @media (min-width: 993px) {
                        padding: 0 24px;                        
                        border-color: #C4C4C4;
                        &:active, &:focus, &:focus-within {
                            border-bottom-color: $darkGrey;
                        }
                    }

                    @media (max-width: 768px) {
                        font-size: 16px;
                        line-height: 24px;
                        min-height: 53px;
                    }

                    &.select{
                        padding: 0;
                    }
                    .btn-light{
                        padding: 0 24px;
                        line-height: 53px!important;
                    }

                }
                textarea.form-control{
                    padding-bottom: 8px;
                    @media (max-width: 992px) {
                        min-height: 184px;
                    }
                }
                .radioItem{
                    // input[type="radio"] {
                    //     display:none;
                    // }
                    // input[type="radio"] + label {
                    //     @include font('Open Sans', sans-serif, 400, normal);
                    //     font-size: 17px;
                    //     line-height: 25px;
                    //     color: #121212;
                    //     margin-bottom: 0;
                    //     @media (max-width: 992px) {
                    //         font-size: 16px;
                    //         line-height: 24px;
                    //     }
                    // }

                    // input[type="radio"] + label span {
                    //     display:inline-block;
                    //     width:24px;
                    //     height:24px;
                    //     margin:0 16px 0 0;
                    //     vertical-align:middle;
                    //     background:url("../images/icons/radio.svg")  0px top no-repeat;
                    //     cursor:pointer;
                    //     background-size: auto 24px;
                    // }

                    // input[type="radio"]:checked + label span {
                    //     background:url("../images/icons/radio.svg") -25px top no-repeat;
                    //     background-size: auto 24px;
                    // }
                    .radio-styled {
                        margin-top: 12px;
                        input[type=radio]{
                            +label{
                                font-size: 19px;
                                line-height: 25px;
                                padding-left: 40px;
                                &:after {
                                    top: 0;
                                }
                            }
             
                            &:not(:focus) +label:after {
                                border-color: #C4C4C4;
                            }
                            &:checked{
                                +label:after {
                                    border-color: #A2C6CF;
                                }

                            }
                            
                            @media (max-width: 992px) {

                                color: #483A54;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                    }
                    .item{
                        display: inline-block;
                        margin-right: 24px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }


            .btnSend{
                text-align: left;
                margin-top: 20px;
                @media (max-width: 768px) {
                    text-align: center;
                    margin-top: 34px;
                }
                button{
                    @include font('Open Sans', sans-serif, 700, normal);
                    color: #000000;
                    font-size: 20px;
                    text-decoration: none;
                    display: inline-block;
                    background: #A2C6CF;
                    padding: 10px 32px;
                    border-radius: 38px;
                    outline: 0;
                    border: none;

                    @media (max-width: 768px) {
                        font-size: 20px;
                        line-height: 20px;
                        padding: 17px 32px;
                    }

                }
            }


        }

        .btnClose{
            padding: 12px;
            position: fixed;
            z-index: 10;
            top: 28px;
            right: -100vw;
            transition: all 350ms;
            @media(max-width: 768px){
                position: absolute;
                top: 21px;
                right: 24px;
                padding: 0
            }
            img{
              width: 25px;
              height: auto;     
            }
        }

        &--content{


        }

    }
    &.active{
        right: 0;
        .btnClose{
            right: 28px;
            @media(max-width: 992px){
                right: 15px;
            }
        }
        .overlay{
            animation: backgroundFade 300ms 300ms both;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    @keyframes backgroundFade {
        from {
            background: rgba($color: #000000, $alpha: 0);
        }

        to {
            background: rgba($color: #000000, $alpha: .5);
        }
    }
}
