.studiePageHeader{
    padding-top: 115px;
    margin-bottom: 55px;
    @media (max-width: 992px) {
        padding-top: 18px;
        margin-bottom: 25px;
    }
    &__title{
        h1{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 65px;
            line-height: 75px;
            color: #506279;
            @media (max-width: 992px) {
                font-size: 36px;
                line-height: 41px;
                padding-left: 0;
            }
        }
    }
}

.CTA-course{
    margin-bottom: 110px;
    @media (max-width: 992px) {
        margin-top: 26px;
        margin-bottom: 66px;
    }

    &.desktop{
        display: block;
        @media (max-width: 992px) {
            display: none;
        }
    }

    &.mobile{
        display: none;
        @media (max-width: 992px) {
            display: block;
        }
    }

    &__wrapper{
        display: flex;
        justify-content: flex-end;
        @media (max-width: 992px) {
            justify-content: center;
        }
        .btn{
            font-weight: 600;
        }
    }
}

.breadcrumbsStudie{
    margin-bottom: 48px;
    @media (max-width: 992px) {
        margin-bottom: 24px;
    }
    a{
        display: inline-block;
        @include font('Open Sans', sans-serif, 300, normal);
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        color: #B4B4B4;
        margin-right: 12px;
        text-decoration: none;
        &:last-child{
            color: #353637;
            text-decoration: underline;
        }
        @media (max-width: 992px) {

        }
    }
}

.studiePageHeaderBasic{
    margin-bottom: 86px;
    @media (max-width: 992px) {
        margin-bottom: 44px;
    }
    &__title{
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 72px;
        line-height: 88px;
        letter-spacing: -0.88px;
        color: #483A54;
        @media (max-width: 992px) {
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 40px;
        }
    }
    &__item{
        .label{
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 28px;
            line-height: 44px;
            color: #B3B3B3;
            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }
            &.label-select{
                @media (max-width: 992px) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
        .item,
        .select{
            display: block;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 28px;
            line-height: 44px;
            color: #121212;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }
        .item{
            position: relative;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }
        .item.help{
            padding-right: 35px;
            span{
                position: relative;
                .help {
                    padding: 0;
                    border-radius: 50%;
                    border: none;
                    background: #FBCFB7;
                    @include background-image("./icons/icon-question.svg", 20, 20);
                    position: absolute;
                    bottom: 7px;
                    right: -32px;
                    cursor: pointer;
                    &:active,
                    &:focus{
                        outline: 0;
                    }
                    @media (max-width: 992px) {
                        bottom: 3px;
                        @include background-image("./icons/icon-question.svg", 16, 16);
                        right: -22px;
                    }
                }
            }

        }
    }
}

.courseType{
    // margin-bottom: 34px;
    margin-bottom: 114px;
    @media (max-width: 992px) {
        margin-bottom: 36px;
    }

    .col-lg-8{
        &.col-lg-8.or-derfirst.order-lg-0,
        &.col-lg-8.order-first.order-lg-0{
            padding-left: 45px;
        }
        @media (max-width: 992px) {
            min-height: 90px;
        }
    }
    .hideCourseItemMenu{
        @media (max-width: 992px) {
            display: none;
        }
    }

    .lineItem{
        @media (max-width: 992px) {
            //&:nth-child(1){order: 1;}
            &:nth-child(2){order: 1;}
            &:nth-child(3){order: 3;}
            &:nth-child(4){order: 2;}
            &:nth-child(5){order: 4;}
            &:nth-child(6){order: 5;}
        }
    }

    &__item{
        margin-bottom: 38px;
        @media (max-width: 992px) {
            margin-bottom: 24px;
        }
        .label{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 26px;
            line-height: 38px;
            color: rgba(0, 0, 0, 0.5);
            position: relative;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
            &.label-select{
                @media (max-width: 992px) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
        .btnWrapper{
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding-top: 5px;
            @media (max-width: 992px) {
                justify-content: center;
                padding-top: 10px;
            }
            .btn{
                font-weight:  600;
            }
        }
        .item,
        .select,
        .item a{
            display: block;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 26px;
            line-height: 38px;
            color: #000000;
            b{
                font-weight: 600;
            }
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }
        .item{
            .checkIcon{
                color: #40AF4B;
                font-size: 34px;
                font-weight: bolder;
                @media (max-width: 992px) {
                    font-size: 34px;
                }
            }
        }
        .item.select{

            span{
                display: initial;
                border-bottom: 2px solid  rgba(246, 141, 111, .6);
                &:hover{
                    border-bottom: 2px solid  rgba(246, 141, 111, 1);
                    span{
                        border-bottom: 2px solid  rgba(246, 141, 111, 0);
                    }
                }
                span{
                    position: relative;
                    border: none;
                    &:after{
                        @include background-image("./icons/arrow-down.svg", 16, 19);
                        position: absolute;
                        right: -33px;
                        top: 8px;
                        @media (max-width: 992px) {
                            @include background-image("./icons/arrow-down.svg", 12, 14);
                            right: -21px;
                            top: 5px;
                        }
                    }

                }
            }
            .item-selected{
                cursor: pointer;
                .selectedSelect{
                    &:after{
                        content: ":";
                    }
                }
                .selectedOption{
                    &.invalid{
                        color: red;
                    }
                }
            }
        }
        .link{
            display: block;
            display: block;
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.24px;
            color: #B4B4B4;
            a{
                display: inline-block;
                @include font('Open Sans', sans-serif, 300, normal);
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                text-decoration-line: underline;
                color: #B4B4B4;
            }
        }
        .item{
            position: relative;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            @media (max-width: 992px) {
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        .item.help{
            padding-right: 35px;
            span{
                position: relative;
                .help {
                    padding: 0;
                    border-radius: 50%;
                    border: none;
                    background: #FBCFB7;
                    @include background-image("./icons/icon-question.svg", 20, 20);
                    position: absolute;
                    bottom: 7px;
                    right: -32px;
                    cursor: pointer;
                    &:active,
                    &:focus{
                        outline: 0;
                    }
                    @media (max-width: 992px) {
                        @include background-image("./icons/icon-question.svg", 16, 16);
                        bottom: 3px;
                        right: -22px;
                    }
                }
            }

        }

        &.select{
            padding-right: 50px;
            @media (max-width: 992px) {
                padding-right: 20px;
            }
            .options{
                display: none;
                div{
                    display: block;
                    margin-bottom: 10px;
                    span{
                        cursor: pointer;
                        border-bottom: 2px solid rgba(246,141,111,0);
                        &.selected{
                            border-bottom: 2px solid rgba(246,141,111,1);
                        }
                    }

                }
                .subSelect{
                    // margin-bottom: 20px;
                    // &:last-child{
                    //     margin-bottom: 0;
                    // }
                    .title{
                        margin-bottom: 0;
                        display: inline-block;
                        > span{
                            border-bottom: 2px solid rgba(246,141,111,0);
                            cursor: pointer;
                            padding-right: 25px;
                        }

                        span span{
                          display: inline-block;
                          &:after{
                            @include background-image("./icons/arrow-down.svg", 13, 15);
                            right: -21px;
                            top: 12px;
                            transform: rotate(0);
                            transition: all 150ms linear;
                            @media (max-width: 992px) {
                                top:7px;
                            }
                          }
                        }
                        &.active{
                            span span{
                                &:after{
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                    .subOptions{
                        padding-left: 20px;
                        margin-bottom: 20px;
                        display: none;
                    }
                }
            }

            &.active{
                z-index: 5;
                // position: relative;
                position: absolute;
                padding: 0;
                width: 100%;
                max-width: 350px;
                @media (max-width: 992px) {
                    padding: 0;
                }
                @media (max-width: 575px) {
                    max-width: 100%;
                }

                &:before{
                    content: "";
                    position: absolute;
                    width: calc(100% + 80px);
                    height: calc(100% + 80px);
                    background: #ffffff;
                    top: -40px;
                    left: -40px;
                    // z-index: -1;
                }

                .overlaySelect{
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(18, 18, 18, 0.5);
                    top: 0;
                    left: 0;
                    z-index: -2;
                }

                .item-selected{
                    display: none;
                }

                *{
                    font-size: 21px;
                    line-height: 36px;
                    @media (max-width: 992px) {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
                .options{
                    display: block;
                }
            }
        }

    }
    .col-xs-8.col-lg-8{
        @media (max-width: 992px) {
            padding: 0;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    // CALL TO ACTION BUTTON (course page)
    // .btn-cta{
    //     position: relative;
    //     display: block;
    //     margin-top: 10px;
    //     padding: 14px 60px 14px 32px;
    //     border-radius: 30px;
    //     font-size: 18px;
    //     font-weight: 400;
    //     text-transform: uppercase;

    //     &::after{
    //         content: "";
    //         display: block;
    //         position: absolute;
    //         top: calc(50% - 8px);
    //         right: 28px;
    //         width: 18px;
    //         height: 16px;
    //         background-image: url(../images/./icons/arrow-email.svg);
    //         background-repeat: no-repeat;
    //         background-size: auto;
    //     }

    //     &.btn-brand-red{
    //         color: $brandPurple;

    //         &::after{
    //             background-image: url(../images/./icons/arrow-email-purple.svg);
    //         }
    //     }
    // }

}

.featuredImageIngress{
    &__image,
    &__video{
        margin-bottom: 38px;
        width: 100%;
        //max-width: calc(93.5% - 15px);
        margin-right: auto;
        margin-left: auto;
        @media (max-width: 992px) {
            margin-bottom: 26px;
            max-width: inherit;
        }
        &.basic{
            width: 100%;
            //max-width: calc(88.1% - 15px);
            @media (max-width: 992px) {
                max-width: inherit;
            }
        }
    }
    &__video{
        position: relative;
        iframe{
            height: 100%;
        }
        .playVideo{
            background: none;
            border: none;
            @include background-image("./medlearn/play-video-btn.svg", 100, 85);
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: calc(-85px / 2);
            margin-left: calc(-100px / 2);
            @media (max-width: 992px) {
                @include background-image("./medlearn/play-video-btn.svg", 100, 85);
                margin-top: calc(-85px / 2);
                margin-left: calc(-100px / 2);
            }
            &:focus{
                outline: none;
            }
        }
        .thumbnail{
            cursor: pointer;
        }
        .closeVideo{
            background: none;
            border: none;
            background-color: rgba($color: #ffffff, $alpha: 0.7);
            @include background-image("icons/close-menu.svg", 50, 50);
            position: absolute;
            border-radius: 50%;
            background-size: 25px;
            top: 50px;
            right: 50px;
            display: none;
            @media (max-width: 992px) {
                width: 30px;
                height: 30px;
                background-size: 10px;
                top: 20px;
                right: 20px;
            }
            &:hover{
                background-color: rgba($color: #ffffff, $alpha: 1);
            }
            &:focus{
                outline: none;
            }
        }

        .img iframe{
            opacity: 0;
        }

        .img iframe, .thumbnail, .playVideo{transition: all 500ms;}

        .thumbnail,
        .playVideo{
            opacity: 1;
        }

        &.active{
            .img iframe{
                opacity: 1;
            }
            .thumbnail,
            .playVideo{
                opacity: 0;
                pointer-events: none;
            }
            .closeVideo{
                display: block;
            }
        }

    }
    &__ingress{
        margin-bottom: 60px;
        @media (max-width: 992px) {
            margin-bottom: 15px;
        }
        h2{
            @include font('Open Sans', sans-serif, 300, normal);
            font-weight: 300;
            font-size: 25px;
            line-height: 40px;
            letter-spacing: 0.24px;
            color: #121212;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
        }
    }
}


.readMoreStudie{
    margin-top: -38px;
    display: none;
    @media (max-width: 992px) {
        margin-bottom: 55px;
        display: block;
    }
    .more,
    .minus{
        @include font('Open Sans', sans-serif, 300, normal);
        font-size: 25px;
        line-height: 40px;
        letter-spacing: 0.24px;
        color: #483A54;
        text-decoration: none;
        display: inline-block;
        @media (max-width: 992px) {
            font-size: 17px;
            line-height: 28px;
        }
        border-bottom: 2px solid  rgba(246, 141, 111, .6);
        &:hover{
            border-bottom: 2px solid  rgba(246, 141, 111, 1);
        }
    }

    .minus{
        display: none;
    }

    &.active{
        .more{
            display: none;
        }
        .minus{
            display: inline-block;
        }
    }

}

.article{
    &+.readMoreStudie{

    }
    &+.quoteBig{
        margin-bottom: 138px;
        @media (max-width: 992px) {
            margin-bottom: 70px;
        }
    }
}

.relatedContent.studie{
    @media (max-width: 992px){
        .blogPosts__sliderWrapper--slider {
            margin-bottom: 0;
        }
    }
}
