.search-bar-wrapper{
    position: fixed;
    //background-color: #fff;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    z-index: 10;
    transition: all 300ms;
    &.active{
        top: 0;
    }
    .btn-close{
        padding: 0;
        position: absolute;
        top: 40px;
        right: 50px;
        &:active,
        &:focus{
            outline: none;
            box-shadow: none;
        }
        @media(max-width: 992px) {
            top: 35px;
            right: 33px;
        }
        &:not(:disabled):hover{
            background-color: transparent;
            border-color: transparent;
        }
        img {
            width: 40px;
            height: 40px;
            @media(max-width: 992px) {
                width: 20px;
                height: 20px;
            }
        }

    }



    &__inputModal{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(80, 98, 121, 0.9);
        z-index: 99;
        overflow: auto;
        form{
            input{
                border: none;
                background: #FFFFFF;
                border-radius: 30px;
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 20px;
                line-height: 23px;
                padding: 17px 36px;
                color: #000000;
                width: 100%;
                max-width: 511px;
                margin: 397px auto 200px auto;
                display: block;

                @media(max-width: 992px) {
                    font-size: 20px;
                    line-height: 23px;
                    padding: 17px 36px;
                }

                &::placeholder{
                    color: #000000;
                }
                &:active,
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
                @media(max-width: 992px) {
                    margin: 175px auto 50px auto;
                }
            }
        }

        .btn-close-modal{
            position: absolute;
            right: 20px;
            padding: 0;
            top: 225px;
            border: none;
            &:active,
            &:focus{
                outline: none;
                box-shadow: none;
            }
            &:not(:disabled):hover{
                background-color: transparent;
                border-color: transparent;
            }
            @media(max-width: 992px) {
                right: 10px;
                top: 24px;
            }
            img{
                width: 42px;
            }
        }

    }

    &__header{
        padding-top: 50px;
        position: relative;
        height: 144px;
        border-bottom: 1px solid #D6D6D6;
        @media(max-width: 992px){
            padding-top: 88px;
        }
        .btn-search{
            padding: 0;
            position: absolute;
            left: -48px;
            top: 5px;
            @media(max-width: 992px){
                left: 0px;
                top: 50%;
                margin-top: -14px;
            }
            &:active,
            &:focus{
                outline: none;
                box-shadow: none;
            }
            img {
                width: 29px;
                height: 29px;
                @media(max-width: 992px){
                    width: 20px;
                    height: 20px;
                }
            }
        }
        form{
            position: relative;
            &:after{
                content: "";
                width: 50px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                pointer-events: none;
                background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
                @media(max-width: 992px){
                    right: 50px;
                }
            }
        }
        input {
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 32px;
            line-height: 44px;
            color: #121212;
            border: none;
            width: 100%;
            &::placeholder{
                color: #121212;
            }
            &:active,
            &:focus{
                outline: none;
                box-shadow: none;
            }
            @media(max-width: 992px){
                padding-right: 50px;
                padding-left: 40px;
                font-size: 18px;
                line-height: 32px;
            }
        }
    }
    &__suggestion{
        border-bottom: 1px solid $grey;
        height: calc(100% - 144px);
        width: 100vw;
        overflow-y: auto;
        @media(max-width: 992px){
            height: calc(100% - 144px);
        }
        a{
            text-decoration: none;
            &:nth-child(odd){
                .search-bar-wrapper__suggestion__item{
                    background-color:  #F5F5F5;
                }
            }
        }
        &__item{
            padding: 40px 0;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 32px;
            line-height: 44px;
            color: #121212;

            @media(max-width: 992px){
                font-size: 18px;
                line-height: 32px;
                padding: 0 15px;
                padding: 24px 0;
            }

        }
    }

}
