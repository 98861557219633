.search-result-wrapper{
    background-color: #fff;
    width: 100%;
    height: 100%;

    .container{
        position: relative;
    }
    .btn-close{
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        &:active,
        &:focus{
            outline: none;
            box-shadow: none;
        }
        @media(max-width: 992px) {
            margin-top: 0;
            right: 10px;
        }
        &:not(:disabled):hover{
            background-color: transparent;
            border-color: transparent;
        }
        img {
            width: 40px;
            height: 40px;
            @media(max-width: 992px) {
                width: 20px;
                height: 20px;
            }
        }

    }


    &__header{
        padding-top: 50px;
        position: relative;
        height: 144px;        
        margin-bottom: 86px;
        .container,
        .row,
        .col-lg-16{
            height:100%;
        }
        .col-lg-16{
            position: relative;
            @media(max-width: 992px) {
                padding: 0!important;
            }
            &:after{
                content: "";
                width: calc(100% - 40px);
                height: 1px;
                background: #D6D6D6;
                bottom: 0;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                @media(max-width: 992px){
                    width: 100vw;
                }
            }
        }
        @media(max-width: 992px){
            height: 97px;
            padding-top: 36px;
            margin-bottom: 36px;
        }
        .btn-search{
            padding: 0;
            position: absolute;
            left: 0;
            top: 5px;
            @media(max-width: 992px){
                left: 5px;
                top: 50%;
                margin-top: -10px;
            }
            &:active,
            &:focus{
                outline: none;
                box-shadow: none;
            }
            
            span{
                font-size: 40px;
                line-height: 40px;
                color: #010101;
                @media(max-width: 992px){
                    font-size: 25px;
                    line-height: 25px;
                }
            }
            
        }
        form{
            position: relative;
            padding: 0 50px;
            @media(max-width: 992px){
                padding: 0;
            }
            &:after{
                content: "";
                width: 50px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                pointer-events: none;
                background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
                @media(max-width: 992px){
                    right: 50px;
                }
            }
        }
        input {
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 34px;
            line-height: 44px;
            color: #000000;
            border: none;
            width: 100%;
            &::placeholder{
                color: #121212;
            }
            &:active,
            &:focus{
                outline: none;
                box-shadow: none;
            }
            @media(max-width: 992px){
                padding-right: 60px;
                padding-left: 40px;
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

    &__result{
        .result-header{
            margin-bottom: 84px;            
            @media(max-width: 992px){
                margin-bottom: 55px;
            }
            @media(max-width: 992px){
                .container,
                .offset-lg-1.col-lg-15{
                    padding: 0!important;
                }
            }
            h2{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 28px;
                line-height: 32px;
                color: #506279;
                margin-bottom: 37px;
                padding: 0 50px;
                @media(max-width: 992px){
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 43px;
                    padding: 0 20px;
                    color: #000000;
                }
            }
            .buttonWrapper{
                padding: 0 50px;
                width: 100%;
                overflow-y: hidden;
                overflow-x: auto;
                &::-webkit-scrollbar {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
                &::-moz-scrollbars {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
                &::-o-scrollbar {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
                &::-google-ms-scrollbar {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
                &::-khtml-scrollbar {display:none!important; height: 0 !important; width: 0 !important; background: transparent;}
                scrollbar-width: none!important;
                -ms-overflow-style: -ms-autohiding-scrollbar!important;

                @media(max-width: 992px){
                    padding: 0 20px;
                }

                .content{
                    display: flex;
                    flex-wrap: wrap;
                    @media(max-width: 992px){
                        flex-wrap: nowrap;
                    }

                    // @media(max-width: 992px) {
                    //     &:before{
                    //         content: "";
                    //         width: 1px;
                    //         height: 1px;
                    //         display: inline-block;
                    //         padding-left: 43px;
                    //     }

                    //     &:after{
                    //         content: "";
                    //         width: 1px;
                    //         height: 1px;
                    //         display: inline-block;
                    //         padding-right: 43px;
                    //     }
                    // }

                    .btn{
                        white-space: nowrap;
                        @include font('Open Sans', sans-serif, 300, normal);
                        font-size: 20px;
                        line-height: 42px;
                        color: #000000;
                        border-radius: 30px;
                        padding: 5px 24px;
                        margin-right: 30px;
                        border: 1px solid #C4C4C4;

                        @media(max-width: 992px){
                            padding: 10px 19px;
                            font-size: 17px;
                            line-height: 20px;
                            margin-right: 10px;
                        }

                        &:last-child{
                            margin-right: 0;
                        }

                        &:focus,
                        &:active,
                        &:hover{
                            box-shadow: none;
                        }
                        &.active{
                            border: 1px solid #F6CDD6;
                            box-shadow: none;
                        }
                        span{
                            display: inline-block;
                            padding-right: 12px;
                            margin-right: 8px;
                            position: relative;
                            &:after{
                                content:"";
                                width: 7px;
                                height: 7px;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                margin-top: -3.5px;
                                background: #000000;
                                border-radius: 50%;
                            }
                        }
                    }
                }

            }
        }


        .result-list{
            display: none;
            &.active{
                display: block;
            }

            .content{
                @media(max-width: 992px) {
                    padding: 0 15px;
                    .content{
                        padding: 0;
                    }
                }
            }

            &[data-result="program"]{

                .content{
                    margin-bottom: 100px;
                    @media(max-width: 992px) {
                        margin-bottom: 40px;
                    }

                    .item{
                        padding: 40px 0;
                        border-bottom: 1px solid #D6D6D6;

                        @media(max-width: 768px) {
                            padding: 25px 0 25px 0;                            
                        }

                        a{
                            text-decoration: none;
                        }

                        &:first-child{
                            padding: 0 0 40px 0;
                            @media(max-width: 992px) {
                                padding: 0 0 25px 0;
                            }
                        }

                        .link{
                            margin-bottom: 13px;
                            @media(max-width: 992px) {
                                margin-bottom: 8px;
                            }   
                            @media(max-width: 768px) {
                               max-width: 290px;                         
                            }                          
                            span{
                                @include font('Open Sans', sans-serif, 400, normal);
                                font-size: 30px;
                                line-height: 48px;
                                color: #506279;
                                display: inline;
                                word-break: break-word;
                                border-bottom: 2px solid #F6CDD6;;
                                @media(max-width: 992px) {
                                    @include font('Open Sans', sans-serif, 400, normal);
                                    font-size: 18px;
                                    line-height: 26px;
                                }
                            }
                        }
                        .info{
                            @media(max-width: 768px) {
                               max-width: 290px;                         
                            } 
                            div{
                                @include font('Open Sans', sans-serif, 300, normal);
                                font-size: 20px;
                                line-height: 48px;
                                color: #121212;
                                display: inline;
                                padding-right: 28px;
                                word-break: break-all;
                                @media(max-width: 992px) {
                                    font-size: 16px;
                                    line-height: 26px;
                                    display: none;
                                    &:first-child{
                                        display: inline-block;
                                        span{
                                            &:after{
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                span{
                                    position: relative;
                                    display: inline-block;
                                    word-break: break-all;
                                    &:after{
                                        content: "";
                                        position: absolute;
                                        background: #121212;
                                        right: -16px;
                                        top: 50%;
                                        margin-top: -2px;
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 50%;
                                    }
                                }
                                &:last-child{
                                    padding-right: 0;
                                    span{
                                        &:after{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

            }

            &[data-result="information"]{

                .content{
                    margin-bottom: 100px;
                    @media(max-width: 992px) {
                        margin-bottom: 40px;
                    }
                    .item{
                        padding: 40px 0;
                        border-bottom: 1px solid #D6D6D6;
                        overflow: hidden;
                        position: relative;

                        @media(max-width: 992px) {
                            padding: 29px 0  24px 0;
                        }

                        &:first-child{
                            padding: 0 0 40px 0;
                            @media(max-width: 992px) {
                                padding: 0 0 24px 0;
                            }
                            .imgWrapper{
                                top:0;
                            }
                        }

                        a{
                            text-decoration: none;
                        }

                        .imgWrapper{
                            position: absolute;
                            top: 40px;
                            left: 0;
                            width: 132px;
                            @media(max-width: 992px) {
                                width: 71px;
                                top: 29px;
                            }
                            img{
                                width: 100%;
                            }
                            &+ .info{
                                padding-left: 172px;
                                min-height: 88px;
                                @media(max-width: 992px) {
                                    padding-left: 98px;
                                    min-height: 47px;
                                }
                            }
                        }

                        .info{
                            .title{
                                margin-bottom: 13px;
                                @media(max-width: 992px) {
                                    margin-bottom: 8px;
                                }
                                h3{
                                    @include font('Open Sans', sans-serif, 400, normal);
                                    font-size: 28px;
                                    line-height: 44px;
                                    color: #121212;
                                    display: inline;
                                    word-break: break-word;
                                    border-bottom: 2px solid #F6CDD6;;
                                    @media(max-width: 992px) {
                                        @include font('Open Sans', sans-serif, 400, normal);
                                        font-size: 17px;
                                        line-height: 24px;
                                    }
                                }
                            }
                            .textWrapper{
                                display: block;

                                width: 100%;
                                max-width: 880px;

                                display: block;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;


                                @media(max-width: 992px) {

                                    -webkit-line-clamp: 3;
                                }

                                @include font('Open Sans', sans-serif, 300, normal);
                                font-size: 21px;
                                line-height: 36px;
                                letter-spacing: 0.24px;
                                color: #121212;
                                padding-right: 28px;
                                word-break: break-all;
                                @media(max-width: 992px) {
                                    font-size: 15px;
                                    line-height: 24px;
                                }

                            }
                        }

                    }
                }

            }

            &[data-result="blogs"]{

                .content{
                    display: flex;
                    align-items: flex-end;
                    flex-wrap: wrap;
                    // justify-content: space-between;

                    margin-bottom: 20px;

                    @media (max-width: 992px) {
                        margin-bottom: 0;
                    }

                    .item{
                        width: 100%;
                        max-width: 29.5%;
                        margin-bottom: 80px;
                        margin-right: 5.7%;

                        &:nth-child(3n+0) {
                            margin-right: 0;
                        }

                        @media(max-width: 1200px){
                            max-width: 47.5%;
                            margin-bottom: 40px;
                            margin-right: 0;

                            &:nth-child(odd) {
                                margin-right: auto;
                            }

                        }

                        @media(max-width: 540px){
                            max-width: 100%;
                        }

                        a{
                            text-decoration: none;
                            width: 100%;
                        }
                        .imgWrapper{
                            display: flex;
                            align-items: flex-end;
                            margin-bottom: 24px;
                            @media(max-width: 992px){
                                margin-bottom: 20px;
                            }
                            img{
                                width: 100%;
                                transition: all 250ms;
                            }
                        }
                        .info{
                            height: 110px;
                            @media(max-width: 992px){
                               height: auto;
                            }
                            .date{
                                margin-bottom: 8px;
                                @include font('Open Sans', sans-serif, 300, normal);
                                font-size: 18px;
                                line-height: 28px;
                                display: flex;
                                align-items: center;
                                color: #121212;
                                opacity: 0.5;
                                @media(max-width: 992px){
                                    font-size: 15px;
                                    line-height: 22px;
                                    letter-spacing: 0.24px;
                                    display: none;
                                }
                            }
                            .title{
                                @include font('Open Sans', sans-serif, 400, normal);
                                font-size: 25px;
                                line-height: 36px;
                                letter-spacing: -0.24px;
                                color: #121212;
                                display: block;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                @media(max-width: 992px){
                                    font-size: 20px;
                                    line-height: 28px;
                                }
                            }
                        }
                        &:hover{
                            img{
                                opacity: .9;
                            }
                        }
                    }
                }

            }


            // &[data-result="program"]{
            //     padding: 0 50px;
            //     @media(max-width: 992px) {
            //         padding: 0;
            //     }   
            // }

            // &[data-result="blogs"]{
            //     padding: 0 50px;
            //     @media(max-width: 992px) {
            //         padding: 0;
            //     }   
            // }
                    
            &[data-result="program"],
            &[data-result="information"],
            &[data-result="blogs"]{

                .content.insert{
                    //margin-bottom: 100px;
                    padding: 0 50px;
                    @media(max-width: 992px) {
                        padding: 0;
                    } 
                    @media(max-width: 992px) {
                        //margin-bottom: 40px;
                    }
                }

                .showMoreWrapper{
                    text-align: center;
                    margin-bottom: 124px;
                    @media(max-width: 992px) {
                        margin-bottom: 86px;
                    }
                    a{
                        @include font('Open Sans', sans-serif, 400, normal);
                        text-decoration: none;
                        display: inline-block;
                        font-size: 24px;
                        line-height: 42px;
                        color: #323E4F;
                        border-bottom: 2px solid #F6CDD6;
                        @media(max-width: 992px) {
                            font-size: 18px;
                            line-height: 32px;
                        }
                    }
                }
            }

        }


    }

}
