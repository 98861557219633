.landingPageHeader{
    &-toleft{
        h1 {
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 108px;
            line-height: 112px;
            letter-spacing: -0.88px;
            color: #483A54;
            margin-left: -40px;
            margin-bottom: 60px;
            overflow-wrap: normal;
            word-wrap: normal;
            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
            @media(max-width: 991px) {
                font-size: 48px;
                line-height: 55px;
                letter-spacing: -0.5px;
                margin-left: 0;
                margin-bottom: 20px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        &__intro {
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 28px;
            line-height: 48px;
            letter-spacing: 0.24px;
            color: #121212;
            margin-bottom: 60px;
            @media(max-width: 991px) {
                font-size: 17px;
                line-height: 28px;
                margin-bottom: 40px;
            }
        }
    }
}

.subLandingPageHeader{
    &-sideinfo {
        margin: 100px 0 32px 0;
        @media(max-width: 991px) {
            margin: 30px 0 32px 0;
        }
        h1{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 65px;
            line-height: 75px;            
            color: #506279;
            overflow-wrap: normal;
            word-wrap: normal;
            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
            @media(max-width: 991px) {
                text-align: center;
                font-size: 36px;
                line-height: 41px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        .back-link {
            margin-bottom:35px;
            @media(max-width: 991px) {
                margin-bottom: 20px;
            }
        }
        &__intro {
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 24px;
            line-height: 38px;
            color: #000000;
            margin-top: 10px;
            @media(max-width: 991px) {
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 18px;
                line-height: 30px;
                margin-top: 29px;
            }
        }

        &.faq{
            
            margin: 100px 0 176px 0;
            @media(max-width: 992px) {
                margin: 10px 0 29px 0;
            }            
        }
    }

    &--sand {
        .back-link {
            margin-bottom: 35px;
            @media(max-width: 991px) {
                margin-bottom: 20px;
            }
        }

    }
}



.title{
    &-toleft{
        h1 {
          color: $brandPurple;
          margin-left: -40px;
          margin-bottom: 60px;
          overflow-wrap: normal;
          word-wrap: normal;
          -webkit-hyphens: none;
          -moz-hyphens: none;
          -ms-hyphens: none;
          hyphens: none;
          @media(max-width: 991px) {
            margin-left: 0;
            margin-bottom: 20px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
          }
        }
        &__intro {
          font-size: 1.75rem; //  18
          margin-bottom: 60px;
          @media(max-width: 991px) {
            font-size: 1.0625rem;
            margin-bottom: 40px;
          }
        }
    }
    &-sideinfo {
        margin: 100px 0 80px;
        @media(max-width: 991px) {
            margin: 30px 0 28px;
        }
        h1{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 65px;
            line-height: 75px;            
            color: #506279;
            overflow-wrap: normal;
            word-wrap: normal;
            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
            @media(max-width: 991px) {
                text-align: center;
                font-size: 36px;
                line-height: 41px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        .back-link {
            margin-bottom:35px;
            @media(max-width: 991px) {
                margin-bottom: 20px;
            }
        }
        &__intro {
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 24px;
            line-height: 38px;
            color: #000000;
            margin-top: 10px;
            @media(max-width: 991px) {
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 18px;
                line-height: 30px;
                margin-top: 29px;
            }
        }
    }
    &-intro {
        margin-bottom: 60px;
        @media(max-width: 991px) {
            margin-bottom: 28px;
        }
        h1 {
            font-size: 4.5rem; // 72px
            color: $brandPurple;
            margin-bottom: 60px;
            overflow-wrap: normal;
            word-wrap: normal;
            -webkit-hyphens: none;
            -moz-hyphens: none;
            -ms-hyphens: none;
            hyphens: none;
            @media(max-width: 991px) {
                font-size: 2rem; // 32px
                margin-bottom: 24px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
        &__intro {
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: $p-m;
            margin-top: 15px;
            margin-bottom: 60px;
            @media(max-width: 991px) {
                margin-top: 0;
                margin-bottom: 40px;
            }
            .sub-title{
                @include font('Open Sans', sans-serif, 400, normal);
                margin-bottom: 12px;
            }
            @media(max-width: 991px) {
                font-size: 1.0625rem; //   17
            }
        }
    }
    &--sand {
        margin: 100px 0 60px;
        @media(max-width: 991px) {
            margin: 30px 0 28px;
        }
        .back-link {
            margin-bottom: 35px;
            @media(max-width: 991px) {
                margin-bottom: 20px;
            }
        }
        h3{
            color: $brandPurple;
        }
        &:before{
            z-index: -1;
            content: '';
            background-image: url("../images/form-title.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 60%;
            max-width: 60%;
            height: 90%;
            right: 10px;
            top: 0%;
            position: absolute;
            @media(max-width: 992px){
                background-image: url("../images/form-title-m.svg");
                width: 100%;
                height: 100%;
                max-width: 98.74%;
                right: 0;

                background-position: right;
            }
        }
    }
}

.mb-10{
    margin-bottom: 10px;
    @media(max-width: 991px) {
        margin-bottom: 6px;
    }
}