.employee-cards {
    margin-bottom: 28px;

    @media(max-width: 991px) {
        margin-bottom: 60px;
    }

    &+.employee-cards{
        margin-top: -28px;

        @media(max-width: 991px) {
            margin-bottom: 60px;
        }
    }

    &__subtitle{
        @include font('Open Sans', sans-serif, 400, normal);
        font-size: 36px;
        line-height: 41px;      
        color: #506279;
        margin-bottom: 30px;
        @media(max-width: 992px) {
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            margin-bottom: 23px; 
        }
    }

    .item{
        margin-bottom: 82px;
        @media(max-width: 991px) {
            margin-bottom: 35px;
        }
        .img {
            background: #C4C4C4;
            margin-bottom: 20px;
            padding-top: calc((470 / 360) * 100%);
            @media(max-width: 991px) {
                margin-bottom: 7px;
            }
        }
        .name {
            word-break: break-word;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 20px;
            line-height: 34px;
            color: #323E4F;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            display: -webkit-box;
            display: -moz-box;
            @media(max-width: 991px) {
                font-size: 16px;
                line-height: 30px;
                font-weight: 700;
                margin-bottom: 0;
            }
        }
        .work {
            word-break: break-word;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 20px;
            line-height: 34px;
            color: rgba(50, 62, 79, 0.7);
            opacity: 0.8;
            margin-bottom: 12px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            display: -webkit-box;
            display: -moz-box;
            @media(max-width: 991px) {
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 0;
            }
        }

        .email,
        .tel{
            word-break: break-word;
            color: #323E4F;
            a{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 20px;
                line-height: 34px;
                color: #323E4F;   
                text-decoration: none;             
                @media(max-width: 991px) {
                    font-size: 16px;
                    line-height: 30px;
                }
            }
        }
        .email{
           a{
            color: #3972B4;
            text-decoration: underline;
           } 
        }

    }
}
