.col-lg-14 {
    .row.resize-col{
        padding: 0;
        @media (max-width: 992px) {
            padding: 0;
        }
        .col-lg-3 {
            @media (min-width: 993px) {
                flex: 0 0 21.42%;
                max-width: 21.42%;
            }
        }
        .col-lg-4 {
            @media (min-width: 993px) {
                flex: 0 0 28.56%;
                max-width: 28.56%;
            }
        }
        .col-lg-5 {
            @media (min-width: 993px) {
                flex: 0 0 35.7%;
                max-width: 35.7%;
            }
        }
        .col-lg-6 {
            @media (min-width: 993px) {
                flex: 0 0 42.84%;
                max-width: 42.84%;
            }
        }
        .col-lg-8 {
            @media (min-width: 993px) {
                flex: 0 0 57.12%;
                max-width: 57.12%;
            }
        }
        .col-lg-10 {
            @media (min-width: 993px) {
                flex: 0 0 71.4%;
                max-width: 71.4%;
            }
        }
    }
}
