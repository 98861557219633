.article,
.accordionContent,
.textBlocks{
    margin-bottom: 100px;
    @media(max-width: 992px) {
        margin-bottom: 80px;
    }
    &__header{
        margin-bottom: 60px;
        @media(max-width: 992px) {
            margin-bottom: 30px;
        }
        &__info {
            .item{
                margin-bottom: 4px;
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.24px;
                color: #000000;
                @media(max-width: 992px) {
                    font-size: 14px;
                    line-height: 24px;
                }
                strong{
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-weight: normal;
                }
            }
            @media(max-width: 992px) {
                margin-bottom: 20px;
            }
        }
        &__intro{
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 25px;
            line-height: 40px;
            letter-spacing: 0.24px;
            color: #121212;
            margin-top: 80px;
            @media(max-width: 992px) {
                margin-top: 40px;
                font-size: 19px;
                line-height: 32px;
            }
        }
    }
    &__html-area{
        overflow: hidden;

        a{
            // text-decoration: none !important;
            // color: #222 !important;
            // border-bottom: 2px solid rgba($color: #F68D6F , $alpha: .6);
            // &:hover{
            //     border-bottom: 2px solid rgba($color: #F68D6F , $alpha: 1);
            // }
            color: #3972B4;
            u{
               text-decoration: none;
            }
        }

        p, ul, ol{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 24px;
            line-height: 38px;
            @media (max-width: 992px) {
                font-size: 18px;
                line-height: 30px;
            }
        }

        &.blogPost{
            p, ul, ol{
                line-height: 28px;
                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 21px;
                }
            }
            p, ul, ol, h3, h4, h5, h6{
            @media(max-width: 992px) {
                    margin-bottom: 20px;
                }
            }
        }

        p{
            color: #000000;
        }

        ul, ol{
            color: #000000;
        }

        ul{
            &+ h1,
            &+ h2,
            &+ h3,
            &+ h4,
            &+ h5,
            &+ h6{
                padding-top: 34px;
            }
        }

        p, ul, ol, h3, h4, h5, h6, .loopify-newsletter {
            margin-bottom: 40px;
            margin-left: calc(35.5% + 15px);
            width: calc(64.5% - 16px);
            @media(max-width: 992px) {
                margin-bottom: 28px;
                margin-left: 0;
                width: 100%;
            }
        }

        ul, ol{
            @media(max-width: 992px) {
                margin-bottom: 40px;
            }
        }

        h1, h2, h3, h4, h5, h6{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 36px;
            line-height: 41px;
            margin-bottom: 12px;
            color: #506279;
            @media (max-width: 992px) {
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                margin-bottom: 16px;
            }
        }

        h1, h3, h4, h5, h6{
            padding-top: 20px;
            @media (max-width: 992px) {
                padding-top: 0px;
            }
        }

        h2{
            width: calc(35.714% - 26px);
            padding-top: 36px;
            position: absolute;
            @media(max-width: 992px) {
                position: relative;
                width: 100%;
                float: none;
                padding-top: 12px;
            }

            + p, + h3, + h4, + h5, + h6, + ul, + ol, + .imageWrapper{
                padding-top: 36px;
                @media(max-width: 992px){
                    padding-top: 0;
                }
            }

            &:first-child{
                padding-top: 0;
                + p, + h3, + h4, + h5, + h6, + ul, + ol, + .imageWrapper{
                    padding-top: 0;
                }
            }

        }

        .imageWrapper{
            margin-left: calc(35.5% + 15px);
            width: calc(64.5% - 16px);
            @media (max-width: 992px) {
                margin-left: 0;
                width: 100%;
            }
            &+ h2 {
                margin-top: 0;
            }
            figure{
                    width: 100%;
                    height: auto;
                img{
                    width: 100%;
                    height: auto;
                    margin-bottom: 16px;
                }
                figcaption{
                    @include font('Open Sans', sans-serif, 300, normal);
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: 0.24px;
                    color: #121212;
                    text-align: center;
                    display: block;
                    @media(max-width: 992px){
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }

            .editor-align-center,
            .editor-align-justify{
                padding-top: 56px;
                margin-bottom: 80px!important;
                @media (max-width: 992px) {
                    padding-top: 36px;
                    margin-bottom: 40px!important;
                }
            }

            .editor-align-left{
                margin-right: 30px;
                margin-bottom: 15px;
                @media (max-width: 992px) {
                    margin-right: 15px;
                }
            }

            .editor-align-right{
                margin-left: 30px;
                margin-bottom: 15px;
                @media (max-width: 992px) {
                    margin-left: 15px;
                }
            }

            &+ .imageWrapper {

                .editor-align-center,
                .editor-align-justify{
                    padding-top: 0;
                    @media (max-width: 992px) {
                        padding-top: 0;
                    }
                }
            }

        }


        .tableWrapper{
            margin-left: calc(35.5% + 15px);
            width: calc(64.5% - 16px);
            padding-top: 23px;
            margin-bottom: 50px;
            overflow-x: auto;
            @media (max-width: 992px) {
                margin-left: 0;
                width: 100%;
            }
            table{
                border: thin solid #cac9c9;
                tr{
                    border-bottom: thin solid #cac9c9;
                    &:last-child{
                        border-bottom: none;
                    }
                    td{
                        border-right: thin solid #cac9c9;
                        padding: 10px;
                        min-width: 100px;
                        &:last-child{
                            border-right: none;
                        }
                    }
                }
            }
        }



        p {
            &+ ul, &+ ol {
                margin-top: -4px;
            }
        }

        ul, ol {
            padding-left: 26px;
            @media (max-width: 992px) {
                padding-left: 28px;
            }
            li {
                padding-left: 4px;
                margin-bottom: 8px;
                @media (max-width: 992px) {
                    margin-bottom: 4px;
                }
              &:marker {
                width: 5px;
              }
            }
        }

        .line-grey {
            margin: 80px 0;
            @media(max-width: 992px){
                margin: 60px 0;
            }
            &+ h1,
            &+ h2,
            &+ h3,
            &+ h4,
            &+ h5,
            &+ h6,
            &+ p,
            &+ ul,
            &+ ol{
                padding-top: 0;
            }
            &+ h1 + *,
            &+ h2 + *,
            &+ h3 + *,
            &+ h4 + *,
            &+ h5 + *,
            &+ h6 + *,
            &+ p + *,
            &+ ul + *,
            &+ ol + *{
                padding-top: 0;
            }
        }

        &.course-status{
            p, ul, ol, h2, h3, h4, h5, h6,
            .imageWrapper,
            .tableWrapper,
            .box-orange-article{
                margin-left: 4.8%;
                @media (max-width: 992px){
                    margin-left: 0;
                }
            }
            h2, h3, h4, h5, h6{
                float: none;
            }
        }

        .loopify-newsletter{
            iframe{
                border: none;
                // height: 320px;
                margin: -20px;

                @media (max-width: 575px){
                    width: calc(100% + 40px);
                }
            }
        }
    }

    &.hide{
        @media (max-width: 992px){
            display: none;
        }
    }

    .quoteBig,
    .quoteSmall{
        margin-top: 60px;
        margin-bottom: 60px;
        @media(max-width: 992px){
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }

    &.basic{
        margin-bottom: 200px;
        @media(max-width: 992px){
            margin-bottom: 100px;
        }
    }

    &.articleContent{
        .article__html-area{
            p, ul, ol, h3, h4, h5, h6, .imageWrapper, .tableWrapper{
                margin-left: calc(37.5% + 15px);
                width: calc(62.5% - 16px);
                @media(max-width: 992px) {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }

}
.article{
    &+.article{
        margin-top: -100px;
        @media(max-width: 992px) {
            margin-top: -80px;
        }
    }
}

.accordionContent{
    margin-bottom: 80px;
    @media(max-width: 992px) {
        margin-bottom: 0px;
    }
}

.mr--40{
    margin-right: -40px!important;
    @media(max-width: 992px) {
        margin-right: 0!important;
    }
}
