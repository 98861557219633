.btnFloatWrapper{
    z-index: 1001;
    position: absolute;
    right: -140px;
    height: auto;
    display: none;
    flex-direction: column-reverse;
    z-index: 3;

    @media (min-width: 992px) {
        bottom: 20px;
    }

    @media (max-width: 992px) {
        position: fixed;
        right: 1px;
        height: 90vh;
        right: -120px;
    }

    &.active{
        display: flex;
    }

    &.isVisible{
        position: fixed;
        top: inherit !important;
        bottom: 30px !important;
    }

    &.course{
        height: inherit;
        bottom: inherit;

        @media (min-width: 1780px) {
            right: calc((100vw - 1640px)/2);
        }

        @media (max-width: 1780px) {
            right: 80px;
        }

        @media(max-width: 1200px) {
            right: 30px;
        }

        @media (max-width: 992px) {
            bottom: 30px;
            right: 10px;
        }

        .btnFloatWrapper__btn{
            position: relative;
            display: block;
            width: auto;
            height: auto;
            padding: 16px 60px 16px 32px;
            border-radius: 30px;
            white-space: nowrap;
            transform: none;

            @media (max-width: 992px) {
                padding: 14px 50px 14px 22px;
            }

            &::after{
                content: "";
                display: block;
                position: absolute;
                top: calc(50% - 8px);
                right: 28px;
                width: 18px;
                height: 16px;
                background-image: url(../images/./icons/arrow-email-purple.svg);
                background-repeat: no-repeat;
                background-size: auto;

                @media (max-width: 992px) {
                    right: 22px;
                }
            }

            span.title{
                height: 25px;
                color: #483A54;
                font-size: 18px;
                font-weight: 400;
                text-transform: uppercase;
                letter-spacing: normal;
                line-height: normal;


                @media (max-width: 992px) {
                    height: 21px;
                    font-size: 15px;

                }
            }
        }
    }

    &--kategori{
        position: fixed;

        @media (min-width: 992px) {
            top: calc(50vh - 70px);
            right: 20px;
            bottom: inherit;
        }

        .btnFloatWrapper__btn{
            background: #E97C5D;

            @media (min-width: 992px) {
                transform: none;
            }
        }
    }

    &__btn{
        background: #999999;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        text-align: center;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px;
        margin-top: 14px;
        transform: translateX(-160px);

        &:last-child{
            margin-top: 0;
        }
        &:active,
        &:focus{
            outline: 0;
        }
        @media (max-width: 991px) {
            width: 120px;
            height: 120px;
            margin-top: 1px;
            transform: translateX(-121px);
        }
        .title{
            @include font('EB Garamond', serif, 400, normal);
            font-size: 21px;
            line-height: 24px;
            letter-spacing: -0.88px;
            color: #FFFFFF;
            display: block;
            @media (max-width: 992px) {
                font-size: 17px;
                line-height: 24px;
            }
        }

        &.chat-online{
            background: #436A5F;
            .status{
                @include font('Open Sans', sans-serif, 300, normal);
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.24px;
                color: #A2E4BF;
                @media (max-width: 991px) {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        &.signUp{
            // background: #483A54;
            background: #E97C5D;
        }
        &.filter{
            background-color: $lightGreen;
            @media(min-width: 992px) {
                display: none;
            }
            @media (max-width: 991px) {
                font-size: .9375rem;
            }
        }

    }
}
